import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import 'flowbite';
// mouting point for the whole app

import App from "@/App.vue";

// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Settings from "@/views/admin/Settings.vue";
import Tables from "@/views/admin/Tables.vue";
import Maps from "@/views/admin/Maps.vue";

// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";

// views without layouts

import Landing from "@/views/Landing.vue";
import Profile from "@/views/Profile.vue";
import LandingIframe from "@/views/LandingIframe.vue"
//import Index from "@/views/Index.vue";
import Configure from "@/views/Configure.vue";
import ConfirmAddressStep from "@/components/ConfiguratorSteps/ConfirmAddressStep.vue";
import TypologyStep from "@/components/ConfiguratorSteps/TypologyStep.vue";
import HouseStatusStep from "@/components/ConfiguratorSteps/HouseStatusStep.vue";
import ImageLoaderStep from "@/components/ConfiguratorSteps/ImageLoaderStep.vue";
import PlanimetryDetailsStep from "@/components/ConfiguratorSteps/PlanimetryDetailsStep.vue";
import PlanimetryDetailsStepPart2 from "@/components/ConfiguratorSteps/PlanimetryDetailsStepPart2.vue";
import PlanimetryDetailsStepPart3 from "@/components/ConfiguratorSteps/PlanimetryDetailsStepPart3.vue";
import AdditionalInfoStep from "@/components/ConfiguratorSteps/AdditionalInfoStep.vue";
import ValuationStep from "@/components/ConfiguratorSteps/ValuationStep.vue";
import PropertyListing from "@/views/PropertyListing.vue";
import PropertyListingUpdated from "@/views/PropertyListingUpdated.vue";
import SingleProperty from "@/views/SingleProperty.vue";
const fb = require('./firebase/init.js');

import './index.css'


// routes

const routes = [
  
  
  {
    path: "/landing",
    component: Landing,
  },
  {
    path: "/landing-iframe",
    component: LandingIframe,
  },
  {
    path: "/profile",
    component: Profile,
  },
  /*{
    path: "/compra",
    component: PropertyListingUpdated,
    // children: [
    //   {
    //     path: ':docId',
    //     name: "PropertyListing",
    //     component: SingleProperty,
    //     props: true,
    //   },
    // ],
  },*/
  {
    path: "/compra",
    component: PropertyListingUpdated,
    // children: [
    //   {
    //     path: ':docId',
    //     name: "PropertyListing",
    //     component: SingleProperty,
    //     props: true,
    //   },
    // ],
  },
  
  {
    path: '/compra/:docId',
    name: "PropertyListing",
    component: SingleProperty,
    props: true,
    alias: '/compra'
    
  },
  {
    path: "/configure",
    component: Configure,
    children: [
      {
        name: 'step-0',
        path: "/configure/conferma-indirizzo",
        component: ConfirmAddressStep,
      },
      {
        name: 'step-1',
        path: "/configure/tipologia",
        component: TypologyStep,
      },
      {
        name: 'step-2',
        path: "/configure/stato",
        component: HouseStatusStep,
      },
      {
        name: 'step-3',
        path: "/configure/images",
        component: ImageLoaderStep,
      },
      {
        name: 'step-4',
        path: "/configure/caratteristiche1",
        component: PlanimetryDetailsStep,
      },
      {
        name: 'step-5',
        path: "/configure/caratteristiche2",
        component: PlanimetryDetailsStepPart2,
      },
      {
        name: 'step-6',
        path: "/configure/caratteristiche3",
        component: PlanimetryDetailsStepPart3,
      },
      {
        name: 'step-7',
        path: "/configure/informazioni-aggiuntive",
        component: AdditionalInfoStep,
      },
      {
        name: 'valuation',
        path: "/configure/valuation",
        component: ValuationStep,
      }
    ]
  },
  {
    path: "/",
    redirect: "/configure"
    // redirect: "/compra"
    // component: Landing,
    //component: Index,
  },
  // { path: "/:pathMatch(.*)*", redirect: "/configure" },
];

fb.auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      store,
      router,
      render: h => h(App)
    }).$mount('#app')
  }
});



const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App).use(router).mount("#app");
