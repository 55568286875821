<template>
    <div :class="[
            'nap-map-popup nap-popup-desktop na-overlay fixed my-auto mx-auto w-full h-full bg-white z-30 top-0 left-0 justify-center flex flex-row',
            showContactPop == true ? 'block' : 'hidden'
        ]" >
            
        <div class="relative nap-popup-wrapper w-full h-full z-40 flex flex-row items-center justify-center br-10">

            <div class="lg:w-11/12 w-full nap-popup-container nap-contact-form flex flex-row items-center justify-center bg-white br-10" :style="{
                height: '700px',
                width: '620px'

            }">
                <div class="h-full w-full relative flex flex-col br-10 items-center justify-center">
                    <div class="nap-contact-form-close-button absolute right-2 top-2 cursor-pointer z-50 flex flex-row items-center justify-center" @click="hideContactPopup()">
                        <img :style="{
                            'width': '14px',
                            'height': '14px',
                            'background-color': '#000',
                            'margin-right': '0px',
                            'mask': 'url(\'' + require(`@/assets/img/icons/close-popup.svg`) + '\')',
                            '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/close-popup.svg`) + '\')'
                            }">
                    </div>
                    
                    <div class="nap-popup-content-wrapper">
                        <div class="flex flex-row justify-between nap-contact-popup-header items-center">
                            <img src="@/assets/img/acquistalo_con.png" class="acquis-logo"/>

                            <img v-if="agency['user'] != null && agency['user']['profilePicture'] != null" :src="agency['user']['profilePicture']" class="acquis-logo"/>
                        </div>

                        <div class="nap-iframe-container">
                            <iframe src="https://www.newarc.it/caseristrutturate-form-agenzia/" height="100%" width="100%"></iframe>
                        </div>
                        <div class="flex flex-row justify-between w-full">
                            <div class="ins-ag-details w-full">
                                <p class="ins-ag-title">L’ AGENZIA</p>
                                <p class="ins-ag-name">{{ agency['name'] }}</p>
                                <p class="ins-ag-address">{{ agency['address'] }}, {{ agency['city'] }}</p>
                                <p class="ins-ag-address">Tel.  {{ agency['phone'] }}</p>
                            </div>
                        </div>
                    </div>
                    
                    
                    
                    
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import Close from "vue-material-design-icons/Close.vue";
import {

  getStorage
} from 'firebase/storage';

export default{
    props: {
        showContactPop: {
            type: Boolean,
            required: true
        },
        agency: {
            type: Object,
            required: true
        },
    },
    mounted() {
        window.addEventListener("message", this.handleIframeMessage);
    },
    beforeDestroy() {
        window.removeEventListener("message", this.handleIframeMessage);
    },
    emits: ["hideContactPopup"],
    methods: {
        handleIframeMessage(event) {
            if (event.data === 'formSubmitted') {
                setTimeout(() => {
                    this.hideContactPopup();
                }, 1000);
                
            }
        },
        hideContactPopup(){
            this.$emit("hideContactPopup");
        },
        fakeSend(){
            this.isSent = true;
        },
        
    },
    data() {
        return {
            isSent: false
        }
    },
    components: {
        Close
    }
}

</script>