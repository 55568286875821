<template>
  <nav class="newarc-menu top-0 lg:shadow-none shadow-md fixed z-50 w-full flex flex-wrap items-center justify-between navbar-expand-lg lg:bg-transparent bg-white">
    <div class="newarc-menu-container container mx-auto flex flex-wrap items-center justify-between">
      <div class="w-full relative flex lg:w-auto lg:flex-row flex-row-reverse items-center justify-between">
          <a href="https://newarc-platform.web.app/#/" class="lg:hidden block flex rounded-full bg-white text-black lg:px-3 lg:py-2 p-2 items-center mr-1">
            <account-circle-outline fillColor="#000" />
          </a>
          <router-link class="lg:mr-4 lg:py-2" to="/" >
              <img style="object-fit: cover; max-width:120px;" class="" src="@/assets/img/newarc_logo_dark.png"/>
          </router-link>
          <button class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none" type="button" v-on:click="setNavbarOpen" style="width:45px;">
            <span v-if="!navbarOpen">
              <i class="text-black fas fa-bars"></i>
            </span>
            <span v-else>
              <i class="text-black fas fa-times"></i>
            </span>
            
          </button>
      </div>
      <div class=" lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" :class="[navbarOpen ? 'block rounded shadow-lg new-arc-opened-nav' : 'hidden']" id="example-navbar-warning">
          <ul class="menu-items flex flex-col lg:flex-row list-none lg:ml-auto na-auth-nav-menu">
              
              <li class="flex items-center">
                  <a class="lg:hover:text-blueGray-200 text-black px-3 flex items-center f15-ls003 font-normal" href="" >
                  Valuta la tua casa
                  </a>
              </li>
              <li class="flex items-center">
                <router-link class="lg:hover:text-blueGray-200 text-black px-3 flex items-center f15-ls003 font-normal" to="/compra" >
                  Compra Casa
                </router-link>
              </li>
              <li class="flex items-center">
                  <a class="lg:hover:text-blueGray-200 text-black px-3 flex items-center f15-ls003 font-normal" href="https://www.newarc.it/le-case-newarc/" >
                  Le case Newarc
                  </a>
              </li>
              <li class="flex items-center">
                  <a class="lg:text-blueGray lg:hover:text-blueGray-200 text-black px-3 flex items-center f15-ls003 font-normal" href="https://www.newarc.it/programma-agenzie/" >
                  Sei un'agenzia?
                  </a>
              </li>
              <li class="flex items-center lg:block hidden">
                  <a href="https://newarc-platform.web.app/#/" class="flex rounded-full bg-white text-black lg:px-3 p-2 items-center shadow">
                    <p class="f14-ls03 font-bold lh-14 mr-1 lg:block hidden">Login</p>
                    <account-circle-outline fillColor="#000" />
                  </a>
              </li>
          </ul>
      </div>
    </div>
</nav>
  
</template>
<script>
import AccountCircleOutline from 'vue-material-design-icons/AccountCircleOutline.vue';
export default {
  data() {
    return {
      navbarOpen: false,
    };
  },
  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
   

  },
  mounted() {
    
  },

  components: {
    AccountCircleOutline
  },
};
</script>
