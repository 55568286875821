<template>

    <div class="flex flex-row justify-between">

        <div class="na-property-sort flex flex-row flex-1">

            <div @click="toggleHoverClass('refType')" @mouseleave="removeHoverClass('refType')" ref="refType"
                class="na-dropdown-pill cursor-pointer primary wide na-has-label has-options relative flex flex-row justify-between items-center">

                <label class="absolute">TIPOLOGIA</label>
                <p>{{ filterParams.type }}</p>

                <div>
                    <img :style="{
                        'width': '9px',
                        'height': '9px',
                        'background-color': '#489B79',
                        'mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')',
                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')'
                    }" class="" />
                </div>

                <ul class="na-dropdown-pill-options hidden absolute top-10 left-0 alt">
                    <li v-for="(item, key) in typology" @click="setType(item);" :key="key">
                        {{ item }}
                    </li>
                </ul>
            </div>



        </div>

        <div class="na-property-filters flex flex-row flex-grow justify-center">

            <div class="na-dropdown-pill secondary relative flex flex-row cursor-pointer justify-between items-center"
                @click="_toggleFilter(true)">
                <div style="margin-right: 5px;">

                    <img :style="{
                        'width': '14px',
                        'height': '14px',
                        'background-color': '#5E5E5E',
                        'mask': 'url(\'' + require(`@/assets/img/icons/filter.svg`) + '\')',
                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/filter.svg`) + '\')'
                    }" class="" />
                </div>
                <p>Filtri</p>
                <div>
                    <img :style="{
                        'width': '9px',
                        'height': '9px',
                        'background-color': '#A2A2A2',
                        'mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')',
                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')'
                    }" class="" />
                </div>

            </div>

            <div class="flex flex-row" v-if="Object.keys(filterParams).length > 0">

                <div v-if="filterParams.area"
                    class="na-dropdown-pill cursor-pointer relative flex flex-row justify-between items-center" @click="_toggleFilter(true)">
                    <div style="margin-right: 5px;">
                        <img :style="{
                        'width': '14px',
                        'height': '14px',
                        'background-color': '#5E5E5E',
                        'mask': 'url(\'' + require(`@/assets/img/icons/square-meters.svg`) + '\')',
                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/square-meters.svg`) + '\')'
                    }" class="" />
                    </div>
                    <p>{{ filterParams.area.areaMin }}-{{ filterParams.area.areaMax }} mq</p>
                </div>

                <div class="na-dropdown-pill cursor-pointer relative flex flex-row justify-between items-center" @click="_toggleFilter(true)">
                    <div style="margin-right: 5px;">
                        <img :style="{
                        'width': '14px',
                        'height': '14px',
                        'background-color': '#5E5E5E',
                        'mask': 'url(\'' + require(`@/assets/img/icons/rooms.svg`) + '\')',
                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/rooms.svg`) + '\')'
                    }" class="" />
                    </div>
                    <p>{{ filterParams.rooms == 'Tutti' ? '> 1' : filterParams.rooms }} locali</p>
                </div>

                <div class="na-dropdown-pill cursor-pointer relative flex flex-row justify-between items-center" @click="_toggleFilter(true)">
                    <div style="margin-right: 5px;">
                        <img :style="{
                        'width': '14px',
                        'height': '14px',
                        'background-color': '#5E5E5E',
                        'mask': 'url(\'' + require(`@/assets/img/icons/bathrooms.svg`) + '\')',
                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/bathrooms.svg`) + '\')'
                    }" class="" />
                    </div>
                    <p>{{ filterParams.bathrooms == 'Tutti' ? '> 1 ' : filterParams.bathrooms }}{{ filterParams.bathrooms == 1 ? ' bagno' : ' bagni' }}</p>
                </div>

                <div class="na-dropdown-pill cursor-pointer relative flex flex-row justify-between items-center" @click="_toggleFilter(true)">
                    <div style="margin-right: 5px;">
                        <img :style="{
                        'width': '14px',
                        'height': '14px',
                        'background-color': '#5E5E5E',
                        'mask': 'url(\'' + require(`@/assets/img/icons/price.svg`) + '\')',
                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/price.svg`) + '\')'
                    }" class="" />
                    </div>
                    <p>{{ parseInt(filterParams.price[0]).toLocaleString() }}-{{ parseInt(filterParams.price[1]).toLocaleString() }}</p>
                </div>
            </div>


        </div>

        <div class="na-property-map-toggle flex flex-row items-center flex-1 justify-end">

            <div class="flex flex-row items-center">
                <div class="flex items-center">
                    <input type="checkbox" v-model="showMap" class="toggle" id="header-toggle" true-value="true"
                        @change="_toggleMap" false-value="false" />
                    <label class="toggle-label" for="header-toggle"></label>
                </div>
                &nbsp;
                <label class="na-property-map-toggle-label">Mappa</label>
            </div>

        </div>

    </div>

</template>

<script>

export default {
    props: {
        toggleMap: {
            type: Function
        },
        toggleFilter: {
            type: Function
        },
        filterParams: {
            type: Object,
            required: true
            
        },
        setFilterParams: {
            type: Function
        },
        cities: {
            type: Array,
            required: true
        },
        zones: {
            type: Array,
            required: true
        },
        typology: {
            type: Array,
            required: true
        }

    },
    created() {},
    beforeMount(){},
    mounted() {
        this.filterParams.city = this.cities[0];
        this.filterParams.zone = this.zones[0];
        this.filterParams.type = this.typology[0];
        console.log('cities', this.cities);
    },
    unmounted() {},
    components() {},
    methods: {
        _toggleMap(){
            this.$emit("toggleMap");
        },
        _toggleFilter(status) {
            this.$emit("toggleFilter", status);
        },
        toggleHoverClass(ref) {
            if( this.$refs[ref].classList.contains('hovered') ) {
                console.log('removed');
                this.$refs[ref].classList.remove('hovered');
            } else {
                console.log('added');
                this.$refs[ref].classList.add('hovered')
            }
            
        },
        removeHoverClass(ref) {
            console.log(this.$refs[ref]);
            this.$refs[ref].classList.remove('hovered');
        },
        setCity(item) {
            this.filterParams.city = item;
            this._setFilterParams();

        },
        setZone(item){
            this.filterParams.zone = item;
            this._setFilterParams();
        },
        setType(item) {
            this.filterParams.type = item;
            this._setFilterParams();
        },
        _setFilterParams(){
            this.$emit("setFilterParams", {
                city: this.filterParams.city,
                zone: this.filterParams.zone,
                type: this.filterParams.type,
                rooms: this.filterParams.rooms,
                bathrooms: this.filterParams.bathrooms,
                price: this.filterParams.price, 
                area: this.filterParams.area,
            });
        }
    },
    data() {
        return {
            showMap: true,
            // cities: ['Torino'],
            // zones: ["Tutte le zone", "Campidoglio", "Colle della Maddalena", "San Secondo", "Pozzo Strada", "Crocetta", "San Salvario - Baretti", "Sassi", "Parella", "Lingotto", "Piazza Solferino", "Quadrilatero Romano", "Cavoretto", "Madonna del Pilone", "Superga", "Via della Rocca", "Santa Rita", "Via Roma", "Cit Turin", "San Donato", "Mirafiori Nord", "Gran Madre - Crimea", "San Salvario - Dante", "San Paolo", "Cenisia", "Nizza Millefonti", "Regio Parco", "Cittadella", "Città Giardino", "Vanchiglia", "Vanchiglietta", "Centro Europa", "Giardini Reali", "Italia 61"],
            // typology: ['Tutte', 'Appartamento', 'Villa', 'Casa indipendente', 'Loft', 'Attico', 'Mansarda'],
        }
    }
}

</script>