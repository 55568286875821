<template>
  <!-- <  h1>Hello world {{ this.propertyCount }}</h1> -->
    <div v-if="!this.modelValue" ref="mapRef"  v-bind:id="this.mapId" class="relative w-full h-full" data-lat="40.748817" data-lng="-73.985428"></div>
    <div v-else ref="mapRef" v-bind:id="this.mapId" class="relative w-full h-full" v-bind:data-lat="this.mapPosition.latitude"
    v-bind:data-lng="this.mapPosition.longitude"></div>
  
</template>
<script>
export default {
  props: {
    modelValue: Object,
    additionalMarkers: Object, // linpst of coordinates pairs
    mapId: {
      type: String,
      default: 'map-canvas'
    },
    mapPosition: {
      type: Object,
      required: true
    },
    propertyCount: {
      type: Number,
      default: 0
    },
    maxZoom: {
      type: Number,
      default: 0
    },
  },
  updated() {
    // console.log(this.mapPosition);
    this.generateMap();
  },
  created(){
  },
  beforeUpdate(){
    // console.log('before upd');
  },
  mounted() {
    this.generateMap();
  },
  methods: {
    generateMap(){
      //  console.log("generateMap");
        let google = window.google;
        let map = this.$refs['mapRef'];

        let lat = this.$refs['mapRef'].getAttribute("data-lat");
        let lng = this.$refs['mapRef'].getAttribute("data-lng");

        if ( this.mapPosition['center'] ) {
          lat = this.mapPosition['center'].lat;
          lng = this.mapPosition['center'].lng;
        }

        let centerlat = lat;
        let centerlng = lng;
        // Find the center of the given coordinates
        // console.log(centerlat, centerlng);
        
        var myLatlng;
        if( this.mapPosition['moveToRight'] === true && window.innerWidth > 700 ) {
          // myLatlng = new google.maps.LatLng(lat, lng - 0.010);
          myLatlng = new google.maps.LatLng(centerlat - 0.001, centerlng - 0.017);
        } else {
          myLatlng = this.mapPosition['center'];
        }
        
        const mapOptions = {
          zoom: 15,
          // scrollwheel: false,
          mapTypeControl: false,
          // zoomControl: false,
          // scaleControl: false,
          gestureHandling: "greedy",
          streetViewControl: false,
          fullscreenControl: false,
          center: myLatlng,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          styles: [
            {
              featureType: "administrative",
              elementType: "labels.text.fill",
              stylers: [{ color: "#444444" }],
            },
            {
              featureType: "landscape",
              elementType: "all",
              stylers: [{ color: "#f2f2f2" }],
            },
            {
              featureType: "poi",
              elementType: "all",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "road",
              elementType: "all",
              stylers: [{ saturation: -100 }, { lightness: 45 }],
            },
            {
              featureType: "road.highway",
              elementType: "all",
              stylers: [{ visibility: "simplified" }],
            },
            {
              featureType: "road.arterial",
              elementType: "labels.icon",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "transit",
              elementType: "all",
              stylers: [{ visibility: "off" }],
            },
            {
              featureType: "water",
              elementType: "all",
              stylers: [{ color: "#5e72e4" }, { visibility: "off" }],
            },
          ],
        };

        map = new google.maps.Map(map, mapOptions);

        const marker = new google.maps.Marker({
          map: map,
        });

        let infowindows = [];
        map.addListener("click", (e) => {
          if ( infowindows.length > 0 ) {
              for (let infoindex = 0; infoindex < infowindows.length; infoindex++) {
                infowindows[infoindex].close();
              }
            }
        });

        

        // new google.maps.Marker({
        //         position: {
        //           lat: centerlat,
        //           lng: centerlng
        //         },
        //         map: map,
        //         animation: google.maps.Animation.DROP,
        //         // icon: icon,
        //         // opacity: locationsToMark[location] !== "property" ? 0.65 : 1 // anchor
        //       });
        
        let locationsToMark = [ 'property', 'school', 'market', 'park'];

        // console.log(this.mapPosition);

        // let moreMarkeres = this.modelValue;
        // if (moreMarkeres != null) {
        var bounds = new google.maps.LatLngBounds();
        let hasAmenities = false;
        
        for( let location = 0; location < locationsToMark.length; location++ ) {
          
          // console.log(this.mapPosition[locationsToMark[location]]);
          
          if( typeof this.mapPosition[locationsToMark[location]] != 'undefined' && this.mapPosition[locationsToMark[location]].length > 0 ) {

            hasAmenities = true;
            
            const icon = {
                url: require("@/assets/img/icons/map-"+locationsToMark[location]+"-icon.svg"), // url
                scaledSize: new google.maps.Size(40, 40), // scaled size
                origin: new google.maps.Point(0,0), // origin
                anchor: new google.maps.Point(0, 0),
                
            };

            // const enlargedIcon = {
            //     url: require("@/assets/img/icons/map-"+locationsToMark[location]+"-icon.svg"), // url
            //     scaledSize: new google.maps.Size(40, 40), // scaled size
            //     origin: new google.maps.Point(0,0), // origin
            //     anchor: new google.maps.Point(0, 0),
                
            // };

            if( this.mapPosition[locationsToMark[location]].length > 0 ) {

              for (const additionalMarker of this.mapPosition[locationsToMark[location]]) {
                
                // console.log(this.mapPosition[locationsToMark[location]]);
                
                let additionalMarkerLatlng = new google.maps.LatLng(
                  additionalMarker.lat,
                  additionalMarker.lng
                );

                let marker = new google.maps.Marker({
                  position: additionalMarkerLatlng,
                  map: map,
                  animation: google.maps.Animation.DROP,
                  icon: icon,
                  opacity: locationsToMark[location] !== "property" ? 0.65 : 1 // anchor
                });

                bounds.extend(additionalMarkerLatlng);

                let newlong = marker.getPosition().lng() + (0.00003 * Math.pow(2, (21 - map.getZoom())));
                // infowindow.setPosition(new google.maps.LatLng(marker.getPosition().lat(), newlong)); 
                if( this.mapPosition['showPopup'] == true ) {

                
                  let contentString =
                    '<div id="content">' +
                    '<div id="bodyContent">' +
                      `<div class="br-10 w-full" style="width:225px;height:150px;background-image:url('${additionalMarker.image}'); background-size:cover;"></div>` +
                      `<p class="text-center map-info-title mt-2 mb-2" style="width:225px;">${additionalMarker.title}</p>` +
                      `<p class="text-center map-info-area mt-2 mb-2">MQ ${additionalMarker.area}</p>` +
                      `<p class="text-center map-info-price mt-2 mb-2">€${parseInt(additionalMarker.price).toLocaleString()}</p>` +
                    "</div>" +
                    "</div>";

                  let infowindow = new google.maps.InfoWindow({
                    content: contentString,
                  });

                  infowindows.push(infowindow);

                  marker.addListener("click", () => {

                    if ( infowindows.length > 0 ) {
                      for (let infoindex = 0; infoindex < infowindows.length; infoindex++) {
                        infowindows[infoindex].close();
                      }
                    }

                    infowindow.setPosition(new google.maps.LatLng(marker.getPosition().lng() - 0.001, marker.getPosition().lat() - 0.017));
                    infowindow.open({
                      anchor: marker,
                      map

                    });
                    
                  });

                  marker.addListener("mouseover", () => {

                    // marker.setIcon(enlargedIcon);
                    if ( infowindows.length > 0 ) {
                      for (let infoindex = 0; infoindex < infowindows.length; infoindex++) {
                        infowindows[infoindex].close();
                      }
                    }

                    infowindow.setPosition(new google.maps.LatLng(marker.getPosition().lng() - 0.001, marker.getPosition().lat() - 0.017));

                    infowindow.open({
                      anchor: marker,
                      map

                    });
                    
                  });
                  marker.addListener("mouseout", () => {
                    // marker.setIcon(icon);
                    infowindow.close();
                  });
                }
              }
            }
          }

        }

        if( hasAmenities == false ) return;

        if( this.mapPosition['property'] ) {
          let __lat = 0.0;
          let __lng = 0.0;
          for( let cc = 0; cc < this.mapPosition['property'].length; cc++ ) {
            __lat += this.mapPosition['property'][cc]['lat'];
            __lng += this.mapPosition['property'][cc]['lng'];
          }
          
          centerlat = __lat / this.mapPosition['property'].length;
          centerlng = __lng / this.mapPosition['property'].length;

        }

        

        // Fit all markers in the map bound
        if( this.maxZoom == 0 ) {
          map.fitBounds(bounds);

          map.addListener("zoom_changed", () => {
            if( map.getZoom() > 15 ) {
              map.setZoom(15);
            }
          });  

        }
        
        // Shift map a little right if it's a desktop device else keep the markers in the middle
        if( this.mapPosition['moveToRight'] === true && window.innerWidth > 700 ) {
          if( this.mapPosition['property'].length > 1 ) {
            map.panTo(new google.maps.LatLng(bounds.getCenter().lat() - 0.001, bounds.getCenter().lng() - 0.03 ));   
            
          } else {
            map.panTo(new google.maps.LatLng(bounds.getCenter().lat() - 0.001, bounds.getCenter().lng() - 0.017 ));   
          }
        } else {
          let center = new google.maps.LatLng(centerlat, centerlng);
          map.panTo(center);  

          if( this.maxZoom > 0 ) {
            // console.log(map.getZoom(), parseInt(this.maxZoom), this.mapId );
            map.setZoom( parseInt(this.maxZoom) );
            
          }
          
          
        }
        
    }
  }
};
</script>
