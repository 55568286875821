<template>
  <section>
    <!-- new code starts -->
    <div class="">
      <div class="flex flex-wrap justify-center text-left">
        <div class="lg:w-8/12 lg:px-10 px-5">
          <h2 class="f25-ls006 font-bold text-black text-center npy-40">
            Seleziona la tipologia
          </h2>

          <div class="grid lg:grid-cols-4 grid-cols-2 gap-6">
            <button @click="editTypology('Appartamento')">
              <div class="text-center">
                <div
                  v-bind:class="
                    type == 'Appartamento'
                      ? 'rounded-lg bg-white border-2 border-green selected-type'
                      : 'rounded-lg bg-white text-primary border-2 border-white'
                  "
                >
                  <div class="px-4 py-4 flex-auto">
                    <div class="mb-2">
                      <img
                        class="mx-auto"
                        src="@/assets/img/apartment-icon.png"
                        style="width: 50px;"
                        alt=""
                      />
                    </div>
                    <h6 class="na-label text-black f15-ls004">Appartamento</h6>
                  </div>
                </div>
              </div>
            </button>

            <button @click="editTypology('Casa indipendente')">
              <div class="text-center">
                <div
                  v-bind:class="
                    type == 'Casa indipendente'
                      ? 'rounded-lg bg-white border-2 border-green selected-type'
                      : 'rounded-lg bg-white text-primary border-2 border-white'
                  "
                >
                  <div class="px-4 py-4 flex-auto">
                    <div class="mb-2">
                      <img
                        class="mx-auto"
                        src="@/assets/img/indipendente_icon.png"
                        style="width: 50px;"
                        alt=""
                      />
                    </div>
                    <h6 class="na-label text-black f15-ls004">
                      Casa indipendente
                    </h6>
                  </div>
                </div>
              </div>
            </button>

            <button @click="editTypology('Loft / Open Space')">
              <div class="text-center">
                <div
                  v-bind:class="
                    type == 'Loft / Open Space'
                      ? 'rounded-lg bg-white border-2 border-green selected-type'
                      : 'rounded-lg bg-white text-primary border-2 border-white'
                  "
                >
                  <div class="px-4 py-4 flex-auto">
                    <div class="mb-2">
                      <img
                        class="mx-auto"
                        src="@/assets/img/loft-icon.png"
                        alt="Icona loft"
                        style="width: 50px"
                      />
                    </div>
                    <h6 class="na-label text-black f15-ls004">
                      Loft / Open Space
                    </h6>
                  </div>
                </div>
              </div>
            </button>

            <button @click="editTypology('shop')">
              <div class="text-center">
                <div
                  v-bind:class="
                    type == 'shop'
                      ? 'rounded-lg bg-white border-2 border-green selected-type'
                      : 'rounded-lg bg-white text-primary border-2 border-white'
                  "
                >
                  <div class="px-4 py-4 flex-auto">
                    <div class="mb-2">
                      <img
                        class="mx-auto"
                        src="@/assets/img/rustico.png"
                        style="width: 50px"
                        alt=""
                      />
                    </div>
                    <h6 class="na-label text-black f15-ls004">
                      Rustico / Casale
                    </h6>
                  </div>
                </div>
              </div>
            </button>

            <button @click="editTypology('Attico / Mansarda')">
              <div class="text-center">
                <div
                  v-bind:class="
                    type == 'Attico / Mansarda'
                      ? 'rounded-lg bg-white border-2 border-green selected-type'
                      : 'rounded-lg bg-white text-primary border-2 border-white'
                  "
                >
                  <div class="px-4 py-4 flex-auto">
                    <div class="mb-2">
                      <img
                        class="mx-auto"
                        src="@/assets/img/mansarda-icon.png"
                        style="width: 50px;"

                        alt=""
                      />
                    </div>
                    <h6 class="na-label text-black f15-ls004">Mansarda</h6>
                  </div>
                </div>
              </div>
            </button>

            <button @click="editTypology('Attico')">
              <div class="text-center">
                <div
                  v-bind:class="
                    type == 'Attico'
                      ? 'rounded-lg bg-white border-2 border-green selected-type'
                      : 'rounded-lg bg-white text-primary border-2 border-white'
                  "
                >
                  <div class="px-4 py-4 flex-auto">
                    <div class="mb-2">
                      <img
                        class="mx-auto"
                        src="@/assets/img/attico_icon.png"
                        style="width: 50px;"
                        alt=""
                      />
                    </div>
                    <h6 class="na-label text-black f15-ls004">Attico</h6>
                  </div>
                </div>
              </div>
            </button>
            <button @click="editTypology('Villetta a schiera')">
              <div class="text-center">
                <div
                  v-bind:class="
                    type == 'Villetta a schiera'
                      ? 'rounded-lg bg-white border-2 border-green selected-type'
                      : 'rounded-lg bg-white text-primary border-2 border-white'
                  "
                >
                  <div class="px-4 py-4 flex-auto">
                    <div class="mb-2">
                      <img
                        class="mx-auto"
                        src="@/assets/img/villetta_icon.png"
                        alt="Icona villetta a schiera"
                        style="width: 50px"
                      />
                    </div>
                    <h6 class="na-label text-black f15-ls004">
                      Villetta a schiera
                    </h6>
                  </div>
                </div>
              </div>
            </button>
            <button @click="editTypology('Palazzo / Stabile')">
              <div class="text-center">
                <div
                  v-bind:class="
                    type == 'Palazzo / Stabile'
                      ? 'rounded-lg bg-white border-2 border-green selected-type'
                      : 'rounded-lg bg-white text-primary border-2 border-white'
                  "
                >
                  <div class="px-4 py-4 flex-auto">
                    <div class="mb-2">
                      <img
                        class="mx-auto"
                        src="@/assets/img/building.png"
                        style="width: 50px;"
                        alt=""
                      />
                    </div>
                    <h6 class="na-label text-black f15-ls004">
                      Palazzo / Stabile
                    </h6>
                  </div>
                </div>
              </div>
            </button>
          </div>

          <h3
            class="text-3xl font-semibold text-green text-center mt-10 hidden"
          >
            La tua valutazione <br />è sempre più vicina!
          </h3>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    modelValue: String,
    requestType: String
  },
  data() {
    return {
      type: this.modelValue,
    };
  },
  methods: {
    editTypology(typology) {
      this.type = typology;
      this.$emit("editTypology", typology);
    },
  },
};
</script>