<template>
    <div :class="[
            'nap-map-popup nap-popup-desktop na-overlay fixed my-auto mx-auto w-full h-full bg-white z-30 top-0 left-0',
            showMapPop == true ? 'block' : 'hidden'
        ]" >
            
        <div class="relative nap-popup-wrapper w-full h-full z-40 flex flex-row items-center justify-center">

            <div class="lg:w-11/12 w-full nap-popup-container flex flex-row items-center justify-center bg-white px-4 py-4 br-10">
                <div class="h-full w-full overflow-hidden relative">
                    <div class="absolute right-0 top-0 cursor-pointer" @click="hideMapPopup()" style="height:20px;width:20px;">
                        <close fillColor="#919191" />
                    </div>
                    
                    <h3 class="f20-ls04 text-green text-center font-bold mb-4">Mappa</h3>
                    <div class="w-full h-full " style="height:95%;">
                        <map-listing-page :mapId="popupMap" v-bind:mapPosition="mapPosition" :maxZoom="16" />
                    </div>
                </div>
            </div>
            
            
        </div>


    </div>
</template>

<script>
import Close from "vue-material-design-icons/Close.vue";
import MapListingPage from "@/components/Maps/MapListingPage.vue";

export default{
    props: {
        showMapPop: {
            type: Boolean,
            required: true
        },
        mapPosition: {
            type: Object,
            required: true
        }
    },
    emits: ["hideMapPopup"],
    methods: {
        
        hideMapPopup(){
            this.$emit("hideMapPopup");
        }
        
    },
    data() {
        return {
            popupMap: 'map-desktop-popup',
        }
    },
    components: {
        Close,
        MapListingPage
    }
}

</script>