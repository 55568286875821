<template>
  <section>
    <div class="">
      <div class="flex flex-wrap justify-center text-left">
        <div class="w-full lg:px-10 nmx-15">
          <h2 class="f25-ls006 font-bold text-black text-center npy-40">
            Indica le caratteristiche
          </h2>

          <div class="lg:grid lg:grid-cols-2 lg:gap-24 flex flex-col justify-around">
            <!-- Step 1 -->
            <div class="mx-0">

              <!-- E' presente l'ascensore? -->
              <div class="flex lg:flex-row lg:flex-wrap lg:justify-between flex-col justify-start mt-4 lg:mt-0">
                <div class="relative leading-10 flex items-center">
                  <img
                      class=""
                      src="@/assets/img/icons/steps-icons/ascensore.svg"
                      style="width: 28px;height: 28px;"
                      alt=""
                    />
                  <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                    E' presente l'ascensore?
                  </label>
                </div>
                
                <div class="relative">
                  <div class="relative inline-flex items-center">
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasElevator == true
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasElevator', true)"
                    >
                      Sì
                    </button>
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasElevator == false
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasElevator', false)"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>

              <!-- La casa ha dei balconi? -->
              <div class="flex lg:flex-wrap lg:justify-between flex-col justify-start mt-4">
                <div class="flex lg:flex-row flex-col justify-between">
                  <div class="leading-10 flex items-center">
                    <img
                        class=""
                        src="@/assets/img/icons/steps-icons/balcone.svg"
                        style="width: 28px;height: 28px;"
                        alt=""
                      />
                    <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                      La casa ha dei balconi?
                    </label>
                  </div>
                  
                  <div class="flex">
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasBalcony == true
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasBalcony', true)"
                    >
                      Sì
                    </button>
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasBalcony == false
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasBalcony', false)"
                    >
                      No
                    </button>
                  </div>
                </div>
                <div v-if="planimetry.hasBalcony" class="flex flex-row mt-3">

                  <div class="nw-48">
                    <img
                      class="mx-auto"
                      src="@/assets/img/L-icon.png"
                      style="height:50px;"
                      alt=""
                    />
                  </div>
                  <div class="flex flex-col">
                    <label class="na-label text-489B79 font-bold f15-ls003 mb-1" for="">Quanti?</label>
                    <div class="">
                      <button v-for="index in 4" :key="index"
                        class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                        type="button"
                        v-bind:class="
                          planimetry.numBalcony == index
                            ? 'bg-green text-white'
                            : 'bg-white text-60'
                        "

                        @click="editPlanimetry('numBalcony', index)"
                      >
                        {{ index }} <span v-if="index==4">+</span>
                      </button>
                    </div>
                  </div>
                  
                  
                </div>
              </div>

              <!-- La casa ha un terrazzo? -->
              <div class="flex lg:flex-wrap lg:justify-between flex-col justify-start mt-4">
                <div class="flex lg:flex-row flex-col justify-between">
                  <div class="relative leading-10 flex">
                    <img
                        class=""
                        src="@/assets/img/icons/steps-icons/terrazzo.svg"
                        style="width: 28px;height: 28px;"
                        alt=""
                      />
                    <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                      La casa ha un terrazzo?
                    </label>
                  </div>
                  
                  <div class="inline-flex">
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasTerrace == true
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasTerrace', true)"
                    >
                      Sì
                    </button>
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasTerrace == false
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasTerrace', false)"
                    >
                      No
                    </button>
                    
                  </div>
                </div>
                <div v-if="planimetry.hasTerrace" class="flex flex-row mt-3">
                  <div class="nw-48">
                    <img
                      class="mx-auto"
                      src="@/assets/img/L-icon.png"
                      style="height:50px;"
                      alt=""
                    />
                  </div>
                  <div class="flex flex-col">
                    <label for="" class="na-label text-489B79 font-bold ml-2 f15-ls003 mb-1">Dimensione?</label>

                    <div class="flex flex-row items-center bg-white p-0 rounded-xl">
                      <span @click=" modelValue.terraceMq > 0 ? modelValue.terraceMq-- : 0" class="px-4 f25-ls006  font-bold">-</span>
                      <input
                        type="number"
                        min="1"
                        max="8"
                        class="
                          nh-48
                          ml-1
                          f16-ls004
                          text-black
                          font-bold
                          border-0
                          text-center
                          p-0
                          nw-48
                        "
                        placeholder="mq"
                        :value="modelValue.terraceMq"
                        @input="editPlanimetry('terraceMq', $event.target.value)"
                      />
                      <span @click="modelValue.terraceMq++" class="px-4 f25-ls006 font-bold">+</span>
                    </div>
                    
                  </div>
                </div>
              </div>

              <!-- La casa ha un garage? -->
              <div class="flex lg:flex-wrap lg:justify-between flex-col justify-start mt-4">
                <div class="flex lg:flex-row flex-col justify-between">
                  <div class="relative leading-10 flex items-center">
                    <img
                        class=""
                        src="@/assets/img/icons/steps-icons/garage.svg"
                        style="width: 28px;height: 28px;"
                        alt=""
                      />
                    <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                      La casa ha un garage?
                    </label>
                  </div>
                  
                  <div class="flex items-center">
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:class="
                        planimetry.hasGarage == true
                          ? 'bg-green text-white'
                            : 'bg-white text-black'
                      "
                      @click="editPlanimetry('hasGarage', true)"
                    >
                      Sì
                    </button>
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:class="
                        planimetry.hasGarage == false
                          ? 'bg-green text-white'
                            : 'bg-white text-black'
                      "
                      @click="editPlanimetry('hasGarage', false)"
                    >
                      No
                    </button>

                    
                  </div>
                </div>
                <div class="mt-3 flex flex-row"  v-if=" planimetry.hasGarage == true " >
                  <div class="nw-48">
                    <img
                      class="mx-auto"
                      src="@/assets/img/L-icon.png"
                      style="height:50px;"
                      alt=""
                    />
                  </div>
                  <div class="flex flex-col">
                    <label class="na-label text-489B79 font-bold f15-ls003 mb-1" for="">Quanti?</label>
                    <div class="">
                      <button v-for="index in 4" :key="index"
                        class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                        type="button"
                        v-bind:class="
                          planimetry.garageCount == index
                            ? 'bg-green text-white'
                            : 'bg-white text-60'
                        "
                        @click="editPlanimetry('garageCount', index)"
                      >
                        {{ index }} <span v-if=" index == 4 ">+ </span>
                      </button>
                    </div>
                  </div>
                  
                </div>
              </div>

            </div>

            <!-- Step 2 -->
            <div class="mx-0">

              <!-- La casa ha una cantina?   -->
              <div class="flex lg:flex-row lg:flex-wrap lg:justify-between flex-col justify-start lg:mt-0 mt-4">
                <div class="relative leading-10 flex items-center">
                  <img
                        class=""
                        src="@/assets/img/icons/steps-icons/cantina.svg"
                        style="width: 28px;height: 28px;"
                        alt=""
                      />
                  <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                    La casa ha una cantina?  
                  </label>
                </div>
                
                <div class="relative">
                  <div class="relative inline-flex">
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasCantina == true
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasCantina', true)"
                    >
                      Sì
                    </button>
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasCantina == false
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasCantina', false)"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
              
              <!-- La casa ha un ripostiglio? -->
              <div class="flex lg:flex-row lg:flex-wrap lg:justify-between flex-col justify-start mt-4" >
                <div class="relative leading-10 flex items-center">
                  <img
                      class=""
                      src="@/assets/img/icons/steps-icons/ripostiglio.svg"
                      style="width: 28px;height: 28px;"
                      alt=""
                    />
                  <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                    La casa ha un ripostiglio?
                  </label>
                </div>
                
                <div class="relative">
                  <div class="relative inline-flex">
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasCloset == true
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasCloset', true)"
                    >
                      Sì
                    </button>
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasCloset == false
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasCloset', false)"
                    >
                      No
                    </button>
                    
                  </div>
                </div>
              </div>
              
              <!-- La casa ha garage? -->
              <!-- <div class="flex flex-no-wrap justify-between items-center mt-4">
                <label class="na-label f15-ls004 text-555 font-normal">
                  La casa ha garage?
                </label>
                <div class="relative">
                  <div class="relative inline-flex">
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasGarage != false &&
                        planimetry.hasGarage != null
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasGarage', true)"
                    >
                      Sì
                    </button>
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasGarage == false
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasGarage', false)"
                    >
                      No
                    </button>
                    <input
                      type="number"
                      class="na-input ml-1 w-28 f20-ls04 text-black font-bold"
                      placeholder="Quanti?"
                      v-if="planimetry.hasGarage"
                      :value="modelValue.hasGarage"
                      min="1"
                      max="8"
                      @input="editPlanimetry('hasGarage', $event.target.value)"
                    />
                  </div>
                </div>
              </div> -->
              
              <!-- È presente giardino condominiale? -->
              <div class="flex lg:flex-row lg:flex-wrap lg:justify-between flex-col justify-start mt-4">
                <div class="relative leading-10 flex items-center">
                  <img
                      class=""
                      src="@/assets/img/icons/steps-icons/giardino_condominiale.svg"
                      style="width: 28px;height: 28px;"
                      alt=""
                    />
                  <label class="na-label f17-ls004 text-489B79 font-bold ml-2 lg:flex lg:flex-col lh-20" style="" htmlFor="grid-password">
                    <span>È presente giardino </span><span>condominiale?</span>
                  </label>
                </div>
                
                <div class="relative">
                  <div class="relative inline-flex">
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasSharedGarden == true
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasSharedGarden', true)"
                    >
                      Sì
                    </button>
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasSharedGarden == false
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasSharedGarden', false)"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>

              <!-- Servizio di portineria? -->
              <div class="flex lg:flex-row lg:flex-wrap lg:justify-between flex-col justify-start mt-4">
                <div class="relative leading-10 flex items-center">
                  <img
                      class=""
                      src="@/assets/img/icons/steps-icons/portineria.svg"
                      style="width: 28px;height: 28px;"
                      alt=""
                    />
                  <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                    Servizio di portineria?
                  </label>
                </div>
                
                <div class="relative">
                  <div class="relative inline-flex">
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasConcierge == true
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasConcierge', true)"
                    >
                      Sì
                    </button>
                    <button
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      type="button"
                      v-bind:style="
                        planimetry.hasConcierge == false
                          ? 'background:#489B79;color:white;'
                          : 'background:white; color:black;'
                      "
                      @click="editPlanimetry('hasConcierge', false)"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    modelValue: Object,
    typology: String,
  },
  data() {
    return {
      step: 0,
      planimetry: this.modelValue,
    };
  },
  methods: {
    editPlanimetry(key, event) {
      console.log(key, event);
      this.$emit("editPlanimetry", key, event);
    },
    _editExposition(value) {
      if (this.planimetry.exposition.includes(value)) {
        this.planimetry.exposition.splice(
          this.planimetry.exposition.indexOf(value),
          1
        );
      } else {
        this.planimetry.exposition.push(value);
      }
      this.editPlanimetry("exposition", this.planimetry.exposition);
    },
  },
};
</script>