<template>
  <section>
    <div class="container mx-auto">
      <div class="flex justify-between w-full lg:px-2">
        <div class="w-full lg:mx-10 nmx-15">
          <h2 class="f25-ls006 font-bold text-black text-center npy-40">
            Indica le caratteristiche
          </h2>

          <!-- <div class="grid lg:grid-cols-2 gap-1 md:gap-12 mx-0 "> -->
          <div class="grid lg:grid-cols-2 gap-0 lg:gap-0 mx-0 justify-items-center">
            <!-- step 1 -->
            <div class="mx-0 lg:w-4/6 w-full lg:pl-16">
              <div class="flex flex-wrap items-start mb-6 justify-between pr-5">
                <div class="relative leading-10 flex items-center">
                  <img class="mx-auto" src="@/assets/img/icons/steps-icons/superficie.svg" style="width: 28px;height: 28px;"
                    alt="" />
                  <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                    Superficie
                  </label>
                </div>
                <div>
                  <div class="relative shadow-lg ml-3 bg-white rounded-xl items-center px-4 pt-3 pb-2 lg:mt-2">
                    <div class="
                        flex
                        uppercase
                        text-black text-lg
                        font-bold
                        mb-1
                        text-center
                      ">
                      <input class="text-black font-bold f20-ls04" 
                      type="number"
                      style="
                          border-width: 0px;
                          width: 100px;
                         " :value="this.planimetry.surface" @input="editPlanimetry('surface', $event.target.value)" />
                      <p class="
                          block
                          text-blueGray-600
                          f13-ls003
                          font-bold
                          text-7b
                          lowercase
                        ">
                        mq
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap lg:my-10 my-5">
                <input type="range" min="10" max="300" list="tickmarks" class="na-range-slider"
                  :value="this.planimetry.surface" @input="editPlanimetry('surface', $event.target.value)" />
                <datalist id="tickmarks" class="w-full grid grid-cols-4 mt-4" style="width: 95%">
                  <option class="text-left f12-ls004 text-555 font-normal" value="10" label="10"></option>
                  <option value="100" label="100" class="f12-ls004 text-555 font-normal"></option>
                  <option class="text-center f12-ls004 text-555 font-normal" value="200" label="200"></option>
                  <option class="text-right f12-ls004 text-555 font-normal" value="300" label="300"></option>
                </datalist>
              </div>

              <div class=" flex-wrap items-start lg:mt-4">
                <div class="leading-10 flex items-center">
                  <img class="" src="@/assets/img/icons/steps-icons/locali.svg" style="width: 28px;height: 28px;" alt="" />
                  <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                    Locali
                  </label>
                </div>

                <div class="block items-center justify-center">
                  <div class="place-items-start mb-5 mt-1" role="group">

                    <button v-for="index in 5" :key="index" type="button"
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      v-bind:class="
                        planimetry.locali == index
                          ? 'bg-green text-white'
                          : 'bg-white text-60'
                      " @click="editPlanimetry('locali', index)">{{ index }} <span v-if="index === 5">+</span></button>

                  </div>
                </div>


              </div>
              <div class="flex-wrap items-start lg:mt-4">
                <div class="leading-10 flex items-center">
                  <img class="" src="@/assets/img/icons/steps-icons/bagni.svg" style="width: 28px;height: 28px;" alt="" />
                  <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                    Bagni
                  </label>
                </div>

                <div class="block items-center justify-center">
                  <div class="place-items-start mb-5 mt-1" role="group">
                    <button v-for="index in 4" :key="index" type="button"
                      class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      v-bind:class="
                        planimetry.bathrooms == index
                          ? 'bg-green text-white'
                          : 'bg-white text-60'
                      " @click="editPlanimetry('bathrooms', index)">{{ index }}</button>

                  </div>
                </div>

              </div>

            </div>

            <!-- step 2 -->
            <div class="
                relative
                lg:block
                h-full
                grid
                mx-0
                lg:pr-16
                lg:w-4/6
                w-full
                ">
              <div class=" flex-wrap items-start">
                <div class="leading-10 flex items-center">
                  <img class="" src="@/assets/img/icons/steps-icons/piano.svg" style="width: 28px;height: 28px;" alt="" />
                  <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                    Piano
                  </label>
                </div>

                <div class="block items-center justify-center">
                  <div class="content-center mb-5 mt-1" role="group">

                    <button type="button"
                      class="mr-2 mb-2 px-3 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      v-bind:class="
                        planimetry.floor == 'Seminterrato'
                          ? 'bg-green text-white'
                          : 'bg-white text-60'
                      " @click="editPlanimetry('floor', 'Seminterrato')">Seminterrato</button>
                    <button type="button"
                      class="mr-2 mb-2 px-3 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      v-bind:class="
                        planimetry.floor == 'Terra'
                          ? 'bg-green text-white'
                          : 'bg-white text-60'
                      " @click="editPlanimetry('floor', 'Terra')">Terra</button>

                    <button type="button"
                      class="mr-2 mb-2 px-3 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      v-bind:class="
                        planimetry.floor == 'Rialzato'
                          ? 'bg-green text-white'
                          : 'bg-white text-60'
                      " @click="editPlanimetry('floor', 'Rialzato')">Rialzato</button>
                    <br>

                    <button v-for="index in 12" :key="index" type="button"
                      class="mr-2 mb-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      v-bind:class="
                        planimetry.floor == index
                          ? 'bg-green text-white'
                          : 'bg-white text-60'
                      " @click="editPlanimetry('floor', index)">{{ index }} <span v-if="index === 12">+</span></button>

                  </div>
                </div>

              </div>

              <div class="  flex-wrap items-start lg:mt-4">
                <div class="flex leading-10 items-center">
                  <img class="" src="@/assets/img/icons/steps-icons/piani_stabile.svg" style="width: 28px;height: 28px;" alt="" />
                  <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                    Piani stabile
                  </label>
                </div>

                <div class="block items-center justify-center">
                  <div class="content-center mb-5 mt-1" role="group">
                    <button v-for="index in 12" :key="index" type="button"
                      class="mr-2 mb-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      v-bind:class="
                        (index >= planimetry.floor || ['Seminterrato', 'Terra', 'Rialzato'].includes(planimetry.floor) )
                          ? (planimetry.numFloors == index)
                            ? 'bg-green text-white'
                            : 'bg-white text-60'
                          : 'bg-mediumGrey text-white'
                      " @click="(index >= planimetry.floor || ['Seminterrato', 'Terra', 'Rialzato'].includes(planimetry.floor) ) ? editPlanimetry('numFloors', index) : pass">{{ index }} <span
                        v-if="index === 12">+</span></button>

                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    modelValue: Object,
  },
  computed: {
    getTotalFloorClass() {
      if (planimetry.numFloors == index) {
        return 'bg-green text-white'
      } else {
        return 'bg-white text-60'
      }

      return this.respond === 'responseFound' ? 'btn-yellow' : 'btn-default'
    },
  },
  data() {
    return {
      step: 0,
      planimetry: this.modelValue,
    };
  },
  methods: {
    editPlanimetry(key, event) {
      console.log(key, event);
      

      if(key == "surface"){
        this.$emit("editPlanimetry", "surface", null)
      } 

      if(this.planimetry.floor > this.planimetry.numFloors){
        this.$emit("editPlanimetry", 'numFloors', null);
      }
      this.$emit("editPlanimetry", key, event);

    },
    _editExposition(value) {
      if (this.planimetry.exposition.includes(value)) {
        this.planimetry.exposition.splice(
          this.planimetry.exposition.indexOf(value),
          1
        );
      } else {
        this.planimetry.exposition.push(value);
      }
      this.editPlanimetry("exposition", this.planimetry.exposition);
    },
    _isNumber(value){
      return typeof value === 'number';
    }
  },
};
</script>