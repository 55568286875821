<template>
  <nav class="newarc-menu top-0 left-0 right-0 lg:shadow-none bg-white shadow-none w-full flex flex-wrap items-center justify-between navbar-expand-lg lg:bg-transparent bg-white lg:relative">
    <div class="newarc-menu-container container mx-auto flex flex-wrap items-center justify-between">
      <div class="w-full relative hidden lg:flex lg:w-auto lg:flex-row flex-row-reverse items-center justify-between flex-1">
          <a href="https://newarc-platform.web.app/#/" class="lg:hidden block flex rounded-full bg-white text-black lg:px-3 lg:py-2 p-2 items-center mr-1">
            <account-circle-outline fillColor="#000" />
          </a>
          <a class="lg:mr-4 lg:py-2" href="https://www.newarc.it" >
              <img style="object-fit: cover; max-width:101px; width:101px;" class="" src="@/assets/img/newarc_logo_dark.png"/>
          </a>
          <button class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none" type="button" v-on:click="setNavbarOpen" style="width:45px;">
            <span v-if="!navbarOpen">
              <i class="text-black fas fa-bars"></i>
            </span>
            <span v-else>
              <i class="text-black fas fa-times"></i>
            </span>
            
          </button>
      </div>

      <div class="w-full relative lg:hidden flex lg:w-auto lg:flex-row items-center justify-between">
          <a class="lg:mr-4 lg:py-2" href="https://www.newarc.it" >
              <img style="object-fit: cover; max-width:101px; width:101px;" class="" src="@/assets/img/newarc_logo_dark.png"/>
          </a>
          
          <button v-on:click="setNavbarOpen">
            <span v-if="!navbarOpen" style="font-size:23px">
              <i class="text-black fas fa-bars"></i>
            </span>
            <span v-else  style="font-size:23px">
              <i class="text-black fas fa-times"></i>
            </span>
            
          </button>

          <div v-if="isMobile()" class="mobile-menu" :class="{ 'opened': navbarOpen }">
            <ul class="na-mobile-hamburger-menu flex flex-col list-none">
              <li class="flex items-center">
                <a class="text-black px-3 flex items-center f15-ls003 font-normal" href="https://newarc.it"><strong>Vendi</strong></a>
              </li>
              <li class="flex items-center">
                <router-link :class="['text-black px-3 flex items-center f15-ls003 font-normal', currentRouteName() == 'PropertyListing' ? 'router-link-active' : '']" to="/compra"><strong>Compra</strong></router-link>
              </li>
              <li class="flex items-center">
                <a class="text-black px-3 flex items-center f15-ls003 font-normal" href="https://www.newarc.it/newarc-ristrutturazioni/"><strong>Ristruttura</strong></a>
              </li>
              <li class="flex items-center">
                <a class="text-black px-3 flex items-center f15-ls003 font-normal" href="https://www.newarc.it/programma-agenzie/">Sei un'agenzia?</a>
              </li>
              <li class="flex items-center">
                <a class="text-black px-3 flex items-center f15-ls003 font-normal" href="https://www.newarc.it/le-case-newarc/">Le case Newarc</a>
              </li>
              <li class="flex items-center">
                <a class="text-black px-3 flex items-center f15-ls003 font-normal" href="">Newarc AI</a>
              </li>
            </ul>
          </div>
      </div>

      <div class="lg:flex flex-row justify-center hidden">
        <ul class="na-mid-menu menu-items flex flex-col lg:flex-row list-none lg:ml-auto">
              
              <li class="flex items-center">
                    <a class="lg:hover:text-blueGray-200 text-black px-3 flex items-center f15-ls003 font-normal" href="https://newarc.it" >
                      Vendi
                    </a>
                </li>
                <li class="flex items-center">
                  <router-link :class="['lg:hover:text-blueGray-200 text-black px-3 flex items-center f15-ls003 font-normal', currentRouteName() == 'PropertyListing' ? 'router-link-active' : '' ] " to="/compra">
                  Compra 
                </router-link>
                </li>
              <li class="flex items-center">
                    <a class="lg:hover:text-blueGray-200 text-black px-3 flex items-center f15-ls003 font-normal" href="https://www.newarc.it/newarc-ristrutturazioni/" >
                      Ristruttura
                    </a>
                </li>

        </ul>
      </div>

      <div class="flex-1 lg:flex items-center bg-white lg:bg-opacity-0 lg:shadow-none" :class="[navbarOpen ? 'block rounded shadow-lg new-arc-opened-nav' : 'hidden']" id="example-navbar-warning">
          

          <ul class="menu-items flex flex-col lg:flex-row list-none lg:ml-auto na-list-nav-menu">
              <li class="flex items-center">
                  <a class="px-3 flex items-center f15-ls003 font-normal" href="https://www.newarc.it/programma-agenzie/" >
                  Sei un'agenzia?
                  </a>
              </li>
              <li class="flex items-center">
                  <a class="px-3 flex items-center f15-ls003 font-normal" href="https://www.newarc.it/le-case-newarc/" >
                  Le case Newarc
                  </a>
              </li>

              <li class="flex items-center">
                  <a class="px-3 flex items-center f15-ls003 font-normal" href="" >
                  Newarc AI
                  </a>
              </li>
              
              
              <!-- <li class="flex items-center lg:block hidden">
                  <a href="https://newarc-platform.web.app/#/" class="flex rounded-full bg-white text-black lg:px-3 p-2 items-center shadow">
                    <p class="f14-ls03 font-bold lh-14 mr-1 lg:block hidden">Login</p>
                    <account-circle-outline fillColor="#000" />
                  </a>
              </li> -->
          </ul>
      </div>
      
    </div>
</nav>

  
</template>
<script>
import AccountCircleOutline from 'vue-material-design-icons/AccountCircleOutline.vue';
export default {
  props: {
    // openFilterOnMobile: {type:Function},
    // openMapOnMobile: {type:Function}
  },
  emits: ["toggleSorting"],
  created() {
      // window.addEventListener('scroll', this.stickyNav);
  },
  destroyed() {
      // window.removeEventListener('scroll', this.stickyNav);
  },
  data() {
    return {
      navbarOpen: false
    };
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    stickyNav() {
      if (window.innerWidth < 600) {
        
        if( window.scrollY > 50 ){
          document.getElementsByTagName("nav")[0].classList.add('fixed');    
        } else {
          document.getElementsByTagName("nav")[0].classList.remove('fixed');    
        }
      } 
    },
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
    currentRouteName() {
        return this.$route.name;
    },
    toggleSorting(){
      console.log('testing');
      this.$emit("toggleSorting");
    }
   

  },
  mounted() {
    
  },

  components: {
    AccountCircleOutline
  },
};
</script>
