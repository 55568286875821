<template>

    <div class="flex flex-row justify-between">

        <div class="na-property-filters flex flex-row flex-grow justify-between">

            <div class="na-dropdown-pill primary relative flex flex-row cursor-pointer justify-between items-center"
                @click="_toggleFilter(true)">
                <p>Applica filtri</p>
                <div>
                    <img :style="{
                        'width': '9px',
                        'height': '9px',
                        'background-color': '#A2A2A2',
                        'mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')',
                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/arrow-down.svg`) + '\')'
                    }" class="" />
                </div>

            </div>

            


        </div> 

        <div class="na-mobile-map-grid-toggle flex flex-row items-center justify-end">

            <!-- <div class="flex flex-row items-center">
                <div class="flex items-center">
                    <input type="checkbox" v-model="showMap" class="toggle" id="header-toggle" true-value="true"
                        @change="_toggleMap" false-value="false" />
                    <label class="toggle-label" for="header-toggle"></label>
                </div>
                &nbsp;
                <label class="na-property-map-toggle-label">Mappa</label>
            </div> -->

            <div :class="['na-mobile-toggle-grid-button na-mobile-toggle-show-grid flex items-center justify-center', activeGrid == 'list' ? 'gridlist active' : '']" @click="_toggleView('list')">
                <img :style="{
                        'mask': 'url(\'' + require(`@/assets/img/icons/list_view.svg`) + '\')',
                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/list_view.svg`) + '\')'
                    }" class="" />
                
            </div>

            <div :class="['na-mobile-toggle-grid-button na-mobile-toggle-show-map flex items-center justify-center', activeGrid == 'map' ? 'gridmap active' : '']" @click="_toggleView('map')">
                <img :style="{
                        'mask': 'url(\'' + require(`@/assets/img/icons/map_view.svg`) + '\')',
                        '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/map_view.svg`) + '\')'
                    }" class="" />
                
            </div>

        </div>

    </div>

</template>

<script>

export default {
    props: {
        toggleView: {
            type: Function
        },
        toggleFilter: {
            type: Function
        },
        filterParams: {
            type: Object,
            required: true
        }
    },
    created() {},
    beforeMount(){},
    mounted() {},
    unmounted() {},
    components() {},
    methods: {
        _toggleView( grid ){
            this.activeGrid = grid;
            
            if( window.innerWidth > 844 ) {
                if( window.innerWidth < 1130 && grid == 'map' ) {
                    this.showMap = true;
                } else if( window.innerWidth < 1130 && grid == 'list' ) {
                    this.showMap = false;
                }
            }

            this.$emit("toggleView", grid);
            console.log(grid);
        },
        _toggleFilter(status) {
            this.$emit("toggleFilter", status);
        }
        
    },
    data() {
        return {
            activeGrid: window.innerWidth < 845 ? 'list' : 'map',
            showMap: window.innerWidth < 845 ? false : true
            
        }
    }
}

</script>