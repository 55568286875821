<template>
    <div id="side-bar" class="hidden lg:block lg:w-400 h-full" style="max-height: 900px;">

        <div class="flex flex-col justify-between h-full">
            <div>
                <div class="sidebar-sticky-header flex-none">
                    <div class="flex items-center justify-center sidebar-configure-header">
                        <h2 class="f18-ls3p font-bold" style="color: #000000">CONFIGURA</h2>
                    </div>
                </div>


                <div class="slider-sidebar-content" :style="{
                    'padding-bottom': parseInt(property.timeStatus) < 100 ? '' : '140px'
                }">


                    <div v-if="showStyle == 'true'" class="px-5 mt-10p pb-10p">
                        <h3 class="f12-ls3p">STILI NEWARC</h3>
                        <div class="flex overflow-x-auto mt-10p">
                            <button v-if="configuration.length > 2" class="mr-2"
                                v-on:click="$refs.vueperslides2.previous()"><img class=""
                                    src="@/assets/img/icons/arrow-left.png" alt=""></button>

                            <vueper-slides v-if="configuration.length > 0" ref="vueperslides2" :arrows="false"
                                :bullets="false" :infinite="true" :fixed-height="true"
                                :visible-slides="configuration.length == 1 ? 1 : 2"
                                class="relative no-shadow sidebar-slider-slides">
                                <vueper-slide class="sidebar-slider"
                                    v-for="(config, config_index) in configuration " :key="index">
                                    <template #content>
                                        <div
                                            :class="['flex flex-col w-full relative overflow-hidden sidebar-slide-config-image ', configuration.length > 1 ? 'items-center' : '']">
                                            <img class="" src="@/assets/img/prop-style-slider.png" alt="">
                                            <div class="flex flex-col items-center">
                                                <span class="f13 font-bold mt-1">{{ config.title }}</span>
                                                <span class="f13 mt-1">{{ property.currency }}{{ config.price
                                                }}</span>
                                            </div>

                                        </div>
                                    </template>
                                </vueper-slide>
                            </vueper-slides>
                            <button v-if="configuration.length > 2" class="ml-2"
                                v-on:click="$refs.vueperslides2.next()"><img class=""
                                    src="@/assets/img/icons/arrow-right.png" alt=""></button>
                        </div>


                    </div>
                    <div v-if="showStyle == 'true'" style="height: 0.09px" class=" bg-gray-200 w-full"></div>
                    
                    <div v-if="showStyle == 'true'" class="px-5 pb-10p">
                        <h3 class="f12-ls3p mt-10p">MODIFICA TINTA PARETI</h3>
                        <div class="flex overflow-x-auto mt-10p">
                            <button v-if="configuration.length > 3" class="mr-2"
                                v-on:click="$refs.vueperslides3.previous()"><img class=""
                                    src="@/assets/img/icons/arrow-left.png" alt=""></button>

                            <vueper-slides v-if="configuration.length > 0" ref="vueperslides3" :arrows="false"
                                :bullets="false" :infinite="true" :fixed-height="true"
                                :visible-slides="configuration.length == 1 ? 1 : 3"
                                class="relative no-shadow modification-slider-slides">
                                
                                <vueper-slide class="sidebar-modification-slider"
                                    v-for="(config, config_index) in configuration " :key="index">
                                    <template #content>
                                        <div
                                            :class="['flex flex-col w-full relative overflow-hidden sidebar-modification-image items-center']">
                                            <img class="" src="@/assets/img/modification-image.png" alt="">
                                            <div class="flex flex-col items-center">
                                                <span class="f13 font-bold mt-1">{{ config.title }}</span>
                                                <span class="f13 mt-1">{{ property.currency }}{{ config.price
                                                }}</span>
                                            </div>

                                        </div>
                                    </template>
                                </vueper-slide>
                            </vueper-slides>
                            <button v-if="configuration.length > 3" class="ml-2"
                                v-on:click="$refs.vueperslides3.next()"><img class=""
                                    src="@/assets/img/icons/arrow-right.png" alt=""></button>
                        </div>


                    </div>
                    <div v-if="showStyle == 'true'" style="height: 0.09px" class=" bg-gray-200 w-full"></div>




                    <div class="px-5 pb-10p" v-if="configurationOptions.length > 0">
                        <h3 class="f12-ls3p mt-10p">AGGIUNGI OPTIONAL</h3>



                        <div class="flex flex-col w-full relative sidebar-slide-optional-style-image"
                            :style="{}">
                            <ul class="grid grid-cols-2 gap-4 mt-10p">

                                <li v-for="(configOption, index) in configurationOptions" :key="index"
                                    @click="_selectionConfigurationOption(configOption)" :class="[
                                        'nap-config-options-list nap-sidebar-config-options items-center justify-start relative',
                                        configurationOptionSelected.length > 0 && configurationOptionSelected.indexOf(configOption) != -1 ? 'active' : ''
                                    ]">

                                    <div class="absolute left-2 top-1">
                                        <input type="checkbox"
                                            v-bind:class="['na-custom-radio option-input radio', configurationOptionSelected.length > 0 && configurationOptionSelected.indexOf(configOption) > -1 ? '' : 'hidden']"
                                            :checked="configurationOptionSelected.length > 0 && configurationOptionSelected.indexOf(configOption) != -1">
                                    </div>

                                    <div class="nap-sb-config-option-image-container">
                                        <div class="nap-sb-con-op-image" :style="{
                                            // 'background': `url('${configOption.image}')`
                                            'background': 'url(' + require(`@/assets/img/${configOption.title.toLowerCase().replace(' ', '-').concat('.jpg')}`) + ')'
                                        }">

                                        </div>

                                        <h4 class="nap-sb-con-op-title text-green mt-2">{{ configOption.title }}
                                        </h4>
                                        <span
                                            class="nap-sb-con-op-price nap-config-option-price flex items-center">€{{ parseInt(configOption.price).toLocaleString()}}</span>
                                    </div>

                                    <nar-tooltip :id="`randomid${index}`" :width="200" title=""
                                        :description="configOption.description" position="top">
                                        <div :id="`randomid${index}`" class="absolute right-2 top-2">
                                            <svg class="nap-sb-con-op-tooltip text-black" viewBox="0 0 24 24"
                                                fill="#ECECEC" stroke="#22252B" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round">
                                                <circle cx="12" cy="12" r="10" />
                                                <line x1="12" y1="16" x2="12" y2="12" />
                                                <line x1="12" y1="8" x2="12.01" y2="8" />
                                                >Dark tooltip
                                            </svg>
                                        </div>
                                    </nar-tooltip>

                                </li>
                            </ul>

                        </div>

                    </div>
                </div>
            </div>

            <div class="sidebar-footer flex flex-col flex-none">
                <div class="h-50 w-full bg-gray-200 header-sidebar-progressbar">

                    <div class="na-property-time-slider-container items-center relative w-full lg:w-1/2 bg-white lg:p-1 lg:pt-2 lg:px-4 p-3 br-10 pb-2 lg:shadow-none shadow-lg"
                        style="margin-bottom: 6px;">
                        <div class=" text-center timeline-title" style="margin-bottom: -10px">AVANZAMENTO LAVORI
                        </div>

                        <div class="flex flex-row justify-between">
                            <img width="20" src="@/assets/img/icons/house_started.svg">
                            <img width="20" src="@/assets/img/icons/house_compleated.svg">
                        </div>
                        <div class="na-property-time-slider relative flex flex-row items-center ">
                            <div class="absolute bg-beige w-full z-10 br-5 na-time-slider-all">

                            </div>
                            <div class="absolute bg-green z-20 br-5  na-time-slider-current" :style="{
                                'width': progress.completed + '%'
                            }">
                                <nar-tooltip v-if="progress.completed < 100" :id="`todayDate`" width="200"
                                    textAlign="center" title="" :description="property.formattedTodayDate"
                                    position="top">
                                    <div :id="`todayDate`"
                                        class="absolute identy-icon flex flex-row items-center justify-center bg-green"
                                        :style="{
                                            'right': progress.completed == 100 ? '0px' : '-17px'
                                        }">

                                        <img src="@/assets/img/icons/hammer.svg" class="object-cover" alt="">
                                    </div>
                                </nar-tooltip>
                            </div>
                        </div>
                        <div class="relative hidden lg:flex flex-row justify-between items-center pb-2">
                            <span class="na-time-slider-label">Inizio</span>
                            <span class="na-time-slider-label">Fine</span>
                        </div>
                        <div :class="[
                            'absolute timeline-slider flex justify-center w-full items-center',
                            ''
                        ]" style="margin-left: -15px;">

                            <div :class="[
                                parseInt(property.timeStatus) >= 100 ? 'timeline-completed' : 'timeline-in-progress',
                                'timeline-status px-6']">
                                <span v-if="parseInt(property.timeStatus) >= 100">
                                    Lavori completati!
                                </span>
                                <span v-else>
                                    Configurabile fino al {{ property.formattedEndDate }}
                                </span>


                            </div>
                        </div>

                    </div>

                </div>
                <div class="flex bg-white" style="height: 50px;">
                    <div class="w-1/2  header-slider-price flex flex-col justify-center items-start pl-6">


                        <span class="total"> {{ property.currency }} {{ parseInt(grandTotal).toLocaleString() }}
                        </span>
                        <span class="total-psq">{{ parseInt(property['eurpermq']).toLocaleString() }}
                            €/m²</span>
                    </div>
                    <button @click="showContactPopup()"
                        class="na-property-item-contanct bg-green text-white flex flex-row items-center w-1/2 text-center justify-center font-bold">
                        Contattaci
                        &nbsp;
                    </button>
                    <!--<div
                        class="w-1/2 header-slider-contact-button flex justify-center items-center bg-green-500 text-white relative"><button @click="showContactPopup()">
                        <img class="absolute" style="right: 20px;" src="@/assets/img/icons/white-icon-right.png"
                            height="10" /></button>
                    </div>-->

                </div>
            </div>
        </div>



    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css';

export default {
    props: {
        property: {
            type: Object,
            required: true
        },
        configuration: {
            type: Array
        },
        configurationOptions: {
            type: Array
        },
        configurationOptionSelected: {
            type: Number
        },
        showStyle: {
            type: Boolean
        },
        progress: {
            required: true
        }
        
    },
    emits: ['hideConfigurePopup', 'selectionConfigurationOption'],
    data () {
        return {
        }
    },
    methods: {
        _selectionConfigurationOption(option) {
            this.$emit('selectionConfigurationOption', option);
        }
    },
    components: {
        VueperSlides,
        VueperSlide,
    }
}
</script>