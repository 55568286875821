<template>
  <div>
    <div class="w-full lg:px-10 md:px-8 px-3">
      <h2
      class="f25-ls006 font-bold text-black text-center npy-40">
      Stato dell'abitazione
    </h2>
      <div class="grid lg:grid-cols-4 grid-cols-1 gap-2 md:gap-5 justify-items-center ">
        <div class="w-full ">
          <div v-bind:class="
            status == 'Da ristrutturare'
              ? 'bg-white border-2 border-green rounded-lg selected-type'
              : 'bg-white border-2 border-white rounded-lg'
          ">
            <button @click="editStatus('Da ristrutturare')">
              <div class="m-3 grid grid-cols-3 lg:grid-cols-1 lg:h-80 place-items-stretch gap-2">
                <img style="object-fit: cover" class="w-28 h-20 lg:w-full md:w-56 md:h-40 col-span-1 lg:pl-2 lg:mt-2" src="@/assets/img/da-ristrutturare.jpg"
                  alt="" />

                <div class="col-span-2 pr-2">
                  <h6 class="
                        na-label
                        f17-ls005
                        font-bold
                        text-black text-left
                        ">
                    Da ristrutturare
                  </h6>
                  <p class="text-left text-normal f13-ls004 text-6f ">
                    La casa è in stato originale. Non sono mai avvenute
                    ristrutturazioni. Gli impianti sono molto datati.
                  </p>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div class="w-full">
          <div v-bind:class="
            status == 'Buono / Abitabile'
              ? 'bg-white border-2 border-green rounded-lg selected-type'
              : 'bg-white border-2 border-white rounded-lg'
          ">
            <button @click="editStatus('Buono / Abitabile')">
              <div class="m-3 grid grid-cols-3 lg:grid-cols-1 lg:h-80 place-items-stretch gap-2">
                <img style="object-fit: cover" class="w-28 h-20 lg:w-full md:w-56 md:h-40 col-span-1 lg:pl-2 lg:mt-2" src="@/assets/img/abitabile.jpg"
                  alt="" />

                <div class="col-span-2 pr-2">
                  <h6 class="
                          na-label
                          f17-ls005
                          font-bold
                          text-black text-left
                         
                        ">
                    Abitabile
                  </h6>
                  <p class="text-left text-normal f13-ls004 text-6f ">
                    La casa ha subito delle ristrutturazioni parziali nel tempo.
                    Gli impianti sono abbastanza datati.
                  </p>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div class="w-full">
          <div v-bind:class="
            status == 'Ottimo/Ristrutturato'
              ? 'bg-white border-2 border-green rounded-lg selected-type'
              : 'bg-white border-2 border-white rounded-lg'
          ">
            <button @click="editStatus('Ottimo/Ristrutturato')">
              <div class="m-3 grid grid-cols-3 lg:grid-cols-1 lg:h-80 place-items-stretch gap-2">
                <img style="object-fit: cover" class="w-28 h-20 lg:w-full md:w-56 md:h-40 col-span-1 lg:pl-2 lg:mt-2" src="@/assets/img/ristrutturata.jpg"
                  alt="" />

                <div class="col-span-2 pr-2">
                  <h6 class="
                          na-label
                          text-black
                          f17-ls005
                          font-bold
                          text-left
                         
                        ">
                    Ristrutturata
                  </h6>
                  <p class="text-left text-normal f13-ls004 text-6f ">
                    La casa è stata ristsrutturata in quasi tutti gli ambienti.
                    Gli impianti sono di recente realizzazione.
                  </p>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div class="w-full">
          <div v-bind:class="
            status == 'Nuovo / In costruzione'
              ? 'bg-white border-2 border-green rounded-lg selected-type'
              : 'bg-white border-2 border-white rounded-lg'
          ">
            <button @click="editStatus('Nuovo / In costruzione')">
              <div class="m-3 grid grid-cols-3 lg:grid-cols-1 lg:h-80 place-items-stretch gap-2">
                <img style="object-fit: cover" class="w-28 h-20 lg:w-full md:w-56 md:h-40 col-span-1 lg:pl-2 lg:mt-2" src="@/assets/img/nuova-costruzione.jpg"
                  alt="" />

                <div class="col-span-2 pr-2">
                  <h6 class="
                          na-label
                          text-black
                          f17-ls005
                          font-bold
                          text-left
                        ">
                    Nuova Costruzione
                  </h6>
                  <p class="text-left text-normal f13-ls004 text-6f ">
                    Lo stabile è di recente costruzione o ha comunque meno di 10
                    anni.
                  </p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: String,
  },
  data() {
    return {
      status: this.modelValue,
    };
  },
  methods: {
    editStatus(status) {
      this.status = status;
      this.$emit("editStatus", status);
    },
  },
};
</script>
