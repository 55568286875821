<template>
    <div v-if="toggleSort" class="na-listing-mobile-map fixed top-0 left-0 z-100 w-full h-full bg-white">
        <div class="fixed w-full z-20 bg-white nap-fixed-filter">
          <div
            class="flex flex-row p-4 nap-mobile-nav-action-buttons nap-mobile-nav-action-filter-buttons justify-center relative items-center">
            <button @click="sortProperty('timeRecent')" type="button"
              class="lg:p-0 filter-map px-3 py-2 bg-white br-5 flex flex-row items-center absolute left-3">
              <img src="@/assets/img/icons/bin.svg" alt="">
              <span class="mx-auto">Svuota</span>
            </button>

            <p class="nap-fixed-filter-title">Applica Filtri</p>

            <button @click="toggleSorting()" type="button"
              class="lg:p-0 px-1 py-2 bg-white br-5 flex flex-row items-center close absolute right-3">
              <close fillColor="#3D3D3D" />
            </button>

          </div>
          <div class="flex flex-col px-6 nap-fixed-filter-container overflow-auto items-center" style="max-height:90vh;overflow:auto;">

            <div class="nap-filter-option flex flex-col">
                
                <label v-for="(sortOpt, key ) in sortOptions" class="filter-label flex flex-row items-center py-3">
                    <input type="radio" name="selectedCity" class="na-custom-radio option-input checkbox" :value="key" :checked=" selectedSort == key? true : false "
                    @click="sortProperty(key)">
                    <span>{{ sortOpt }}</span>
                </label>
            </div>

            <button @click="toggleSorting()"
                class="na-property-item-contanct bg-green text-white flex flex-row items-center  text-center justify-center font-bold w-full br-10 mt-10">
                Apply
            </button>

            

            
          </div>

        </div>

    </div>
</template>

<script>
import Close from "vue-material-design-icons/Close.vue";

export default{
    props: {
        sortOptions: {
            required: true
        },
        toggleSort: {
            type: Boolean,
            required: true
        },
        selectedSort: {
            type: String
        }
    },
    emits: ["toggleSorting", "sortProperty"],
    methods: {
        
        toggleSorting(){
            this.$emit("toggleSorting");
        },
        sortProperty( sortBy) {

            this.$emit("sortProperty", sortBy);
        } 
        
    },
    data() {
        return {
            
        }
    },
    components: {
        Close,
        
    }
}

</script>