<template>
  <div v-on:click="hideGoogleAddress()" id="top">
    <navbar />
    <main class="bg-gradient-to-b from-white via-gray-50 to-gray-100">
      <div
        class="banner-desktop relative flex content-center lg:items-center justify-center "
      >
        <!-- <img
          class="absolute lg:block hidden top-0 w-full h-full bg-center bg-cover"
          src="@/assets/img/landing-header.png"
        />
        <img
          class="absolute lg:hidden block top-0 w-full h-full bg-center bg-cover"
          src="@/assets/img/newarc-landing-mobile.jpg"
        /> -->

        <div class="container relative lg:px-10 px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-7/12 px-4 text-center">
              <div class="lg:pr-12">
                <h1 class="text-green font-bold banner-heading">
                  Valuta la tua casa
                </h1>
                <p class="f25-ls006 text-black font-bold text-center banner-sub-heading">
                  Ottieni subito una valutazione precisa e gratuita
                </p>
                <p
                  class="font-normal lg:mt-20 mt-8 banner-subheading f23-ls005 text-75 banner-text"
                >
                  
                </p>

                <div
                  class="relative lg:flex grid relative bg-white rounded-full justify-left mt-4 "
                >
                  <input
                    v-model="houseAddress"
                    style="flex-basis: 70%; outline: none"
                    type="address"
                    placeholder="Inserisci l'indirizzo per iniziare..."
                    ref="houseAddress"
                    v-on:keyup="delayBeforeSearch()"
                    v-on:keyup.enter="goToConfigurator()"
                    class="no-border-input lg:pl-14 pl-5 pr-4 py-4 w-full rounded-full pac-target-input banner-search-elements"
                  />
                  
                  <button
                    class="rounded-full lg:px-10 lg:py-6 p-3 bg-green text-white lg:flex lg:relative absolute right-0 items-center banner-search-elements font-bold "
                    @click="goToConfigurator()"
                  >
                    <span class="lg:block hidden font-bold f20-ls04 mr-4"
                      >Iniziamo</span
                    >
                    <arrow-right fillColor="#FFFFFF" />
                  </button>
                  
                  <ul class="absolute na-google-prediction" v-if=" placesPrediction.length > 0 ">
                    <li v-on:click="setGoogleAddress(i)" v-for="(place, i) in placesPrediction" :key="i">
                      {{ place.description }}
                    </li>
                  </ul>

                </div>
                

              </div>
            </div>
          </div>
        </div>
        <div
          class=" top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px "
          style="transform: translateZ(0)"
        ></div>
      </div>

      <section class="bg-white npy-35">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center text-center mb-5 lg:mb-10">
            <div class="w-full lg:w-12/12 px-4">
              <p  class="text-base text-green font-bold lg:font-normal perche-valutare-con animate__animated ">
                PERCHÈ VALUTARE CON NEWARC?
              </p>

              <h2 class="ottieni-una-valutazi font-bold mt-4 text-black animate__animated ">

                Scopri quanto vale la tua casa in pochi semplici passi.<br class="lg:block hidden" /> Tu inserisci

                i dati, al resto pensiamo noi.
              </h2>
            </div>
          </div>

          <div
            class=" grid lg:grid-cols-3 lg:gap-8 gap-4 lg:w-10/12 mx-auto hover:drop-shadow-xl "
          >
            <div class="lg:py-4 animate__animated ">
              <div class="landing-infobox border rounded-br-3xl rounded-lg p-6 lg:bg-gray-50 bg-white m-2 lg:hover:shadow-2xl lg:h-80 w-30 " v-bind:class=" screen_width >= 1024 ? 'transition ease-out duration-700 transform hover:-translate-y-1 hover:scale-110' : '' " >
                <div class="flex">
                  <img class="lg:mx-start mx-auto" src="@/assets/img/ai.png" />
                </div>
                <h5 class="font-bold mt-5 lg:mt-10 valuation-features text-center lg:text-left">
                  Stima realistica
                </h5>
                <p class="mt-4 f14-ls03 lh-22 text-40 font-normal valuation-features-text text-center lg:text-left">
                  Il nostro sofisticato algoritmo elabora oltre 60 variabili per
                  fornirti una quotazione su cui puoi fare affidamento.
                </p>
              </div>
            </div> 
            <div class="lg:py-4 animate__animated ">
              <div
                class="landing-infobox border rounded-br-3xl rounded-lg p-6 lg:bg-gray-50 bg-white m-2 lg:hover:shadow-2xl lg:h-80 w-30
                "
                v-bind:class="
                screen_width >= 1024
                ? 'transition ease-out duration-700 transform hover:-translate-y-1 hover:scale-110'
                : ''
                "
              >
                <div class="flex">
                  <img class="lg:mx-start mx-auto" src="@/assets/img/conoscenza@2x.png" style="height:74px" />
                </div>
                <h5 class="font-bold mt-5 lg:mt-10 valuation-features text-center lg:text-left">
                  Conoscenza del mercato
                </h5>
                <p class="mt-4 f14-ls03 lh-22 text-40 font-normal valuation-features-text text-center lg:text-left">
                  Diamo il giusto valore alle caratteristiche di una casa e
                  sappiamo quali sono le più ricercate al giorno d'oggi.
                </p>
              </div>
            </div>

            <div class="lg:py-4 animate__animated ">
              <div
                class="landing-infobox border rounded-br-3xl rounded-lg p-6 lg:bg-gray-50 bg-white m-2 lg:hover:shadow-2xl lg:h-80 w-30"
                v-bind:class="
                screen_width >= 1024
                ? 'transition ease-out duration-700 transform hover:-translate-y-1 hover:scale-110'
                : ''
                "
              >
                <div class="flex">
                  <img class="lg:mx-start mx-auto" src="@/assets/img/visita@2x.png"  style="height:74px"/>
                </div>
                <h5 class="font-bold mt-5 lg:mt-10 valuation-features text-center lg:text-left">
                  Nessuna visita
                </h5>
                <p class="mt-4 f14-ls03 lh-22 text-40 font-normal text-center lg:text-left">
                  Coi dati che ci fornirai saremo in grado di valutare le
                  condizioni del tuo immobile senza visite in presenza.
                </p>
              </div>
            </div>
          </div>

          <div class="lg:my-10 my-5 animate__animated ">
            <button v-on:click="goToTop()" class="rounded-full lg:px-12 px-6 py-4 lg:py-6 bg-green text-white flex justify-between items-center mx-auto f20-ls04 lh-55 font-bold" type="button" style="min-width: 300px" >
              <span class="font-bold banner-search-elements mr-4"
                >Ottieni una valutazione</span
              >
              <arrow-right fillColor="#FFFFFF" />
            </button>
          </div>

        </div>
      </section>

      <section class="npy-85 bg-f7 npX-100">
        <div class="lg:grid lg:grid-cols-2 flex flex-col flex-col-reverse container mx-auto">
          <div class="">
            <div class="lg:bg-green relative home-tile-wrapper lg:my-0 my-7 animate__animated " >
              <img class="lg:block hidden lg:absolute home-tile " style="object-fit: contain; width:600px;" src="@/assets/img/home-tile.png">
              <img class="lg:hidden block" src="@/assets/img/house1-mobile@2x.png">
            </div>
            <a class="lg:hidden block animate__animated " href="https://www.newarc.it/le-case-newarc/">
              <p class="flex justify-center mt-5">
                <button class=" rounded-full lg:px-10 px-10 py-3 bg-green text-white flex items-center font-bold f16-ls004" >
                  Scopri di più
                </button>
              </p>
            </a>
          </div>
          <div class="">
            <p class="text-base text-green font-normal perche-valutare-con lg:text-right text-center animate__animated ">LE CASE NEWARC</p>
            <p class="lg:text-right text-center ottieni-una-valutazi text-22252B font-bold lg:mt-10 lh-40 animate__animated ">
              I particolari<br>
              fanno la differenza.
            </p>
            <p class="ogni-nostra-casa-e-p lg:text-right text-center mt-5 animate__animated ">
              Ogni nostra casa è progettata con i più alti standard qualitativi,<br class="lg:block hidden"> con un occhio di riguardo a sostenibilità e innovazione. 
            </p>
            <a class="lg:block hidden animate__animated " href="https://www.newarc.it/le-case-newarc/">
              <p class="flex justify-end mt-5">
                <button class=" rounded-full lg:px-10 px-10 py-3 bg-green text-white flex items-center font-bold f16-ls004" >
                  Scopri di più
                </button>
              </p>
            </a>
            <div class="relative lg:block hidden mt-8 home-tile-small animate__animated " style="height:240px;">
              <img class="absolute" src="@/assets/img/home-tile-small.png">
            </div>
          </div>
          


        </div>
      </section>

      <section class="npy-85 npX-100 home-section-3">
        <div class="lg:grid lg:grid-cols-2 flex flex-col container mx-auto">
          <div class="">
            <p class="text-base text-green lg:font-normal font-bold perche-valutare-con lg:text-left text-center animate__animated ">COSA CI RENDE UNICI</p>
            <p class="lg:text-left text-center ottieni-una-valutazi text-white font-bold lg:mt-10 lh-40 text-white animate__animated ">
              La nostra Intelligenza Artificiale<br>al tuo servizio.
            </p>
            <p class="ogni-nostra-casa-e-p lg:text-left text-center mt-5 text-9f animate__animated ">

               
              Insieme alla valutazione, ricevi anche il report con i motivi <br class="lg:block hidden"> che ci hanno guidato verso la stima.
            <br class="lg:block hidden">
              Non ti diciamo solo quanto vale, ti spieghiamo il perché.

            </p>
            <a href="https://www.newarc.it/newarc-ai/" class="animate__animated ">
              <p class="flex justify-start mt-5 lg:block hidden">
                <button class=" rounded-full lg:px-10 px-10 py-3 bg-green text-white flex items-center font-bold f16-ls004 animate__animated ">
                  Scopri di più
                </button>
              </p>
            </a>
          </div>
          <div class="">
            <div class="relative home-section-3-image-wrapper my-5 animate__animated " style="">
              <img class="lg:absolute home-section-3-image" src="@/assets/img/mockup.png">
            </div>

            <p class="flex justify-center lg:hidden block">
              <button class=" rounded-full lg:px-10 px-10 py-3 bg-green text-white flex items-center font-bold f16-ls004 animate__animated ">
                Scopri di più
              </button>
            </p>
          </div>

        </div>
      </section>

      <section class="bg-green npy-85 relative">
        <div class="container mx-auto">
          <p class="text-white text-center textimonial-title relative container mx-auto f32-ls008 lh-43 font-bold animate__animated ">Cosa pensano di noi<br>i nostri clienti.</p>
          <vueper-slides autoplay :arrows="true" class=" no-shadow new-testimonal relative container mx-auto" :fixed-height="true">
            
            <vueper-slide
              v-for="(slide, i) in slides"
              :key="i">
              <template #content>
                <div class="flex w-full lg:w-1/2 lg:flex-row flex-col h-full justify-content-center lg:justify-center justify-start mx-auto items-center bg-green animate__animated ">
                  <div class="items-center flex items-center p-5">
                    <img class="rounded-full lg:w-full w-1/3 lg:mx-start mx-auto" :src="slide.image" alt="">
                  </div>
                  <div class="flex flex-col">
                    <div class="lh-23 f13-ls047 lg:text-left text-center text-white font-normal px-5 lg:px-10">
                      {{ slide.content }}
                    </div>
                    <div class="lh-23 f13-ls047 text-white font-normal italic mt-5 px-5 lg:px-10 lg:text-left text-center"> 
                      {{ slide.title }}
                    </div>
                  </div>
                </div>
              </template>
            </vueper-slide>
          </vueper-slides> 
        </div>
        
      </section>

    </main>
    <footer-component />

    <div id="hidden-tmp-map" class="hidden"></div>
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import ArrowRight from "vue-material-design-icons/ArrowRight.vue";
import InformationVariant from "vue-material-design-icons/InformationVariant.vue";
import MapExample from "@/components/Maps/MapExample.vue";
import { createPopper } from "@popperjs/core";
import {Animate} from 'animate.css';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'


var presetAddress = null;

export default {
  mounted() {

    window.addEventListener('scroll', this.handleSCroll);
    window.addEventListener('resize', this.handleWindowResize);

    this.screen_width = window.innerWidth;
    console.log(this.screen_width);
    
  },
  methods: {
    handleWindowResize(){
      this.screen_width = window.innerWidth;
      console.log(this.screen_width);
    },
    handleSCroll() {

      var list = document.querySelectorAll('.animate__animated');

      for (var i = 0; i < list.length; i++) {
          var me = list[i];
          if( (me.offsetTop - (window.innerHeight/1.3) ) <= window.scrollY && !me.classList.contains('animate__fadeInUp') ) {
            me.classList.add("animate__fadeInUp");
          }
      }

      let header = document.querySelector(".newarc-menu");
      if( window.scrollY > 50 ) {
        header.classList.add('menu-sticky'); 
        // header.classList.remove('fixed'); 
      } else {
        header.classList.remove('menu-sticky'); 
        // header.classList.add('fixed'); 
      }
      
      
    },
    toggleSlidesTime () {
      if (this.slidesTimeTimerId) {
        clearInterval(this.slidesTimeTimerId)
        this.slidesTimeTimerId = 0
      } else {
        this.updateSlidesWithTime()
        this.slidesTimeTimerId = setInterval(this.updateSlidesWithTime, 1000)
      }
    },
    updateSlidesWithTime () {
      this.slides.forEach(slide => {
        let time = new Date()
        slide.title = time.toLocaleTimeString()
        slide.content = 'Time in 5 hours: ' + new Date(time.getTime() + 5 * 3600000).toLocaleTimeString()
      })
    },
    toggleTooltip(i) {
      let ref;
      let tooltipRef;
      if (i == 0) {
        ref = this.$refs.btnRef0;
        tooltipRef = this.$refs.tooltipRef0;
      } else if (i == 1) {
        ref = this.$refs.btnRef1;
        tooltipRef = this.$refs.tooltipRef1;
      } else if (i == 2) {
        ref = this.$refs.btnRef2;
        tooltipRef = this.$refs.tooltipRef2;
      }

      if (this.tooltipShow[i]) {
        this.tooltipShow[i] = false;
      } else {
        this.tooltipShow[i] = true;
        createPopper(ref, tooltipRef, {
          placement: "bottom",
        });
      }
    },
    goToTop() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 130)
      }, 20)
    },
    setLocation(latitude, longitude) {
      this.latitude = latitude;
      this.longitude = longitude;
    },
    async delayBeforeSearch(){
      this.placesPrediction = [ { description: "Searching..."}];
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.checkPrediction();
      }, this.delayBeforeSearchTimer);
      
    },
    async checkPrediction(){
      
      let ignore_words = [
        "corso via",
        "via corso"
      ];

      const found_ignored = ignore_words.findIndex(element => {
        return element.toLowerCase() === this.houseAddress.toLowerCase().trim();
      });
      
      if( this.houseAddress.length < 4 || found_ignored === 0 ) return;

      const service = new google.maps.places.AutocompleteService();

      service.getPlacePredictions(
        { 
          input: this.houseAddress,
          componentRestrictions: { country: "it" },
          fields: ['formatted_address', 'geometry', 'name'],
        }, 
        function(predictions,status){

        if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
          console.log(status);
          presetAddress = null;
          return;
        }
    
      }).then( (predictions) => {


        if( predictions['predictions'].length === 0 ) return;

        this.placesPrediction = [];
        const map = new google.maps.Map(
          document.getElementById("hidden-tmp-map"),
          {
            center: { lat: -33.866, lng: 151.196 },
            zoom: 15,
          }
        );

        for (let index = 0; index < predictions['predictions'].length ; index++) {
          const placeElement = predictions['predictions'][index];
          this.placesPrediction.push(placeElement);
        }

        const request = {
          placeId: predictions['predictions'][0]['place_id']
        };

        const service = new google.maps.places.PlacesService(map);

        service.getDetails(request, (place, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            place &&
            place.geometry &&
            place.geometry.location
          ) {
            presetAddress = place;
            
            localStorage.setItem(
              "latitude",
              place.geometry.location.lat()
            );
            localStorage.setItem(
              "longitude",
              place.geometry.location.lng()
            );

            
            
          } else {
            presetAddress = null;
          }
        });

        // this.placesPrediction = placePredict;
        return;
      });
    },
    hideGoogleAddress(){
      this.placesPrediction = [];
    },
    setGoogleAddress( index ){
      console.log(index);
      this.houseAddress = this.placesPrediction[index].description;
      this.googleAddress = presetAddress;
      this.placesPrediction = [];

    },
    goToConfigurator() {
      let postalCode = "";
      let streetNumber = "";
      let address = "";
      let city = "";

      var divsToHide = document.getElementsByClassName("pac-container"); //divsToHide is an array
      for(var i = 0; i < divsToHide.length; i++){
          divsToHide[i].style.display = "none"; // depending on what you're doing
      }

      if( this.placeSelected == false && presetAddress == null) {
        console.log('No address is selected');
        return;
      } else if( this.placeSelected == false && presetAddress != null ) {
        this.placeSelected = true;
        this.houseAddress = presetAddress.formatted_address;
        this.googleAddress = presetAddress;
      }
      // TODO da sostituire con selezione in base a types[] come da map di google address
      if (this.googleAddress.address_components.length == 8) {
        streetNumber = this.googleAddress.address_components[0].short_name;
        address = this.googleAddress.address_components[1].short_name;
        city = this.googleAddress.address_components[2].short_name;
      } else {
        address = this.googleAddress.address_components[0].short_name;
        city = this.googleAddress.address_components[1].short_name;
      }

      let latitude = localStorage.getItem("latitude");
      let longitude = localStorage.getItem("longitude");
      if (this.googleAddress.address_components.length == 8) {
        postalCode = this.googleAddress.address_components[7].short_name;
      }

      this.$router.push({
        path: "/configure",
        query: {
          address: address,
          streetNumber: streetNumber,
          city: city,
          postalCode: postalCode,
          lat: latitude,
          lng: longitude,
        },
      });
    },
    getImgUrl(img) {
      // var images = require.context('../assets/', false, /\.png$/)
      // console.log(img);
      // return '@/assets/img/' + img;
    }
  },
  data() {
    return {
      screen_width: 0,
      placeSelected: false,
      searchResults: null,
      slidesTimeTimerId: 20,
      slides: [
        { 
          image: require('@/assets/img/prof-casa.png'),
          title: 'Salvatore Sigismondi, Professione Casa', 
          content: '“Il team di Newarc si è dimostrato fin da subito serio e professionale…. L’immobile che avevamo in vendita è stato acquistato in meno di due settimane e rivenduto su carta in meno di un mese con commissioni piene pagate sia in acquisto che rivendita…”' 
        },
        
      ],
      tooltipShow: [false, false, false],
      // presetAddress: "",
      houseAddress: "",
      fullAddress: null,
      latitude: 0,
      longitude: 0,
      mockMapCoordinates: {
        latitude: 45.0769239,
        longitude: 7.6200728,
      },
      placesPrediction: [],
      delayBeforeSearchTimer: 500
    };
  },
  components: {
    Navbar,
    FooterComponent,
    ArrowRight,
    MapExample,
    InformationVariant,
    Animate,
    VueperSlides, 
    VueperSlide
  },
};


</script>
