import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import { getFunctions } from "firebase/functions";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const productionConfig = {
  apiKey: "AIzaSyCQonHGTeEdhNQV1XJufEyu-B6rP1msc6Y",
  authDomain: "newarc.firebaseapp.com",
  projectId: "newarc",
  storageBucket: "newarc.appspot.com",
  messagingSenderId: "593015570351",
  appId: "1:593015570351:web:bceaad57aee0ca74d8f95a"
};

// firebase init
const stagingConfig = {
//   apiKey: process.env.VUE_APP_API_KEY,
//   authDomain: process.env.VUE_APP_KEY_AUTH_DOMAIN,
//   databaseURL: process.env.VUE_APP_KEY_DATABASE_URL,
//   projectId: process.env.VUE_APP_PROJECT_ID,
//   storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
//   appId: process.env.VUE_APP_ID
    apiKey: "AIzaSyBq5h5fMtUIE5i0LKSY8B5y8s6SdG_g1dQ",
    authDomain: "newarc-staging.firebaseapp.com",
    projectId: "newarc-staging",
    storageBucket: "newarc-staging.appspot.com",
    messagingSenderId: "10485153929",
    appId: "1:10485153929:web:045ee5077dbb8a66ff0658"
}

const firebaseApp = firebase.initializeApp(productionConfig)
// firebase.functions().useEmulator("http://localhost:5000");

// firebase db
const db = firebase.firestore()

// firebase auth
const auth = firebase.auth()

// firebase collections
const notesCollection = db.collection('notes')

const functions = getFunctions(firebaseApp);
const storage = getStorage(firebaseApp);

export {
  auth,
  db,
  firebase,
  notesCollection,
  functions,
  storage
}