<template>
  <section>
    <div class="">
      <div class="flex flex-wrap justify-center text-left">
        <div class="w-full nmx-15">
          <h2 class="f25-ls006 font-bold text-black text-center npy-40">
            Indica le caratteristiche
          </h2>


          <div class="lg:grid lg:grid-cols-2 lg:gap-24 flex flex-col justify-around">            

            <!-- Step 1 -->
            <div class="lg:mx-auto mx-0 lg:pl-16 lg:w-3/4">


              <!-- Anno di costruzione della casa? -->
              <div class="flex lg:flex-wrap lg:justify-between flex-col justify-start mt-4 lg:mt-0">
                <div class="relative leading-10 flex items-center">
                  <img
                      class="nh-30 nw-30"
                      src="@/assets/img/icons/costruzione.svg"
                      alt=""
                    />
                  <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                    Anno di costruzione della casa?
                  </label>
                </div>
                
                <div class="flex flex-col justify-between mt-2">
                  <div class="relative inline-flex items-center justify-between">
                    
                    <div class="w-1/2 flex flex-row justify-between items-center bg-white p-0 rounded-xl shadow-xl">
                      <span @click=" modelValue.yearBuilt > 1800 ? modelValue.yearBuilt-- : 0" class="px-4 f25-ls006  font-bold">-</span>
                      <input
                        type="number"
                        :value="modelValue.yearBuilt" min="1800" max="2025"
                        placeholder="Anno"
                        style="width:80px;"
                        class="nh-48 ml-1 f16-ls004 text-black font-bold border-0 text-center p-0 nw-1/2"
                        @input="editPlanimetry('yearBuilt', $event.target.value)"
                      />
                      <span @click="modelValue.yearBuilt++" class="px-4 f25-ls006 font-bold">+</span>
                    </div>
                    
                    <button class=" px-6 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out" type="button" v-bind:class="
                        planimetry.unknownBuiltYear == true
                          ? 'bg-green text-white'
                          : 'bg-white text-60'
                        " @click="
                          editPlanimetry(
                            'unknownBuiltYear',
                            !planimetry.unknownBuiltYear
                          )
                        ">
                      Non so
                    </button>
                  </div>
                  <div class="flex flex-wrap lg:mt-10 mt-8">
                    <input type="range" min="1800" max="2050" list="tickmarks" style="width:100%"
                      :value="this.planimetry.yearBuilt" @input="editPlanimetry('yearBuilt', $event.target.value)" />
                    <datalist id="tickmarks" class="w-full grid grid-cols-3 mt-4" style="width: 100%">
                      <option class="text-left f12-ls004 text-555 font-normal" value="1800" label="1800"></option>
                      <option value="1925" label="1925" class="f12-ls004 text-555 font-normal text-center"></option>
                      <option class="text-right f12-ls004 text-555 font-normal" value="2050" label="2050"></option>
                    </datalist>
                  </div>
                </div>
              </div>

              <!-- Classe energetica -->
              <div class="flex lg:flex-row lg:flex-wrap lg:justify-between flex-col justify-start mt-4">
                <div class="relative leading-10 flex items-center">
                  <img
                      class="nh-30 nw-30"
                      src="@/assets/img/icons/classe_energetica.svg"
                      alt=""
                    />
                  <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                    Classe energetica
                  </label>
                </div>
                <div class="relative mt-2">
                  <div class="flex flex-wrap items-center">
                    
                    <button v-for="eC in energyClass" :key="eC" type="button"
                      class="mr-2 mb-2 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                      v-bind:class="[
                        planimetry.energyClass == eC
                          ? 'bg-green text-white'
                          : 'bg-white text-60',
                        eC.length > 2 ? 'px-5' : 'nw-48'
                      ]" @click="editPlanimetry('energyClass', eC)">{{ eC }} </button>
                    
                    <button class="mr-2 mb-2 px-5 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out" type="button" v-bind:class="
                      planimetry.unknownEnergyClass == true
                        ? 'bg-green text-white'
                        : 'bg-white text-60'
                    " @click="editPlanimetry('unknownEnergyClass',!planimetry.unknownEnergyClass)">
                      Non so
                    </button>
                  </div>
                </div>
              </div>
              
            </div>

            <!-- Step 2 -->

            <div class="lg:mx-auto mx-0 lg:pr-16 lg:w-3/4">


              <!-- Conosci l'esposizione? -->
              <div class="flex lg:flex-col lg:flex-wrap lg:justify-between flex-col justify-start lg:mt-0 mt-4">
                <div class="relative leading-10 flex items-center">
                  <img
                      class="nh-30 nw-30"
                      src="@/assets/img/icons/esposizione.svg"
                      alt=""
                    />
                  <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                    Conosci l'esposizione?
                  </label>
                </div>
                
                <div class="relative mt-2">
                  <div class="relative inline-flex">
                    <button class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out" type="button" v-bind:class="
                      planimetry.knowExposition == true
                        ? 'bg-green text-white'
                          : 'bg-white text-60'
                    " @click="editPlanimetry('knowExposition', true)">
                      Sì
                    </button>
                    <button class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out" type="button" v-bind:class="
                      planimetry.knowExposition == false
                        ? 'bg-green text-white'
                          : 'bg-white text-60'
                    " @click="editPlanimetry('knowExposition', false)">
                      No
                    </button>
                  </div>
                </div>

                <div class="mt-3 flex flex-row"  v-if="planimetry.knowExposition" >
                  <div class="nw-48">
                    <img
                      class="mx-auto"
                      src="@/assets/img/L-icon.png"
                      style="height:50px;"
                      alt=""
                    />
                  </div>
                  <div class="flex flex-col">
                    <label class="na-label text-489B79 font-bold f15-ls003 mb-1" for="">Indica una o più esposizioni</label>
                    <div class="">
                      <button v-for="_dir in propertyDirection" :key="_dir"
                        class="mr-2 mb-2 lg:px-5 px-3 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                        type="button"
                        v-bind:class="
                          planimetry.exposition.includes(_dir)
                            ? 'bg-green text-white'
                          : 'bg-white text-60'
                        "
                        @click="_editExposition(_dir)"
                      >
                        {{ _dir }}
                      </button>
                    </div>
                  </div>
                  
                </div>
                
              </div>

              <div class="flex lg:flex-row lg:flex-wrap lg:justify-between flex-col justify-start mt-4 lg:mt-4">
                <div class="relative leading-10 flex items-center">
                  <img
                      class="nh-30 nw-30"
                      src="@/assets/img/icons/riscaldamento.svg"
                      alt=""
                    />
                  <label class="na-label f17-ls004 text-489B79 font-bold ml-2" htmlFor="grid-password">
                    Riscaldamento
                  </label>
                </div>
                <div class=" mt-2">
                  <button v-for="_dir in propertyHeating" :key="_dir"
                    class="mb-2 mr-2 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                    type="button"
                    style="width:45%"
                    v-bind:class="
                      planimetry.heating == _dir
                        ? 'bg-green text-white'
                          : 'bg-white text-60'
                    "
                    @click="editPlanimetry('heating',_dir)"
                  >
                    {{ _dir }}
                  </button>

                </div>

                <div class="mt-3 flex flex-row"  v-if=" planimetry.heating == 'Autonomo' || planimetry.heating == 'Centralizzato'" >
                  <div class="nw-48">
                    <img
                      class="mx-auto"
                      src="@/assets/img/L-icon.png"
                      style="height:50px;"
                      alt=""
                    />
                  </div>
                  <div class="flex flex-col">
                    <label class="na-label text-489B79 font-bold f15-ls003 mb-1" for="">Con termovalvole?</label>
                    <div class="">
                      <button 
                        class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                        type="button"
                        v-bind:class="
                          planimetry.termovalvole == true
                            ? 'bg-green text-white'
                          : 'bg-white text-60'
                        "
                        @click="editPlanimetry('termovalvole', true)"
                      >
                        Si
                      </button>

                      <button 
                        class="mr-2 nw-48 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                        type="button"
                        v-bind:class="
                          planimetry.termovalvole == false
                            ? 'bg-green text-white'
                          : 'bg-white text-60'
                        "
                        @click="editPlanimetry('termovalvole', false)"
                      >
                        No
                      </button>

                      <button 
                        class="mr-2 px-5 nh-48 hover:shadow-md focus:shadow-lg rounded inline-block f16-ls004 font-bold text-center  focus:outline-none active:shadow-lg transition duration-150 ease-in-out"
                        type="button"
                        v-bind:class="

                          planimetry.termovalvole == 'Non so'
                            ? 'bg-green text-white'
                          : 'bg-white text-60'
                        "
                        @click="editPlanimetry('termovalvole', 'Non so')"

                      >
                        Non so
                      </button>
                    </div>
                  </div>
                  
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  props: {
    modelValue: Object,
    typology: String,
    energyClass: String,
    propertyDirection: String,
    propertyHeating: String
  },
  
  data() {
    return {
      step: 0,
      planimetry: this.modelValue,
      energyClass: ['A+','A','B','C','D','E','F','G','Non classificabile','In attesa'],
      propertyDirection: ['Nord','Sud','Est','Ovest'],
      propertyHeating: ['Autonomo','Centralizzato','Assente','Non so']
    };
  },
  methods: {
    editPlanimetry(key, event) {
      console.log(key, event);


      if (key == "unknownEnergyClass" && event == true) {
       this.$emit("editPlanimetry", "energyClass", null);
      }
      if (key == "energyClass") {
       this.$emit("editPlanimetry", "unknownEnergyClass", false);
      }
      this.$emit("editPlanimetry", key, event);
    },
    _editExposition(value) {
      if (this.planimetry.exposition.includes(value)) {
        this.planimetry.exposition.splice(
          this.planimetry.exposition.indexOf(value),
          1
        );
      } else {
        this.planimetry.exposition.push(value);
      }
      this.editPlanimetry("exposition", this.planimetry.exposition);
    },
    _isNumber(value){
      return typeof value === 'number';
    }
  },
};
</script>