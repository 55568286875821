<template>
   <div class="fixed w-full bottom-0 left-0 bg-white py-2 z-50 na-progress-bar">
      <div class="container mx-auto px-4">
         <!-- desktop navigation -->
         <div class="flex justify-between lg:gap-0 gap-4 items-center">
            <div class="">
               <button
                  class="flex text-grey p-4 rounded-full float-left f20-ls04 font-bold"
                  style="background: #Cccccc;"
                  type="button"
                  v-on:click='decreaseStep()' 
                  >
                  <img
                  src="@/assets/img/Indietro.png" 
                  style="width:20px;"
                  alt="avanti"
                />
               </button>
            </div>
            <div class="flex-grow">
               <div class="w-full px-4">
                  <div
                     class="na-progress-bar-bg text-grey uppercase  rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                     >
                     <div
                        class="bg-green text-sm text-white font-bold uppercase px-6  rounded-full outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 py-1 text-center"
                        :style="{width: this.completionPercentage+'%'}"
                        ><span class="f12-ls003 font-bold">
                           <span class="lg:block hidden">{{this.stepNames[this.modelValue]}}</span>
                           <span class="lg:hidden block">&nbsp;</span>
                        </span>
                     </div>
                  </div>
               </div>
            </div>
            <div  class="">
               <button
                v-bind:class="
                    nextStepButtonVisible == true
                      ? 'flex bg-green text-white p-4 rounded-full float-right f20-ls04 font-bold'
                      : 'flex bg-green-opaque text-white p-4 rounded-full float-right f20-ls04 font-bold'
                  "
                  type="button"
                  v-on:click='increaseStep()'
                  >
                  <img
                  src="@/assets/img/Avanti.png"
                  style="width:20px; "
                  alt="avanti"
                />
                  
               </button>
            </div>
         </div>

         
      </div>
   </div>
</template>
<script>
   import ArrowRight from 'vue-material-design-icons/ArrowRight.vue';
   import ArrowLeft from 'vue-material-design-icons/ArrowLeft.vue';
   
   export default {
     props:{
       modelValue: Number,
       nextStepButtonVisible: Boolean
     },
     components: {
       ArrowRight,
       ArrowLeft
     },
     emits: ['increaseStep', 'decreaseStep'],
     data() {
      return {
         stepNames:['Indirizzo', 'Tipologia', 'Condizioni interne', 'Foto e planimetria','Caratteristiche','Caratteristiche', 'Caratteristiche','Informazioni aggiuntive',  'Informazioni aggiuntive'],
       };
     },
     computed: {
       completionPercentage: function () {
       return 100*(this.modelValue+1) / this.stepNames.length
     }
     },
     methods:{
       increaseStep(){
         if(this.nextStepButtonVisible){
            this.$emit("increaseStep");
            
         }
           
       },
       decreaseStep(){
         this.$emit("decreaseStep");
       }
     }
   };
</script>