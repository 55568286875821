<template>
  <nav
    class="top-0 z-50 w-full flex flex-wrap items-center justify-between navbar-expand-lg bg-green"
   ref="naConfigureNavBar">
    <div class="container mx-auto">
      <div class="flex justify-between w-full text-white na-nav-bar lg:px-2 px-2 items-center">

        <div>
          <!--<a :href="`${website_homepage_url}`" class="text-white text-sm font-bold leading-relaxed inline-block py-2 whitespace-nowrap uppercase">-->
          <a href='https://www.newarc.it' class="text-white text-sm font-bold leading-relaxed inline-block py-2 whitespace-nowrap uppercase">
            <img style="object-fit: cover; width: 112px;" class="lg:block hidden" src="@/assets/img/logo-light.png"/>
            <img style="object-fit: cover;" class="lg:hidden block" src="@/assets/img/logo-icon-light.png"/>
          </a>
            
        </div>
        <div class="flex-1">
          <h2 class="f20-ls04 font-bold lg:block hidden text-center">{{modelValue.address}} {{modelValue.streetNumber}}, {{modelValue.postalCode}}, {{modelValue.city}}</h2> 
          <span class="lg:hidden block">
            <h2 class="f20-ls04 font-bold text-center p-0 m-0">{{modelValue.address}} {{modelValue.streetNumber}},</h2>
            <h2 class="f20-ls04 font-normal text-center p-0 m-0">{{modelValue.postalCode}}, {{modelValue.city}}</h2> 
          </span>
          
        </div>
        <!--<div class="lg:ml-6">
          
          <a href="#" class="flex rounded-full bg-white text-black lg:px-3 lg:py-2 p-2 items-center">
            <p class="f14-ls03 font-bold lh-14 mr-1 lg:block hidden">Aiuto</p>
            <help-circle-outline fillColor="#000" />
          </a>

        </div>-->

      </div>
    </div>
    
  </nav>
  
</template>
<script>
import HelpCircleOutline from 'vue-material-design-icons/HelpCircleOutline.vue';
export default {
  props:{
    modelValue: Object
  },
  /*created(){
    console.log(this.modelValue)
    this.addressObject.address = this.$route.query.address
    this.addressObject.streetNumber = this.$route.query.streetNumber
    this.addressObject.city = this.$route.query.city
    this.addressObject.postalCode = this.$route.query.postalCode
    this.fullAddress = this.addressObject.address + ', ' + this.addressObject.streetNumber + ', ' + this.addressObject.postalCode +  ', ' + this.addressObject.city
  },*/
  data() {
    return {
      navbarOpen: false,
      fullAddress: "",
      addressObject: {
        address: "",
        streetNumber: "",
        city: "",
        postalCode: "",
      },
      website_homepage_url: process.env.VUE_APP_URL,
      url: process.env.VUE_APP_URL, 
    };
  },
  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
  },
  components: {
    HelpCircleOutline
  },
};
</script>
