<template>
    <div :class="[
            'nap-map-popup nap-popup-desktop na-overlay fixed my-auto mx-auto w-full h-full bg-white z-30 top-0 left-0',
            displayStyleMoreInfo == true ? 'block' : 'hidden'
        ]" >
            
        <div class=" relative nap-popup-wrapper w-full h-full z-40 flex flex-row items-center justify-center">

            <div class="lg:w-11/12 w-full nap-popup-container flex flex-row items-center justify-center bg-white px-4 py-4 br-10 nap-mi-wrapper">
                <div class="h-full w-full overflow-hidden relative">
                    <div class="lg:absolute lg:right-0 lg:top-0 cursor-pointer fixed right-4 top-2 z-100 cursor-pointer" @click="toggleStyleMoreInfo()" style="height:20px;width:20px;">
                        <close fillColor="#919191" />
                    </div>
                    <div class="lg:relative fixed top-0 left-0 w-full bg-white pt-3 lg:py-0">
                        <h3 class="nap-mi-title mb-4">Caratteristiche stile</h3>
                        <h3 class="nap-mi-sub-title mb-4">{{ selectedDesign }}</h3>
                    </div>
                    
                    
                    <div v-if=" selectedDesign != '' " class="nap-mi-container w-full grid lg:grid-cols-4 grid-cols-2 lg:gap-16 gap-2 lg:px-20 pb-20">
                        
                        <div v-for="design in styleMoreInfo[selectedDesign] " class="nap-mi-tile flex flex-col lg:justify-center justify-start items-center">
                            <img class="nap-mi-tile-image" :src="require(`@/assets/img/${design['image']}`)">
                            <p class="nap-mi-tile-title">{{ design['title'] }}</p> 
                            <p class="nap-mi-tile-description">{{ design['description'] }}</p> 

                        </div>

                        <!-- <div class="nap-mi-tile flex flex-col justify-center items-center">
                            <img class="nap-mi-tile-image" src="@/assets/img/stoccolma3.jpg">
                            <p class="nap-mi-tile-title">Tinta pareti</p> 
                            <p class="nap-mi-tile-description">Gres porcellanato effetto legno rovere chiaro 20x120cm</p> 

                        </div>

                        <div class="nap-mi-tile flex flex-col justify-center items-center">
                            <img class="nap-mi-tile-image" src="@/assets/img/stoccolma3.jpg">
                            <p class="nap-mi-tile-title">Tinta pareti</p> 
                            <p class="nap-mi-tile-description">Gres porcellanato effetto legno rovere chiaro 20x120cm</p> 

                        </div>

                        <div class="nap-mi-tile flex flex-col justify-center items-center">
                            <img class="nap-mi-tile-image" src="@/assets/img/stoccolma3.jpg">
                            <p class="nap-mi-tile-title">Tinta pareti</p> 
                            <p class="nap-mi-tile-description">Gres porcellanato effetto legno rovere chiaro 20x120cm</p> 

                        </div> -->

                    </div>
                    
                    
                </div>
            </div>
            
            
        </div>


    </div>
</template>

<script>
import Close from "vue-material-design-icons/Close.vue";

export default{
    props: {
        displayStyleMoreInfo: {
            type: Boolean,
            required: true
        },
        selectedDesign: {
            type: String,
            required: true
        },
        styleMoreInfo: {
            type: Object,
            required: true
        },
        // showLayoutPop: {
        //     type: Boolean,
        //     required: true
        // },
        // buildingPlanImages: {
        //     type: Object,
        //     required: true
        // }

    },
    created(){
        // console.log(this.buildingPlanImages);
    },
    emits: ["toggleStyleMoreInfo"],
    methods: {
        toggleStyleMoreInfo() {
            this.$emit("toggleStyleMoreInfo", "");
        }
    },
    data() {
        return {
            
        }
    },
    components: {
        Close
    }
}

</script>