<template>
  <!-- <section> -->



  <!-- new code starts here -->
  <!-- <div class=""> -->
  <!-- <section style="width:100%;">
      <div class="flex flex-wrap justify-center text-center">
          <div class="
            w-full
            lg:mt-10 lg:rounded-2xl
            bg-white
            shadow-2xl
            pt-10
            pb-16
            npy-20
          ">
            
            <valuation-only v-bind:requestType="requestType" @confirmAgencyInterest="confirmAgencyInterest" />

          </div>
      </div>
    </section> -->
  <div class="lg:flex flex:grow justify-evenly text-left w-screen">

    <div class="flex npy-40 confirm-add-map-left" :style="{
      'padding-bottom': isMobile == true ? '280px' : '100px'
    }">
      <div class="w-full lg:px-10 px-5 pt-5 py-6 pb-0 lg:mb-0 mb-4">
        <h2 class="text-center lg:text-left w-full f25 font-bold lg:font-extrabold text-black">
          Conferma indirizzo
        </h2>

        <div class="w-full ">
          <div class="grid grid-cols-3 gap-2 mb-2 md:-mb-0">
            <div class="col-span-2">
              <label class="pt-2 hidden md:block place-items-center  na-label mb-1 text-555 f15-ls004 font-normal"
                htmlFor="grid-password">
                Indirizzo
              </label>
              <input type="text" class="w-full na-input  text-black f15-ls004 font-bold bg-red-600"
                placeholder="Indirizzo" ref="address" :value="modelValue.address"
                @input="editAddress('address', $event.target.value)" />
            </div>
            <div class="w-full">
              <label class="pt-2 hidden md:block na-label  mb-1 text-555 f15-ls004 font-normal" htmlFor="grid-password">
                Civico
              </label>
              <input type="number" class="w-full na-input na-input-full text-black f15-ls004 font-bold"
                placeholder="Civico" :value="modelValue.streetNumber"
                @input="editAddress('streetNumber', $event.target.value)" />
            </div>
          </div>
          <div class="grid grid-cols-3 gap-2 ">
            <div class="col-span-2">
              <label
                class="pt-2 hidden md:block place-items-center col-span-3 na-label mb-1 grid-cols-8 text-555 f15-ls004 font-normal"
                htmlFor="grid-password">
                Città
              </label>
              <input type="text" class="w-full na-input na-input-full text-black f15-ls004 font-bold" placeholder="Città"
                :value="modelValue.city" @input="editAddress('city', $event.target.value)" />
              <div
                v-if="this.modelValue.city !== 'Torino' && this.modelValue.city !== 'Milano' && this.modelValue.city !== 'Roma'">
              </div>
              <div
                v-if="this.modelValue.city !== 'Torino' && this.modelValue.city !== 'Milano' && this.modelValue.city !== 'Roma'">

                <h6 style="color: #606060" class=" text-center mx-auto mt-4 mb-12 font-bold">
                  Al momento il servizio è attivo su Torino, Milano e Roma.<br />Arriveremo presto
                  anche nella tua città!
                </h6>

              </div>
            </div>
            <div class="w-full">
              <label class="pt-2 hidden md:block place-items-center na-label mb-1 text-555 f15-ls004 font-normal"
                htmlFor="grid-password">
                CAP
              </label>
              <input type="number" class="na-input na-input-full text-black f15-ls004 font-bold" placeholder="CAP"
                :value="modelValue.postalCode" @input="editAddress('postalCode', $event.target.value)" />
            </div>
          </div>
        </div>
        <div class="my-2 shadow w-full h-64 block lg:hidden">
          <map-example v-model:mapId="id1" v-model="this.modelValue" />
        </div>
        <div
          v-if="(requestType == 'valuta' || requestType == '' || requestType == 'curiosity') || (requestType == 'vendi' && requestTypeSource == 'user')">

          <h2 class="text-center lg:text-left w-full f25 font-bold lg:font-extrabold text-black mt-4">
            Qual è il tuo obiettivo?
          </h2>

          <div class="grid grid-cols-2 lg:grid-cols-3 h-24 md:h-40 gap-8" role="group">
            <button v-for="objective in objectiveButton" type="button"
              :class="['items-center bg-white hover:shadow-inner transition hover:shadow-inner border-2 mt-2 md:mt-4 p-3 md:p-5 text-center br-10 na-valutazione', objective.is_active == true ? 'active' : '']"
              @click="setActiveObjective(objective)">
              <div>
                <img class="hover:hidden mx-auto" :src="objective.icon" alt="" />
              </div>
              <p class="md:block  f13-ls004 font-normal step-0-objective-sub-heading mt-1">
                {{ objective.sub_heading }}
              </p>
              <h4 class="block step-0-objective-heading">
                {{ objective.heading }}
              </h4>

            </button>


          </div>
        </div>

        <div class="lg:block hidden  w-auto py-3  lg:float-right  mt-0 md:mt-6 flex justify-center ">
          <button v-on:click="increaseStep()" v-bind:class="this.allComplete
            ? 'na-button flex flex-row font-bold f20-ls04 bg-green'
            : 'na-button-opaque flex flex-row font-bold f20-ls04'
            " type="button">
            Inizia &nbsp;&nbsp;
            <arrow-right fillColor="#FFFFFF" style="margin-top: 4px" />
          </button>
        </div>
      </div>
      <!--<div v-else style="text-align: center" class="w-full lg:px-10 px-5 pt-5 py-6 pb-0 lg:mb-0 mb-4 ">
        <img class="mx-auto" style="padding-top: 89px" src="@/assets/img/errore.png" alt="" />
        <h2 class="f30-ls07 font-bold text-black" style="padding-top: 30px">
          Ops!
        </h2>
        <h6 style="color: #606060" class="lg:w-5/12 text-center mx-auto mt-4 mb-12 font-bold">
          Al momento il servizio è attivo solo su Torino.<br />Arriveremo presto
          anche nella tua città!
        </h6>
        <div>
          <button v-on:click="goBack()" v-bind:class="'na-button  font-bold f20-ls04'" type="button">
            Torna alla home
          </button>
        </div>
      </div>-->
    </div>

    <div v-if="this.modelValue.city === 'Torino' || this.modelValue.city === 'Milano' || this.modelValue.city === 'Roma'"
      class="lg:hidden block bg-white w-auto py-3  lg:float-none  mt-0 md:mt-3 grid justify-items-center lg:relative fixed bottom-0 w-full na-progress-bar ">
      <button v-on:click="increaseStep()" v-bind:class="this.allComplete
        ? 'na-button flex flex-row font-bold f20-ls04 bg-green'
        : 'na-button-opaque flex flex-row font-bold f20-ls04'
        " type="button">
        Inizia &nbsp;&nbsp;
        <arrow-right fillColor="#FFFFFF" style="margin-top: 4px" />
      </button>
    </div>
    <div class="shadow w-full  lg:w-6/12  lg:block hidden">
      <map-example v-model:mapId="id2" v-model="this.modelValue" />
    </div>
  </div>
</template>
<script>
import MapExample from "@/components/Maps/MapExample.vue";
import ArrowRight from "vue-material-design-icons/ArrowRight.vue";

import ValuationOnly from "@/components/ConfiguratorSteps/valuation-box/ValuationOnly.vue";
import HouseOk from "@/components/ConfiguratorSteps/valuation-box/HouseOk.vue";
import HouseNotOk from "@/components/ConfiguratorSteps/valuation-box/HouseNotOk.vue";

export default {
  props: {
    modelValue: Object,
    requestType: String,
    requestTypeSource: String,
    originalAddressObject: Object, //serve solo per fare confronto ed eventualmente rifare geocoding
  },
  components: {
    MapExample,
    ArrowRight,
    ValuationOnly,
    HouseOk,
    HouseNotOk
  },
  emits: ["increaseStep", "decreaseStep", "editAddress", "setRequestType"],
  data() {
    return {
      tooltipShow: false,
      id1: "map-canvas-1",
      id2: "map-canvas-2",
      isObjectActive: false,
      isMobile: false,
      objectiveButton: [
        {
          'icon': require('@/assets/img/icons/icon-vendere.png'),
          'heading': 'vendere',
          'sub_heading': 'Valutare una casa da',
          'action': 'vendi',
          'is_active': false,
        },
        {
          'icon': require('@/assets/img/icons/icon-comprare.png'),
          'heading': 'comprare',
          'sub_heading': 'Valutare una casa da',
          'action': 'valuta',
          'is_active': false,
        },
        {
          'icon': require('@/assets/img/icons/icon-curiosita.png'),
          'heading': 'curiosità',
          'sub_heading': 'Valutare per',
          'action': 'curiosity',
          'is_active': false,
        },
      ],
      allComplete:
        this.modelValue.city.length > 0 &&
        this.modelValue.address.length > 0 &&
        this.modelValue.postalCode &&
        this.modelValue.streetNumber && this.isObjectActive == true,
      originalAddress:
        this.originalAddressObject.address +
        " " +
        this.originalAddressObject.streetNumber +
        ", " +
        this.originalAddressObject.postalCode +
        ", " +
        this.originalAddressObject.city,
    };
  },
  created() {
    console.log(this.requestType);
    if (this.requestType != '') {
      this.isObjectActive = false;
      console.log('here');
    }
    this.updateCompletion();
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false
    }
  },
  mounted() {
    console.log(this.modelValue);



    this.modelValue.latitude = localStorage.getItem("latitude");
    this.modelValue.longitude = localStorage.getItem("longitude");
    // this.isMobile();

    // console.log(this.requestType);
  },
  methods: {
    // isMobile() {
    //   if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //     this.isMobile = true;
    //   } else {
    //     this.isMobile = false
    //   }
    // },
    confirmAgencyInterest() { },
    setActiveObjective(objective) {




      this.objectiveButton.map((e) => {
        if (e.heading == objective.heading) {
          // this.requestType = objective.action;
          this.isObjectActive = true;
          this.$emit("setRequestType", objective.action);
          // this.requestType = objective.action;
          return e.is_active = true;
        } else {
          return e.is_active = false;
        }
      });

      console.log('action', this.modelValue);
      this.updateCompletion();


    },
    increaseStep() {
      console.log(this.allComplete)
      if (this.allComplete) {
        this.geocodingIfEdit();
        this.$emit("increaseStep");
      }
    },
    editAddress(key, event) {
      this.$emit("editAddress", key, event);
      this.updateCompletion();
    },

    updateCompletion() {
      console.log(this.requestType);
      this.allComplete =
        this.modelValue.city.length > 0 &&
        this.modelValue.address.length > 0 &&
        this.modelValue.postalCode &&
        this.modelValue.streetNumber;
      if ((this.requestType != null || this.requestType == 'valuta') && this.isObjectActive == false) {
        this.allComplete = false;
      }
      if (this.modelValue.city !== 'Torino' && this.modelValue.city !== 'Milano' && this.modelValue.city !== 'Roma') {
        console.log('cityu' + this.modelValue.city)
        this.allComplete = false;

      }
    },
    goBack() {
      this.$router.push({
        path: "/",
      });
    },
    geocodingIfEdit() {
      //this.googleAddress = //addressAutocomplete.getPlace();
      const houseAddress =
        this.modelValue.address +
        " " +
        this.modelValue.streetNumber +
        ", " +
        this.modelValue.postalCode +
        ", " +
        this.modelValue.city;
      console.log(houseAddress);
      if (this.originalAddress == houseAddress) {
        console.log("no geocoding");
        return;
      }
      console.log("geocoding");

      //localStorage.removeItem("latitude");
      //localStorage.removeItem("longitude");
      var self = this;
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: houseAddress }, function (results, status) {
        if (status == "OK") {
          console.log(results[0].geometry.location.lat());
          console.log(results[0].geometry.location.lng());
          localStorage.setItem("latitude", results[0].geometry.location.lat());
          self.modelValue.latitude = localStorage.getItem("latitude");
          localStorage.setItem("longitude", results[0].geometry.location.lng());
          self.modelValue.longitude = localStorage.getItem("longitude");
        } else {
          alert(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
      //this.modelValue.latitude = localStorage.getItem("latitude");
      //this.modelValue.longitude = localStorage.getItem("longitude");
    },
  },
};
</script>
