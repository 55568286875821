<template>
  <div ref="configurePage" class="flex flex-col" style="height: 100vh">
    <navbar ref="navbarDark" v-model="addressObject" />
    
    <main ref="naMainContent" v-bind:class="this.step == 0 || this.step == 8
      ? 'bg-beige flex-grow flex'
      : 'bg-beige  flex-grow flex na-main'
      ">
      <!-- Step 1 -->
      <section v-if="this.step == 0" class="bg-beige flex-grow flex">
        <confirm-address-step v-model:requestType="requestType" v-model:valutaType="valutaType"
          v-model:requestTypeSource="requestTypeSource" v-model="addressObject"
          v-model:originalAddressObject="addressObject" @setRequestType="setRequestType" @editAddress="editAddress"
          @increaseStep="increaseStep" @decreaseStep="decreaseStep" />
      </section>

      <!-- Step 2 -->
      <section v-if="this.step == 1" class="bg-beige flex-grow container mx-auto">
        <typology-step v-model:requestType="requestType" v-model:valutaType="valutaType" v-model="typology"
          @editTypology="editTypology" />
      </section>

      <!-- Step 3 -->
      <section v-if="this.step == 2" class="bg-beige flex-grow container mx-auto">
        <house-status-step v-model:requestType="requestType" v-model:valutaType="valutaType" v-model="status"
          @editStatus="editStatus" />
      </section>

      <!-- Step 4 -->
      <section v-if="this.step == 3" class="bg-beige flex-grow container mx-auto">
        <image-loader-step v-model:requestType="requestType" v-model:valutaType="valutaType" v-model="houseImages"
          @editHouseImages="editHouseImages" @increaseStep="increaseStep" />
      </section>

      <!-- Step 5 -->
      <section v-if="this.step == 4" class="bg-beige flex-grow container mx-auto">
        <planimetry-details-step v-model:requestType="requestType" v-model:valutaType="valutaType" v-model="planimetry"
          @editPlanimetry="editPlanimetry" />
      </section>

      <section v-if="this.step == 5" class="bg-beige flex-grow container mx-auto">
        <planimetry-details-step-part-2 v-model:requestType="requestType" v-model:valutaType="valutaType"
          v-model="planimetry" v-model:typology="typology" @editPlanimetry="editPlanimetry" />
      </section>

      <section v-if="this.step == 6" class="bg-beige flex-grow container mx-auto">
        <planimetry-details-step-part-3 v-model:requestType="requestType" v-model:valutaType="valutaType"
          v-model="planimetry" v-model:typology="typology" @editPlanimetry="editPlanimetry" />
      </section>

      <!-- Step 6 -->
      <section v-if="this.step == 7" class="bg-beige flex-grow container mx-auto">
        <additional-info-step v-model:requestType="requestType" v-model:addressObject="addressObject" v-model="additionalInfo" v-model:planimetry="this.planimetry"
          @editAdditionalInfo="editAdditionalInfo" />
      </section>

      <section v-if="this.step == 8" class="bg-beige flex-grow container mx-auto">
        <loading-screen v-if="!this.valuationResult.callPerformed" />
        <valuation-step v-else v-model:requestType="requestType" v-model:valutaType="valutaType"
          v-model:currentImmobile="this.currentImmobile" v-model:valuationResult="this.valuationResult.result"
          v-model:planimetry="planimetry" v-model:typology="typology" v-model:additionalInfo="this.additionalInfo"
          v-model:status="status" v-model:newarcType="newarcType" />
      </section>


      <div v-if="additionalInfo.showAgreePopup == true"
        class="fixed top-0 left-0 z-50 w-full h-full bg-white na-overlay">

        <div class="relative mx-auto lg:w-10/12 w-full h-full flex flex-row justify-center items-center"
          style="height:100vh;">

          <div class="br-10 bg-green text-center na-get-agency-value-wrapper">

            <section class="w-full mx-auto bg-green px-1 py-8 lg:p-6 lg:px-12 br-10 relative">

              <div class="absolute z-50 top-2 right-2 cursor-pointer" @click="setWantAgencyValuation(false)"
                style="height:30px;width:30px;">
                <close fillColor="#FFFFFF" />
              </div>

              <h4 class="text-2xl text-white font-bold">Vorresti avere una valutazione <br />ancora più precisa?</h4>

              <div class="mt-6 bg-white lg:p-6 p-4 br-10">

                <p class="f18-ls004 w-full lg:w-10/12 mx-auto mb-4">Newarc collabora con le migliori agenzie del tuo
                  territorio per offrirti la valutazione più accurata disponibile ad oggi sul mercato. L'agenzia
                  selezionata fisserà con te un sopralluogo gratuito senza obbligo di vendita e ti dirà subito il reale
                  valore del tuo immobile.</p>

                <div class="grid lg:grid-cols-4 grid-cols-2 lg:gap-10 lg:gap-y-16 gap-y-4 gap-x-4">

                  <div class="vendilo-icon">
                    <img class="mx-auto" src="@\assets\img\icons\scontistiche.svg" alt="" />
                    <p class="vendilo-icon-title f18-ls004 text-black mt-1 font-bold">Scontistiche</p>
                    <p class="vendilo-icon-desc f14-ls03 text-5b">Ottieni sconti fino al 50% <br />sulla provvigione
                    </p>
                  </div>

                  <div class="vendilo-icon">
                    <img class="mx-auto" src="@\assets\img\icons\esperienza.svg" alt="" />
                    <p class="vendilo-icon-title f18-ls004 text-black mt-1 font-bold">Esperienza</p>
                    <p class="vendilo-icon-desc f14-ls03 text-5b">Profonda conoscenza <br />del territorio</p>
                  </div>

                  <div class="vendilo-icon">
                    <img class="mx-auto" src="@\assets\img\icons\valorizzazione.svg" alt="" />
                    <p class="vendilo-icon-title f18-ls004 text-black mt-1 font-bold">Valorizzazione</p>
                    <p class="vendilo-icon-desc f14-ls03 text-5b">Massima valorizzazione <br />del tuo immobile</p>
                  </div>

                  <div class="vendilo-icon">
                    <img class="mx-auto" src="@\assets\img\icons\selezione.svg" alt="" />
                    <p class="vendilo-icon-title f18-ls004 text-black mt-1 font-bold">Selezione</p>
                    <p class="vendilo-icon-desc f14-ls03 text-5b">Selezioniamo le migliori <br />agenzie della zona
                    </p>
                  </div>
                </div>

                <div class="flex flex-col justify-between">
                  <div class="flex justify-center mt-6">
                    <button
                      class="rounded-full px-5 lg:px-12 p-4 bg-green text-white flex items-center mx-auto font-bold "
                      @click="setWantAgencyValuation(true)" type="button">
                      <span class="font-bold f10-ls04 md:f20-ls04">Ok, sono d'accordo!</span>

                    </button>
                  </div>
                  <div class="flex justify-center mt-6">
                    <button
                      class="rounded-full px-5 lg:px-12 p-4 bg-beige text-black flex items-center mx-auto font-bold "
                      @click="setWantAgencyValuation(false)" type="button">
                      <span class="font-bold f10-ls04 md:f20-ls04">No grazie</span>

                    </button>
                  </div>
                </div>



              </div>
              <img class="mx-auto mt-4" src="@/assets/img/newarc_logo.png">

            </section>
          </div>

        </div>

      </div>

      <!-- otp -->
      <div v-if="otp.showOTPPopup == true" class="fixed top-0 left-0 z-50 w-full h-full bg-white na-overlay">

        <div class="relative mx-auto lg:w-10/12 w-full h-full flex flex-row justify-center items-center"
          style="height:100vh;">

          <div class="br-10 bg-green text-center na-get-agency-value-wrapper">

            <section class="otp-box w-full mx-auto px-1 py-8 lg:p-6 lg:px-12 br-10 relative">

              <div class="absolute z-50 top-2 right-2 cursor-pointer" @click="setOtpPopupStatus(false)"
                style="height:30px;width:30px;">
                <close fillColor="#000000" />
              </div>

              <p class="otp-header">
              Inserisci il codice OTP inviato a {{ additionalInfo.submitterPhone }}. <a class="otp-link" @click="setOtpPopupStatus(false)" href="javascript:void(0)">Cambia numero</a>
              </p>

              <div class="mt-2 lg:p-6 p-4 br-10">
                
                <div class="form-group">
                  <input v-for="index in 6" type="text" v-model="otp.digits[index]" maxlength="1" class="na-input w-10 mr-2 text-center " :data-abc="index"  @input="moveFocus(index, $event)" :ref="`digit${index}`" @keydown.backspace="handleBackspace(index, $event)" @keydown.delete="handleBackspace(index, $event)">
                </div> 
                
                <div class="flex flex-row mt-2">
                  <p v-if="otp.timer > 0" class="otp-messages">Rinvia OTP in {{ otp.timer }} secondi</p>
                  <!-- <p class="otp-messages">{{ otp.otpMessage }}</p> -->
                </div>
                <div class="flex flex-col justify-between">
                  <div class="flex justify-center mt-6">

                    <button v-if="otp.timer === 0" class="rounded-full px-5 lg:px-12 p-4 bg-green text-white flex items-center mx-auto font-bold mr-2" @click="resendOTP">
                      Rinvia OTP
                    </button>
                    
                    <button class="rounded-full px-5 lg:px-12 p-4 bg-green text-white flex items-center mx-auto font-bold " :disabled="otp.digits.join('').length < 6" @click="submitOTP" type="button">
                      <span class="font-bold f10-ls04 md:f20-ls04">Verifica</span>
                    </button>

                    
                  </div>
                  <p class="otp-messages mt-2" style="font-size: 12px;">{{ otp.otpMessage }}.</p>
                  
                </div>



              </div>
              <!-- <img class="mx-auto mt-4" src="@/assets/img/newarc_logo.png"> -->

            </section>
          </div>

        </div>

      </div>


    </main>

    <stepper v-if="step < 8 && step > 0" v-model="step" v-model:nextStepButtonVisible="nextStepButtonVisible"
      @increaseStep="increaseStep" @decreaseStep="decreaseStep" />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbarDark.vue";
import Stepper from "@/components/ConfiguratorSteps/Stepper.vue";
import TypologyStep from "@/components/ConfiguratorSteps/TypologyStep.vue";
import HouseStatusStep from "@/components/ConfiguratorSteps/HouseStatusStep.vue";
import ImageLoaderStep from "@/components/ConfiguratorSteps/ImageLoaderStep.vue";
import ConfirmAddressStep from "@/components/ConfiguratorSteps/ConfirmAddressStep.vue";
import PlanimetryDetailsStep from "@/components/ConfiguratorSteps/PlanimetryDetailsStep.vue";
import AdditionalInfoStep from "@/components/ConfiguratorSteps/AdditionalInfoStep.vue";

import background from "@/assets/img/turin-back.png";
import ValuationStep from "@/components/ConfiguratorSteps/ValuationStep.vue";
import LoadingScreen from "@/components/ConfiguratorSteps/LoadingScreen.vue";

import axios from "axios";
import PlanimetryDetailsStepPart2 from "../components/ConfiguratorSteps/PlanimetryDetailsStepPart2.vue";
import PlanimetryDetailsStepPart3 from "../components/ConfiguratorSteps/PlanimetryDetailsStepPart3.vue";

import Close from "vue-material-design-icons/Close.vue";

export default {
  created() {
    this.addressObject.address = this.$route.query.address;
    this.addressObject.streetNumber = this.$route.query.streetNumber;
    this.addressObject.city = this.$route.query.city;
    this.addressObject.postalCode = this.$route.query.postalCode;
    this.addressObject.latitude = this.$route.query.lat;
    this.addressObject.longitude = this.$route.query.lng;

    // Check the request type Vendi/Valuta
    // this.requestType = 'valuta';
    if (typeof this.$route.query.tipo != 'undefined') {
      this.requestType = this.$route.query.tipo;
      this.additionalInfo.requestType = this.requestType
      this.requestTypeSource = 'url';
    }

    localStorage.setItem("latitude", this.$route.query.lat);
    localStorage.setItem("longitude", this.$route.query.lng);
  },
  mounted() { },
  data() {
    return {
      requestType: '',
      valutaType: null,
      requestTypeSource: 'user',
      background,
      mainContentHeight: "",
      addressObject: {
        address: null,
        streetNumber: null,
        city: null,
        postalCode: null,
        latitude: 0,
        longitude: 0,
      },
      houseImages: {
        images: [],
        imageFiles: [],
        planimetryName: null,
        planimetryFile: null,
      },
      typology: null,
      status: null,
      planimetry: {
        surface: null,
        locali: null,
        bathrooms: null,
        floor: null,
        numFloors: null,
        hasElevator: null,
        hasBalcony: null,
        numBalcony: 1,
        hasTerrace: null,
        terraceMq: null,
        hasSharedGarden: null,
        hasPrivateGarden: null,
        gardenMq: null,
        hasGarage: null,
        garageCount: 1,
        hasCantina: null,
        hasCloset: null,
        numCantina: 1,
        hasConcierge: null,
        yearBuilt: null,
        unknownBuiltYear: false,
        energyClass: null,
        unknownEnergyClass: false,
        knowExposition: null,
        exposition: [],
        heating: null, //"Autonomo",
        termovalvole: null,
        unknownTermovalvole: null,
      },
      additionalInfo: {
        submitterName: null,
        submitterSurname: null,
        submitterEmail: null,
        submitterPhone: null,
        privacyCheckbox: false,
        sellerType: null,
        sellerProfession: null,
        isPropertyOfSubmitter: null,
        desiredSellTime: null,
        currentAvailability: null,
        userAgeRange: null,
        newsletter: false,
        whyAskEvaluation: null,
        whatKindOfProperty: null,
        submitterIsLookingForHouse: null,
        wantsAgencyValuation: false,
        showAgreePopup: false,
        newarcType: null,
        requestType: null, //deprecated
        valutaType: null, //deprecated
        vendiType: null, //deprecated
        userFocusAreas: null,
      },
      setAdditionalInfoFirstFlag: 0,
      step: 0,
      valuationResult: {
        callPerformed: false,
        callStatus: null,
        result: null,
      },
      nextStepButtonVisible: false,
      currentImmobile: null,
      
      otp: {
        digits: [
          '','','','','','',''
        ],
        countryCode: "+39",
        timer: 0,
        showOTPPopup: false,
        isOTPValidated: false,
        otpSubmitted: false,
        countdownInterval: null,
        otpMessage: ''

      },
      
      
    };
  },
  computed: {
    isOTPComplete() {
      // Check if all OTP digits are filled
      return this.otp.digits.every(digit => digit !== '');
    }
  },  
  components: {
    Close,
    Navbar,
    TypologyStep,
    Stepper,
    HouseStatusStep,
    ImageLoaderStep,
    ConfirmAddressStep,
    PlanimetryDetailsStep,
    AdditionalInfoStep,
    ValuationStep,
    LoadingScreen,
    PlanimetryDetailsStepPart2,
    PlanimetryDetailsStepPart3,
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  methods: {
    sendOtp(){
      this.startTimer();
      this.otp.otpSubmitted = false;
      this.otp.digits = ['','','','','','',''];
      
      var phoneNumber = this.additionalInfo.submitterPhone;

      if( phoneNumber.indexOf(this.otp.countryCode) == -1 ) {
        phoneNumber = this.otp.countryCode + this.additionalInfo.submitterPhone.trim();
      }

      this.otp.otpMessage = 'Invio in corso...';
      
      axios
        .post(
          process.env.VUE_APP_twilo_endpoint_start_verify,
          {
            to: phoneNumber,
            locale: 'it'
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if( response.data.success == true ) {
            this.otp.otpMessage = 'Codice OTP inviato!';
          } else {
            this.otp.otpMessage = 'Errore nell\'invio del codice OTP!';
          }

          // console.log('Send OTP',response);
          

        });
    },
    submitOTP() {
      // Example function to handle OTP submission
      if (this.otp.digits.join('').length == 6 ) {
        
        var otpCode = this.otp.digits.join('');
        var phoneNumber = this.additionalInfo.submitterPhone;

        if( phoneNumber.indexOf(this.otp.countryCode) == -1 ) {
          phoneNumber = this.otp.countryCode + this.additionalInfo.submitterPhone.trim();
        }

        this.otp.otpMessage = 'Verifica in corso...';

        axios
        .post(
          process.env.VUE_APP_twilo_endpoint_check,
          {
            to: phoneNumber,
            code: otpCode
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if( response.data.success == true ) {
            
            this.otp.otpMessage = 'Verified';
            
            this.setOtpPopupStatus(false);

            if ((this.additionalInfo.wantsAgencyValuation == false && this.requestType == 'vendi' && this.additionalInfo.wantsToSell) && (this.status == 'Ottimo/Ristrutturato' || this.status == 'Nuovo / In costruzione'  || !this.planimetry.hasElevator || this.planimetry.floor == 'Terra' ||this.planimetry.floor == 'Seminterrato' || this.planimetry.floor == 'Rialzato' ||  parseInt(this.addressObject.postalCode) <= 10120 || parseInt(this.addressObject.postalCode) >= 10147)) {
      
          
              this.editAdditionalInfo('showAgreePopup', true);
              this.additionalInfo.showAgreePopup = true;
              

              
            } else {
              this.performApiCall();
              this.step += 1;
            }


          } else {
            this.otp.otpMessage = 'Errore nella verifica del codice. '+ response.data.message;
          }
          // console.log('Submit OTP',response);

        });

      }
    },
    resendOTP() {
      this.sendOtp();
      
    },
    startTimer() {
      // Start a 15-second countdown timer
      this.otp.timer = 15;
      this.otp.countdownInterval = setInterval(() => {
        if (this.otp.timer > 0) {
          this.otp.timer--;
        } else {
          clearInterval(this.otp.countdownInterval);
        }
      }, 1000);
    },
    setOtpPopupStatus(status) {
      clearInterval(this.otp.countdownInterval);
      this.otp.showOTPPopup = status;
    },
    moveFocus(position, event) {
      
      // Move focus to the next input field after entering a digit
      if ( event.inputType != 'deleteContentBackward' && position >= 1 && position < 6) {
        this.$refs['digit' + (position+1)][0].focus();
      }
    },
    handleBackspace(index, event) {
      if (index > 0 && !this.otp.digits[index] && typeof this.$refs['digit' + (index-1)] !== 'undefined') {
        this.$refs['digit' + (index-1)][0].focus();
        // otpInputs.value[index - 1].focus();
      }
    },
    setRequestType(requestType) {
      // console.log(requestType);
      this.requestType = requestType;
      this.additionalInfo.requestType = this.requestType;
      if (requestType != 'vendi') {
        this.valutaType = requestType;
        this.additionalInfo.valutaType = this.valutaType;

      }
      this.requestTypeSource = 'user';
    },
    setWantAgencyValuation(value) {
      this.additionalInfo.wantsAgencyValuation = value;
      this.additionalInfo.showAgreePopup = false;
      this.performApiCall();
      this.step += 1;
      // this.$emit("performApiCall");

    },
    closeAgreementConfirationPopup() {
      this.additionalInfo.showAgreePopup = false;
    },
    isCustomMobile() {
      return false;
    },
    addToRoute() {
      // console.log(this.step);
      if (this.step == 0) {
        this.$router.push({
          name: 'step-0',
          query: this.$route.query
        });
      } else if (this.step == 1) {
        this.$router.push({
          name: 'step-1',
          query: this.$route.query
        });
      } else if (this.step == 2) {
        this.$router.push({
          name: 'step-2',
          query: this.$route.query
        });
      } else if (this.step == 3) {
        this.$router.push({
          name: 'step-3',
          query: this.$route.query
        });
      } else if (this.step == 4) {
        this.$router.push({
          name: 'step-4',
          query: this.$route.query
        });
      } else if (this.step == 5) {
        this.$router.push({
          name: 'step-5',
          query: this.$route.query
        });
      } else if (this.step == 6) {
        this.$router.push({
          name: 'step-6',
          query: this.$route.query
        });
      } else if (this.step == 7) {
        this.$router.push({
          name: 'step-7',
          query: this.$route.query
        });
      } else if (this.step == 8) {
        this.$router.push({
          name: 'valuation',
          query: this.$route.query
        });
      }

      //console.log(this.$route.query);
    },
    increaseStep() {

      // console.log(this.addressObject);

      if (this.step < 7) {
        if (this.step == 2) {
          this.step += 2;
        } else {
          this.step += 1;

        }
        this.scrollToTop();
      } else if (this.step == 7) {


        //v-if="requestType == 'vendi' && (status == 'Ottimo/Ristrutturato' || status == 'Nuovo / In costruzione') && (!this.additionalInfo.wantsToSell || !this.currentImmobile.elevator || this.currentImmobile.unitFloor == 'Terra' || this.currentImmobile.unitFloor !== 'Seminterrato' || this.currentImmobile.unitFloor !== 'Rialzato') ||  this.currentImmobile.zipCode <= 10120 && this.currentImmobile.zipCode >= 10147">


        if( this.otp.isOTPValidated == false ) {
          this.sendOtp();
          // this.startTimer();
          this.otp.showOTPPopup = true;
          return;
        }

        // Only show popup when it's only good for agencies
        /*if ((this.additionalInfo.wantsAgencyValuation == false && this.requestType == 'vendi' && this.additionalInfo.wantsToSell) && (this.status == 'Ottimo/Ristrutturato' || this.status == 'Nuovo / In costruzione'  || !this.planimetry.hasElevator || this.planimetry.floor == 'Terra' ||this.planimetry.floor == 'Seminterrato' || this.planimetry.floor == 'Rialzato' ||  parseInt(this.addressObject.postalCode) <= 10120 || parseInt(this.addressObject.postalCode) >= 10147)) {
       
          
          this.editAdditionalInfo('showAgreePopup', true);
          this.additionalInfo.showAgreePopup = true;
          

          
        } else {
          this.performApiCall();
          this.step += 1;
        }*/

      }
      this.addToRoute();

      this.showNextStepButton();
    },
    decreaseStep() {
      if (this.step > 0) {
        if (this.step == 4) {
          this.step -= 2;
        } else {
          this.step -= 1;

        }
        // this.addToRoute();
        this.$router.back();

        this.scrollToTop();
      }
      this.showNextStepButton();
    },
    showNextStepButton() {
      switch (this.step) {
        case 1: // typology step
          if (this.typology != null) {
            this.nextStepButtonVisible = true;
          } else {
            this.nextStepButtonVisible = false;
          }
          break;
        case 2: //status step
          if (this.status != null) {
            this.nextStepButtonVisible = true;
          } else {
            this.nextStepButtonVisible = false;
          }
          break;
        case 3: //photo step
          this.nextStepButtonVisible = true;
          //facoltativo
          break;
        case 4: //caratteristiche 1
          if (
            this.planimetry.surface != null &&
            this.planimetry.locali != null &&
            this.planimetry.bathrooms != null &&
            this.planimetry.floor != null &&
            this.planimetry.numFloors != null
          ) {
            this.nextStepButtonVisible = true;
          } else {
            this.nextStepButtonVisible = false;
          }
          break;
        case 5: //caratteristiche 2
          if (
            this.planimetry.hasBalcony != null &&
            this.planimetry.hasElevator != null &&
            this.planimetry.hasTerrace != null &&
            ((this.planimetry.hasPrivateGarden != null && this.planimetry.gardenMq != null) || this.planimetry.hasSharedGarden != null) &&
            this.planimetry.hasCantina != null &&
            this.planimetry.hasConcierge != null
          ) {
            this.nextStepButtonVisible = true;
          } else {
            this.nextStepButtonVisible = false;
          }
          break;
        case 6: //caratteristiche 3
          if (
            this.planimetry.heating != null &&
            (this.planimetry.termovalvole != null ||
              this.planimetry.unknownTermovalvole != null || (this.planimetry.heating == 'Assente' || this.planimetry.heating == 'Non so')) &&
            (this.planimetry.yearBuilt != null ||
              this.planimetry.unknownBuiltYear == true) &&
            (this.planimetry.energyClass != null ||
              this.planimetry.unknownEnergyClass == true) &&
            this.planimetry.knowExposition != null &&
            (this.planimetry.knowExposition == false ||
              (this.planimetry.knowExposition == true &&
                this.planimetry.exposition.length > 0))
          ) {
            this.nextStepButtonVisible = true;
          } else {
            this.nextStepButtonVisible = false;
          }

          /* If user go back to Step 6 from 7 or any other case 
          The data should be reverted to null so that the data captured always remain consistance */
          this.additionalInfo.privacyCheckbox = false;
          this.additionalInfo.userFocusAreas = null;

          this.additionalInfo.sellerType = null;
          this.additionalInfo.whatKindOfProperty = null;
          this.additionalInfo.houseType = null;
          this.additionalInfo.submitterIsLookingForHouse = null;
          this.additionalInfo.buyBudget = null;
          this.additionalInfo.rooms = null;
          this.additionalInfo.sellerProfession = null;
          this.additionalInfo.whyAskEvaluation = null;
          this.additionalInfo.isPropertyOfSubmitter = null;
          this.additionalInfo.wantsToSell = null;
          this.additionalInfo.desiredSellTime = null;
          this.additionalInfo.currentAvailability = null;
          this.additionalInfo.userAgeRange = null;


          break;
        case 7: //Informazioni aggiuntive

          this.nextStepButtonVisible = false;

          if (this.isNullOrEmpty(this.additionalInfo.submitterName)
            || this.isNullOrEmpty(this.additionalInfo.submitterSurname)
            || !this.containsEmail(this.additionalInfo.submitterEmail)
            || !this.containsItalianPhoneNumber(this.additionalInfo.submitterPhone)
            || this.additionalInfo.privacyCheckbox == false) {

            this.nextStepButtonVisible = false;
            break;

          } else {
            this.nextStepButtonVisible = true;

          }
          break

          if (this.requestType == 'vendi') {

            if (this.additionalInfo.sellerType == 'privato') {


              /* if sellerType privato */
              this.additionalInfo.sellerProfession = null;
              this.additionalInfo.whyAskEvaluation = null;

              if (
                this.additionalInfo.isPropertyOfSubmitter != null
                && this.additionalInfo.wantsToSell != null
                && this.additionalInfo.userAgeRange != null) {

                if (this.additionalInfo.wantsToSell == true
                  && this.additionalInfo.whatKindOfProperty != null
                  && this.additionalInfo.desiredSellTime != null
                  && this.additionalInfo.currentAvailability != null) {

                  this.nextStepButtonVisible = true;
                  break;

                } else if (this.additionalInfo.wantsToSell == false) {

                  /* if wantsToSell is false then revert the values to null */
                  this.additionalInfo.whatKindOfProperty = null;
                  this.additionalInfo.desiredSellTime = null;
                  this.additionalInfo.currentAvailability = null;

                  this.nextStepButtonVisible = true;
                  break;

                }
              }
            } else if (this.additionalInfo.sellerType == 'professionista'
              && this.additionalInfo.sellerProfession != null
              && this.additionalInfo.whyAskEvaluation != null) {

              /* if sellerType professionista */
              this.additionalInfo.isPropertyOfSubmitter = null;
              this.additionalInfo.wantsToSell = null;
              this.additionalInfo.whatKindOfProperty = null;
              this.additionalInfo.desiredSellTime = null;
              this.additionalInfo.currentAvailability = null;


              this.nextStepButtonVisible = true;
              break;

            }
            console.log("CCOMBO BREAKER")
            this.nextStepButtonVisible = false;
            break;

          } else if (this.requestType == 'valuta') {

            if (this.additionalInfo.sellerType == 'privato') {

              /* if sellerType privato then revert values to null */
              this.additionalInfo.sellerProfession = null;
              this.additionalInfo.whyAskEvaluation = null;

              if (this.additionalInfo.whatKindOfProperty != null
                && this.additionalInfo.houseType != null
                && this.additionalInfo.houseCondition != null
                && this.additionalInfo.buyBudget != null
                && this.additionalInfo.userAgeRange != null
                && this.additionalInfo.rooms != null
                && this.additionalInfo.userFocusAreas != null
                && this.additionalInfo.userFocusAreas.length > 0) {

                this.nextStepButtonVisible = true;
                break;

              }

            } else if (this.additionalInfo.sellerType == 'professionista') {

              /* if sellerType professionista then revert values to null */
              this.additionalInfo.whatKindOfProperty = null;
              this.additionalInfo.houseType = null;
              this.additionalInfo.houseCondition = null;
              this.additionalInfo.buyBudget = null;
              this.additionalInfo.userAgeRange = null;
              this.additionalInfo.rooms = null;

              if (this.additionalInfo.sellerProfession != null
                && this.additionalInfo.whyAskEvaluation != null
                && this.additionalInfo.userFocusAreas != null
                && this.additionalInfo.userFocusAreas.length > 0) {

                this.nextStepButtonVisible = true;
                break;

              }
            }

            this.nextStepButtonVisible = false;
            break;

          } else if (this.requestType == 'curiosity') {

            if (this.additionalInfo.sellerType == 'privato') {

              /* if sellerType privato then revert values to null */
              this.additionalInfo.sellerProfession = null;
              this.additionalInfo.whyAskEvaluation = null;

              if (this.additionalInfo.isPropertyOfSubmitter != null) {

                this.nextStepButtonVisible = true;
                break;

              }

            } else if (this.additionalInfo.sellerType == 'professionista') {

              /* if sellerType professionista then revert values to null */
              this.additionalInfo.isPropertyOfSubmitter = null;

              if (this.additionalInfo.sellerProfession != null
                && this.additionalInfo.whyAskEvaluation != null
                && this.additionalInfo.userFocusAreas != null
                && this.additionalInfo.userFocusAreas.length > 0) {
                this.nextStepButtonVisible = true;
                break;
              }

            }

            this.nextStepButtonVisible = false;
            break;

          }

          this.nextStepButtonVisible = false;
          break;



          //this.nextStepButtonVisible = true;



          //break;
          //console.log(this.additionalInfo);
          /*if (
            this.additionalInfo.sellerType != null &&
            //this.additionalInfo.isPropertyOfSubmitter != null &&
            (this.additionalInfo.submitterName != null && this.additionalInfo.submitterName.length > 0) &&
            (this.additionalInfo.submitterEmail != null && this.additionalInfo.submitterEmail.length > 0) &&
            this.additionalInfo.submitterSurname != null && this.additionalInfo.submitterSurname.length > 0 &&
            (this.additionalInfo.submitterPhone != null && this.additionalInfo.submitterPhone.length > 5) &&
            this.additionalInfo.privacyCheckbox != false
          ) {
            this.nextStepButtonVisible = true;
            break

            if (this.requestType == 'valuta') {
              if (this.additionalInfo.sellerType == "privato") {
                this.nextStepButtonVisible = this.additionalInfo.whatKindOfProperty != null &&
                  this.additionalInfo.houseType != null &&
                  this.additionalInfo.submitterIsLookingForHouse != null &&
                  this.additionalInfo.buyBudget != null &&
                  this.additionalInfo.userAgeRange != null &&
                  this.additionalInfo.rooms != null &&
                  this.additionalInfo.userFocusAreas != null
              } else {
                this.nextStepButtonVisible = this.additionalInfo.whyAskEvaluation != null &&
                  this.additionalInfo.sellerProfession != null &&
                  this.additionalInfo.userFocusAreas != null
              }

            } else if (this.requestType == "vendi") {
              if (this.additionalInfo.sellerType == "privato") {
                if (this.additionalInfo.isPropertyOfSubmitter != null &&
                  this.additionalInfo.wantsToSell != null) {
                    this.nextStepButtonVisible = this.additionalInfo.whatKindOfProperty != null &&
                    this.additionalInfo.desiredSellTime != null &&
                    this.additionalInfo.currentAvailability != null &&
                    this.additionalInfo.userAgeRange != null
                }
              } else {
                this.nextStepButtonVisible= this.additionalInfo.sellerProfession != null && 
                this.additionalInfo.whyAskEvaluation != null
              }
            } else{
            if (this.additionalInfo.sellerType == "privato") {
                if(this.additionalInfo.wantsToSell != null){}
              } else {
                this.nextStepButtonVisible = this.additionalInfo.sellerProfession != null  &&               
                 this.additionalInfo.whyAskEvaluation != null

              }}*/

          /*
          /*if (
            (this.additionalInfo.sellerType == "privato" &&
              this.additionalInfo.isPropertyOfSubmitter == false &&
              this.additionalInfo.whyAskEvaluation != null &&
              this.additionalInfo.submitterIsLookingForHouse != null) ||
            (this.additionalInfo.sellerType == "privato" &&
              this.additionalInfo.isPropertyOfSubmitter == true &&
              this.additionalInfo.whatKindOfProperty != null &&
              this.additionalInfo.desiredSellTime != null) ||
            (this.additionalInfo.sellerType == "professionista" &&
              this.additionalInfo.sellerProfession != null &&
              this.additionalInfo.whyAskEvaluation != null)
          )*/
          /*
                      if (this.additionalInfo.sellerType == "privato") {
                        if (this.requestType == 'vendi' && this.additionalInfo.currentAvailability != null) {
          
                          if (
                            this.additionalInfo.isPropertyOfSubmitter == false
                            && this.additionalInfo.whyAskEvaluation != null
                            && this.additionalInfo.submitterIsLookingForHouse != null
                            && this.additionalInfo.userAgeRange != null
                          ) {
                            this.nextStepButtonVisible = true;
                          } else if (
                            this.additionalInfo.isPropertyOfSubmitter == true
                            && this.additionalInfo.whatKindOfProperty != null
                            && this.additionalInfo.desiredSellTime != null
                            && this.additionalInfo.userAgeRange != null
                          ) {
                            this.nextStepButtonVisible = true;
                          } else {
                            this.nextStepButtonVisible = false;
                          }
          
                        } else { //Valuta or compra
          
                          // console.log(this.additionalInfo.isPropertyOfSubmitter,
                          //  this.additionalInfo.userAgeRange);
          
                          if (this.requestType == 'curiosity') {
                            if (this.additionalInfo.isPropertyOfSubmitter != null && this.additionalInfo.userAgeRange != null
                            ) {
                              // console.log('here');
                              this.nextStepButtonVisible = true;
                            } else {
                              this.nextStepButtonVisible = false;
                            }
                          } else {
                            if (this.additionalInfo.userAgeRange != null
                            ) {
                              // console.log('here');
                              this.nextStepButtonVisible = true;
                            } else {
                              this.nextStepButtonVisible = false;
                            }
                          }
          
                        }
                      } else if (this.additionalInfo.sellerType == "professionista") {
          
                        if (this.requestType == 'vendi' && this.additionalInfo.currentAvailability != null) {
          
                          if (
                            this.additionalInfo.sellerProfession != null &&
                            this.additionalInfo.whyAskEvaluation != null
                          ) {
                            this.nextStepButtonVisible = true;
                          } else {
                            this.nextStepButtonVisible = false;
                          }
          
                        } else { //valuta
                          if (
                            this.additionalInfo.sellerProfession != null &&
                            this.additionalInfo.whyAskEvaluation != null
                          ) {
                            this.nextStepButtonVisible = true;
                          } else {
                            this.nextStepButtonVisible = false;
                          }
                        }
          
          
                      } else {
                        this.nextStepButtonVisible = false;
                      }
                      // if (
                      //   (this.additionalInfo.sellerType == "privato" &&
                      //     this.additionalInfo.isPropertyOfSubmitter == false &&
                      //     this.additionalInfo.whyAskEvaluation != null &&
                      //     this.additionalInfo.submitterIsLookingForHouse != null) ||
                      //   (this.additionalInfo.sellerType == "privato" &&
                      //     this.additionalInfo.isPropertyOfSubmitter == true &&
                      //     this.additionalInfo.whatKindOfProperty != null) ||
                      //   (this.additionalInfo.sellerType == "professionista" &&
                      //     this.additionalInfo.sellerProfession != null &&
                      //     this.additionalInfo.whyAskEvaluation != null)
                      // ) {
                      //   this.nextStepButtonVisible = true;
                      // } else {
                      //   this.nextStepButtonVisible = false;
                      // }
                      */
          // } else {
          //   this.nextStepButtonVisible = false;
          // }

          break;
      }
    },
    editAddress(key, value) {
      this.addressObject[key] = value;
      // console.log(key, value);
    },
    editTypology(typology) {
      this.typology = typology;
      this.showNextStepButton();
    },
    editStatus(status) {
      this.status = status;
      this.showNextStepButton();
    },
    editHouseImages(houseImages) {
      this.houseImages = houseImages;
    },
    editPlanimetry(key, value) {
      this.planimetry[key] = value;
      if (key == "termovalvole") {
        this.planimetry["unknownTermovalvole"] = false;
      }
      this.showNextStepButton();
    },
    editAdditionalInfo(key, value) {

      if (this.setAdditionalInfoFirstFlag == 0) {

        // Object.entries(this.additionalInfo).map(([arrkey, arrvalue]) => {

        //     if(key != arrkey ) {
        //       this.additionalInfo[arrkey] = arrvalue;
        //       console.log(arrkey, arrvalue);
        //     }

        // });
        this.setAdditionalInfoFirstFlag++;
      }

      this.additionalInfo[key] = value;
      this.showNextStepButton();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    performApiCall() {
      if (this.typology == "Attico") {
        this.typology == "Attico / Mansarda";
      }

      let newarcType = 'curiosity';
      let requestType = this.requestType;

      // these shall be deprecated
      let vendiType = '';
      let valutaType = this.valutaType;


      if (this.requestType == 'vendi' && (this.status == 'Ottimo/Ristrutturato' || this.status == 'Nuovo / In costruzione') && !(this.planimetry.hasElevator && (this.planimetry.floor !== 'Terra' || this.planimetry.floor !== 'Seminterrato' || this.planimetry.floor !== 'Rialzato') && (parseInt(this.addressObject.postalCode) > 10120 && parseInt(this.addressObject.postalCode) < 10147))) {

        newarcType = 'agency';

        requestType = 'vendi';
        vendiType = 'agenzie';


      } else if (this.requestType == 'vendi' && (this.status == 'Buono / Abitabile' || this.status == 'Da ristrutturare') && this.planimetry.hasElevator && (this.planimetry.floor !== 'Terra' || this.planimetry.floor !== 'Seminterrato' || this.planimetry.floor !== 'Rialzato') && (parseInt(this.addressObject.postalCode) > 10120 && parseInt(this.addressObject.postalCode) < 10147)) {

        newarcType = 'subito/insieme';

        requestType = 'vendi';
        vendiType = 'subito/insieme';


      } else if (this.requestType == 'curiosity') {

        newarcType = 'curiosity';

        requestType = 'valuta';
        valutaType = "curiosity";


      } else if (this.requestType == 'valuta') {

        newarcType = 'buy';

        requestType = 'valuta';
        valutaType = "compra";


      }

      this.additionalInfo.newarcType = newarcType

      //these shall be deprecated
      this.additionalInfo.valutaType = valutaType;
      this.additionalInfo.requestType = requestType;
      this.additionalInfo.vendiType = vendiType;

      this.currentImmobile = {
        airConditioning: "",
        constructionYear: parseInt(this.planimetry.yearBuilt),
        elevator: this.planimetry.hasElevator,
        energyClass: this.planimetry.energyClass,
        grossSquareFootage: parseInt(this.planimetry.surface),
        latitude: parseFloat(this.addressObject.latitude),
        longitude: parseFloat(this.addressObject.longitude),
        maintenanceStatus: this.status,
        marketZone: "",
        numberOfBathrooms: parseInt(this.planimetry.bathrooms),
        persistence: "",
        propertyType: this.typology,
        rooms: parseInt(this.planimetry.locali),
        unitFloor: this.planimetry.floor,
        zipCode: parseInt(this.addressObject.postalCode),
        buildingFloorCount: parseInt(this.planimetry.numFloors),
        terrace: this.planimetry.hasTerrace,
        balcony: this.planimetry.hasBalcony,
        furnished: "",
        kitchenType: "",
        newarcType: newarcType,
        requestType: requestType,
        valutaType: valutaType,
        vendiType: vendiType

      }

      axios
        .post(
          process.env.VUE_APP_predict,
          {
            comparabili: [this.currentImmobile],
            status: this.status,
            typology: this.typology,
            planimetry: this.planimetry,
            additionalInfo: this.additionalInfo,
            addressObject: this.addressObject,
            assignedAgency: null,
            ageRange: null,
          },
          {
            headers: {
              "Content-Type": "application/json",
              //"Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            },
          }
        )
        .then((response) => {
          // console.log(response);

          this.valuationResult.result = response.data;

          this.valuationResult.callStatus = response.status;
          this.valuationResult.callPerformed = true;
          //TODO gestire gli errori (schermata di errore)
        });
    },
    containsEmail(inputString) {
      // Regular expression for matching email addresses
      const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;

      // Use the test() method to check if the string contains an email
      return emailRegex.test(inputString);
    },
    containsItalianPhoneNumber(inputString) {
      // Regular expressions for matching Italian phone numbers
      const landlineRegex = /^(0[2-9][0-9]{7,8}|0[2-9][0-9]{10})$/;
      const mobileRegex = /^(3[0-9]{8,9})$/;

      // Use the test() method to check if the string contains a match
      return landlineRegex.test(inputString) || mobileRegex.test(inputString);
      // return true;
    },
    isNullOrEmpty(inputString) {
      // Check if the inputString is null or empty
      return inputString === null || inputString.trim() === '';
    }
  },
};
</script>

