<template>
  <div id="top" class="lg:mt-0  nap-top-container nap-listing-page" @click="clickOutsideElement">
    <navbar :openFilterOnMobile="openFilterOnMobile" :openMapOnMobile="openMapOnMobile" @toggleSorting="toggleSorting" />
    <main class="flex justify-center relative">

      <div v-if="!isMobile()" class="na-listing-map-background absolute top-0 left-0 z-0 w-full h-full">
        <map-listing-page v-model:mapId="id2" v-bind:mapPosition="mapPosition"
          :propertyCount="mapPosition.property.length" />
      </div>
      <div v-if="showMapOnMobile" class="na-listing-mobile-map fixed top-0 left-0 z-100 w-full h-full">
        <div class="fixed w-full z-20 nap-fixed-navbar">
          <div class="flex flex-row pt-3 pb-5 nap-mobile-nav-action-buttons justify-center relative">
            <button @click="openFilterOnMobile()" type="button"
              class="lg:p-0 filter-map px-3 py-2 bg-beige br-5 flex flex-row items-center">
              <img src="@/assets/img/icons/filters.svg" alt="">
              <span class="mx-auto">Filtri</span>
            </button>

            <button @click="closeMapOnMobile()" type="button"
              class="lg:p-0 px-1 py-2 bg-beige br-5 flex flex-row items-center absolute right-1 close">
              <close fillColor="#3D3D3D" />
            </button>

          </div>

        </div>
        <map-listing-page v-model:mapId="mapMobile" v-model="mapPosition" v-bind:mapPosition="mapPosition" />
      </div>

      <div v-if="showFilterOnMobile" class="na-listing-mobile-map fixed top-0 left-0 z-100 w-full h-full bg-white">
        <div class="fixed w-full z-20 bg-white nap-fixed-filter">
          <div
            class="flex flex-row p-4 nap-mobile-nav-action-buttons nap-mobile-nav-action-filter-buttons justify-center relative items-center">
            <button @click="resetFilter()" type="button"
              class="lg:p-0 filter-map px-3 py-2 bg-white br-5 flex flex-row items-center absolute left-3">
              <img src="@/assets/img/icons/bin.svg" alt="">
              <span class="mx-auto">Svuota</span>
            </button>

            <p class="nap-fixed-filter-title">Applica Filtri</p>

            <button @click="closeFilterOnMobile()" type="button"
              class="lg:p-0 px-1 py-2 bg-white br-5 flex flex-row items-center close absolute right-3">
              <close fillColor="#3D3D3D" />
            </button>

          </div>
          <div class="flex flex-col px-6 nap-fixed-filter-container overflow-auto" style="max-height:90vh;overflow:auto;">

            <div class="nap-filter-option flex flex-col">
              <h4>Tipologia</h4>
              <ul class="nap-filter-tipo na-pill-checkbox">
                <li v-for="(item, key) in typology" :class="[
                  'nap-pill-list-item',
                  isTypoAllSelected == true ? (item == 'Tutte' ? '' : 'opacity-50') : (item == 'Tutte' ? 'opacity-50' : '')
                ]" @click="adjustTypo(item)" :key="key">
                  <label>
                    <input type="radio" name="typology" v-model="checkboxTypology" :value="item">
                    <span class="nap-pill-list-item-label">{{ item }}</span>
                  </label>
                </li>

              </ul>
            </div>

            <div class="nap-filter-option flex flex-col">
              <h4>Prezzo</h4>

              <div class="flex flex-row justify-between px-4">
                <label class="fixed-label w-1/2 p-1 relative">
                  <span class="absolute left-3 top-3">Da</span>
                  <input @keyup="filterProperties()" class="w-full" v-model="price.value[0]" type="text">
                </label>
                <label class="fixed-label w-1/2 p-1 relative">
                  <span class="absolute left-3 top-3">A</span>
                  <input @keyup="filterProperties()" type="text" v-model="price.value[1]" class="w-full">
                </label>
              </div>

              <div class="relative mt-6 px-4">
                <Slider v-model="price.value" v-bind="price" @end="filterProperties()"></Slider>
              </div>
            </div>

            <div class="nap-filter-option flex flex-col">
              <h4>Locali</h4>

              <div class="flex flex-row justify-between px-4">
                <label class="fixed-label w-1/2 p-1 relative">
                  <span class="absolute left-3 top-3">Da</span>
                  <input @keyup="filterProperties()" class="w-full" v-model="locals.value[0]" type="text">
                </label>
                <label class="fixed-label w-1/2 p-1 relative">
                  <span class="absolute left-3 top-3">A</span>
                  <input @keyup="filterProperties()" type="text" v-model="locals.value[1]" class="w-full">
                </label>
              </div>

              <div class="relative mt-6 px-4">
                <Slider v-model="locals.value" v-bind="locals" @end="filterProperties()"></Slider>
              </div>
            </div>

            <div class="nap-filter-option flex flex-col">
              <h4>Bagni</h4>

              <div class="flex flex-row justify-between px-4">
                <label class="fixed-label w-1/2 p-1 relative">
                  <span class="absolute left-3 top-3">Da</span>
                  <input @keyup="filterProperties()" class="w-full" v-model="baths.value[0]" type="text">
                </label>
                <label class="fixed-label w-1/2 p-1 relative">
                  <span class="absolute left-3 top-3">A</span>
                  <input @keyup="filterProperties()" type="text" v-model="baths.value[1]" class="w-full">
                </label>
              </div>

              <div class="relative mt-6 px-4">
                <Slider v-model="baths.value" v-bind="baths" @end="filterProperties()"></Slider>
              </div>
            </div>
            <div class="nap-filter-option flex flex-col">
              <br>
              <br>
              <br>
              <br>
            </div>

          </div>

        </div>

      </div>




      <div class="lg:m-8 lg:m-2 container">

        <div class="listing-left bg-white na-listing-wrapper z-10 relative">

          <div class="na-listing-filter-wrapper flex flex-col lg:px-6 pt-6">
            <div class="na-filter-top flex lg:flex-row flex-col lg:justify-between items-center relative">
              <h3 class="flex flex-row f20-ls04 w-full justify-center lg:justify-start">
                Le case Newarc a &nbsp;
                <span @click="toggleCityZoneFilter()" class="cursor-pointer hidden items-center lg:flex flex-row flex-1">
                  <span class="flex flex-row">
                    <span class="flex">{{ selectedCity }}</span>
                    <span class="flex" v-if="selectedZone != ''">,&nbsp;</span>
                    <span class="flex">{{ selectedZone }}</span>
                  </span>
                  <chevron-down fillColor="#489B79" />
                </span>
              </h3>
              <div v-if="showCityZoneFilter"
                class="na-listing-city-zone-pop absolute left-20 bg-white p-4 flex flex-col justify-between z-100 shadow-md br-10">
                <div class="flex flex-row justify-between">
                  <div class="na-filter-box flex flex-col flex-1 mr-2 br-10">
                    <h6 class="mb-1">Città</h6>

                    <label v-for="city in cities" class="filter-label flex flex-row items-center">
                      <input type="radio" name="selectedZone" @click="clearZones(city)"
                        class="na-custom-radio option-input checkbox " :value="city" v-model="selectedCity">
                      {{ city }}
                    </label>
                  </div>
                  <div :class="[
                    'na-filter-box bg-beige flex flex-col flex-1 ml-2 br-10 h-full relative overflow-y-scroll',
                    selectedCity.length > 0 ? '' : 'opacity-50'
                  ]" style="max-height: 350px;">

                    <h6 class="mb-1">Zona</h6>

                    <label v-for="zone in zones" class="filter-label flex flex-row items-center">
                      <input type="radio" name="selectedCity" class="na-custom-radio option-input checkbox" :value="zone"
                        @click="setZoneToEmpty(zone)" v-model="selectedZone" v-bind:disabled="selectedCity.length == 0">
                      <span>{{ zone }}</span>
                    </label>
                  </div>
                </div>

                <div class="flex flex-row justify-center">
                  <button @click="toggleCityZoneFilter()" class="button p-2 mt-4 br-5 na-filter-button">Applica</button>
                </div>

              </div>
              <!-- <div class="na-filter-switch  lg:content-center lg:p-2 hidden lg:flex justify-around flex-row lg:mt-0 mt-2">
                
                <button @click="makeCompleteActive(true)" type="button" :class="[
                  'px-3 py-2 hover:shadow-md focus:shadow-lg rounded inline-block f11-ls034 font-bold text-center focus:outline-none active:shadow-lg transition duration-150 ease-in-out lh-13',
                  isCompleteActive == true ? 'active' : '' ]">Completate</button>
                
                <button @click="makeCompleteActive(false)" type="button" :class="['px-3 py-2 lg:ml-2 hover:shadow-md focus:shadow-lg rounded inline-block f11-ls034 font-bold text-center focus:outline-none active:shadow-lg transition duration-150 ease-in-out text-80 lh-13',
                  isCompleteActive == true ? '' : 'active' ]">Lavori in corso</button>

              </div> -->
            </div>
            <div v-if="!isMobile()" class="na-filter-bottom hidden lg:flex lg:flex-row flex-col justify-between mt-3">

              <div class="nap-desktop-filter na-filter-option p-2 grid grid-cols-3 gap-2 lg:flex flex-row">

                <div class="nap-filter-option flex flex-col">
                  <h4 class="flex flex-row justify-between"><span>Tipologia</span> <chevron-down fillColor="#000000" />
                  </h4>
                  <ul class="nap-d-f-opt nap-filter-tipo na-pill-checkbox">
                    <li v-for="(item, key) in typology" :class="[
                      'nap-pill-list-item',
                      isTypoAllSelected == true ? (item == 'Tutte' ? '' : 'opacity-50') : (item == 'Tutte' ? 'opacity-50' : '')
                    ]" @click="adjustTypo(item)" :key="key">
                      <label>
                        <input type="radio" name="typology" v-model="checkboxTypology" :value="item">
                        <span class="nap-pill-list-item-label">{{ item }}</span>
                      </label>
                    </li>

                  </ul>
                </div>

                <div class="nap-filter-option flex flex-col">
                  <h4 class="flex flex-row justify-between"><span>Prezzo</span> <chevron-down fillColor="#000000" /></h4>
                  <div class="nap-d-f-opt">
                    <div class=" flex flex-row justify-between px-4">
                      <label class="fixed-label w-1/2 p-1 relative">
                        <span class="absolute left-3 top-3">Da</span>
                        <input class="w-full" v-model="price.value[0]" type="text">
                      </label>
                      <label class="fixed-label w-1/2 p-1 relative">
                        <span class="absolute left-3 top-3">A</span>
                        <input type="text" v-model="price.value[1]" class="w-full">
                      </label>
                    </div>

                    <div class="relative mt-6 px-4">
                      <Slider v-model="price.value" v-bind="price" @end="filterProperties()"></Slider>
                    </div>
                  </div>

                </div>

                <div class="nap-filter-option flex flex-col">
                  <h4 class="flex flex-row justify-between"><span>Locali</span> <chevron-down fillColor="#000000" /></h4>

                  <div class="nap-d-f-opt">
                    <div class="flex flex-row justify-between px-4">
                      <label class="fixed-label w-1/2 p-1 relative">
                        <span class="absolute left-3 top-3">Da</span>
                        <input @keyup="filterProperties()" class="w-full" v-model="locals.value[0]" type="text">
                      </label>
                      <label class="fixed-label w-1/2 p-1 relative">
                        <span class="absolute left-3 top-3">A</span>
                        <input @keyup="filterProperties()" type="text" v-model="locals.value[1]" class="w-full">
                      </label>
                    </div>

                    <div class="relative mt-6 px-4">
                      <Slider v-model="locals.value" v-bind="locals" @end="filterProperties()"></Slider>
                    </div>
                  </div>

                </div>

                <div class="nap-filter-option flex flex-col">
                  <h4 class="flex flex-row justify-between"><span>Bagni</span> <chevron-down fillColor="#000000" /></h4>

                  <div class="nap-d-f-opt">
                    <div class="flex flex-row justify-between px-4">
                      <label class="fixed-label w-1/2 p-1 relative">
                        <span class="absolute left-3 top-3">Da</span>
                        <input @keyup="filterProperties()" class="w-full" v-model="baths.value[0]" type="text">
                      </label>
                      <label class="fixed-label w-1/2 p-1 relative">
                        <span class="absolute left-3 top-3">A</span>
                        <input @keyup="filterProperties()" type="text" v-model="baths.value[1]" class="w-full">
                      </label>
                    </div>

                    <div class="relative mt-6 px-4">
                      <Slider v-model="baths.value" v-bind="baths" @end="filterProperties()"></Slider>
                    </div>
                  </div>

                </div>
                <!-- <select name="city" id="city" class="na-select f11-ls07">
                  <option value="">Tipologia</option>
                  <option value="">Appartamento</option>
                  <option value="">Villa</option>
                  <option value="">Casa indipendente</option>
                  <option value="">Loft</option>
                  <option value="">Attico</option>
                  <option value="">Mansarda</option>
                </select>

                <select name="zone" id="zone" class="na-select f11-ls07">
                  <option value="">Prezzo</option>
                  <option value="">Option 1.2</option>
                  <option value="">Option 1.3</option>
                  <option value="">Option 1.4</option>
                </select>

                <select name="" id="" class="na-select active f11-ls07">
                  <option value="">Locali</option>
                  <option value="">Option 1</option>
                  <option value="">Option 1</option>
                  <option value="">Option 1</option>
                </select>

                <select name="" id="" class="na-select f11-ls07">
                  <option value="">Bagni</option>
                  <option value="">Option 1</option>
                  <option value="">Option 1</option>
                  <option value="">Option 1</option>
                </select> -->

                <!--<select name="" id="" class="na-select f11-ls07">
                  <option value="">Più filtri</option>
                  <option value="">Option 1</option>
                  <option value="">Option 1</option>
                  <option value="">Option 1</option>
                </select>-->


              </div>

              <!--<div class="na-filter-sort flex flex-row items-center justify-end relative">
                <button type="button" class="lg:p-2 lg:px-4 bg-none br-5 nap-desktop-sort-button">
                  <img style="width:20px;height:20px;" src="@/assets/img/icons/order.svg" alt="">
                </button>
                <div class="nap-d-f-opt absolute">
                  <div class="flex flex-col justify-between px-4">
                    <label v-for="(sortOpt, key ) in sortOptions" @click="sortProperty(key)" :class='[
                      key == selectedSort ? "active" : "", "nar-sort-option-label"
                    ]'>{{ sortOpt }}</label>
                  </div>
                  
                </div>
              </div>-->

            </div>

            <div class="na-filter-bottom lg:hidden  flex-row justify-between mt-3">

              <div class="na-filter-option p-2 grid grid-cols-2 justify-items-stretch">
                <label class="px-1">
                  <span>CITTÀ</span>
                  <select name="item-city" v-model="selectedCity" @change="filterProperties()" id="item-city"
                    class="na-select city-zone w-1/2">
                    <option v-for="city in cities" :value="city">{{ city }}</option>
                  </select>
                </label>

                <label class="px-1">
                  <span>ZONA</span>
                  <select name="item-zone" v-model="selectedZone" @change="filterProperties()" id="item-zone"
                    class="na-select city-zone w-1/2">
                    <option v-for="zone in zones" :value="zone">{{ zone }}</option>
                  </select>
                </label>


              </div>


            </div>

            <!-- <button type="button" @click="getNextNewarcHomes">Fetch next</button> -->


          </div>

          <div class="na-listing-container lg:mt-0 mt-3 lg:p-6">

            <div v-if="propertyCount > 0" v-for="( property, propertykey ) in properties" :key="propertykey"
              @click="navigateToDetails(property)" :class="[
                'na-list-item flex lg:flex-row flex-col justify-between',
                property['display'] == 1 ? '' : 'hidden'
              ]">

              <!-- <router-link :to="{ path: `/compra/${property['docId']}` }" clas="flex flex-row w-full"> -->


              <div :class="[
                'na-list-image-slider relative',
              ]">
                <!-- <div class="na-list-item-size absolute bottom-4 left-4 z-20 bg-white br-5 f13-ls05 text-black lh-13 px-3 py-1 font-bold">
                    <span class="text-666 f11-ls54">MQ</span> {{property.size}}
                  </div> -->



                <vueper-slides :bullets="false" :touchable="true" :arrows="true" :fixed-height="true" :fade="true"
                  class="no-shadow mx-auto">
                  <!-- :fixed-height="false" -->

                  <vueper-slide v-for="(image, index) in property.picturePaths" :key="index">
                    <template #content>
                      <div @mousedown="longPressStartTimer(property['docId'])" @mouseup="longPressClearTimer"
                        @mouseleave="longPressClearTimer" @click=""
                        class="nap-listing-slider-image w-full relative h-full br-10" :style="{
                          'background-image': `url(${image})`
                        }">
                        &nbsp;
                        <!-- Public tag box -->
                        <div v-if="property['publicStatusTags'] && Object.keys(property['publicStatusTags']).length > 0">

                          <div class="overlay-container">
                          <template v-for="(value, key) in property['publicStatusTags']" :key="key">
                            <div  :class="['overlay-box-slider', { 'overlay-personalizzabile': key === 'Personalizzabile', 'overlay-novita': key === 'Novità!' }]">
                              <div>
                                <h4 class="f11-017">{{ key }}</h4>
                              </div>
                              
                            </div>

                          </template>
                          </div>

                        </div>
                        <!-- <img class="w-full h-full br-10" v-bind:src="image" alt=""> -->
                        <!-- <img class="object-cover br-10" v-bind:src="image" alt=""> -->
                      </div>
                    </template>
                  </vueper-slide>
                </vueper-slides>
              </div>

              <!-- <div
                class="na-list-prop-info cursor-pointer na-list-info lg:pt-4 lg:pr-4  p-4 flex flex-col justify-between"
                @click="$router.push({
                  name: `SingleProperty`,
                  state: {
                    docId: property['docId'],
                    property: property
                  }
                })"> -->
              <div
                class="na-list-prop-info cursor-pointer na-list-info lg:pt-4 lg:pr-4  p-4 flex flex-col justify-between"
                @click="$router.push(`/compra/${property['docId']}`)">
                <div>
                  <h3 class="flex flex-row justify-between f15-ls05"><span>{{ property.title }}</span></h3>
                  <h4 class="f12-ls011">{{ property.zone }}, {{ property.city }} </h4>

                  <ul class="list-item-feature mt-6 grid grid-cols-2 gap-4">
                    <li class="flex flex-row items-center" v-for="(feature, icon) in property.features" :key="icon">
                      <div class="list-item-feature-image">

                        <img class="w-full" :style="{
                          'background-color': '#489B79',
                          'mask': 'url(\'' + require(`@/assets/img/icons/${icon}.svg`) + '\')',
                          '-webkit-mask': 'url(\'' + require(`@/assets/img/icons/${icon}.svg`) + '\')'
                        }">
                      </div>
                      <div class="list-item-feature-description ml-2 f12-ls05">{{ feature }}</div>
                    </li>

                  </ul>
                </div>
                <div class="flex flex-row justify-between items-center mt-4 ">
                  <div class="na-list-item-price lg:text-right text-left">
                    {{ property.price }}
                  </div>
                  <div class="na-list-item-actions flex flex-row">
                    <a v-if="property['phone'] != ''">
                      <img src="@/assets/img/icons/phone.svg">
                    </a>
                    <a v-if="property['share'] != ''">
                      <img src="@/assets/img/icons/share.svg">
                    </a>
                  </div>
                </div>

              </div>
              <!-- </router-link> -->

            </div>
            <div v-else>
              <div class="flex flex-row justify-center items-center h-24">
                <!-- <h3 v-if="loadingProperties == true ">Caricamento...</h3> -->
                <h3 class="text-center" v-if="loadingProperties == false">Nessun risultato per ora... ma la tua casa dei
                  sogni è qui da qualche parte!</h3>
                <!-- <h3 v-else>Caricamento...</h3> -->
              </div>

            </div>

            <div ref="scroll-loader">
              <h3 class="text-center" v-if="loadingProperties == true">Caricamento...</h3>
            </div>

          </div>

        </div>


      </div>

      <mobile-popup-sorting :sortOptions="sortOptions" :toggleSort="toggleSort" :selectedSort="selectedSort"
        @toggleSorting="toggleSorting" @sortProperty="sortProperty" />

    </main>
    <footer-component />

    <div id="hidden-tmp-map" class="hidden"></div>
  </div>
</template>
<style src="@vueform/slider/themes/default.css"></style>
<script>

import { collection, addDoc } from "firebase/firestore"
// the firestore instance
import db from '../firebase/init.js';

import Navbar from "@/components/Navbars/ListingNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import Close from "vue-material-design-icons/Close.vue";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
// import InformationVariant from "vue-material-design-icons/InformationVariant.vue";
import MapListingPage from "@/components/Maps/MapListingPage.vue";
// import { createPopper } from "@popperjs/core";
// import {Anitmate} from 'animate.css';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import Slider from '@vueform/slider'
import MobilePopupSorting from "./Property/MobilePopupSorting.vue";

import {

  ref,
  getDownloadURL,
  getStorage
} from 'firebase/storage';


import { functions, storage } from '../firebase/init.js';
import { httpsCallable } from 'firebase/functions';
import firebase from 'firebase/compat/app';


// https://www.vuescript.com/custom-range-slider/
export default {

  created() {
    this.getNewarcHomes();
  },
  mounted() {
    this.removeBodyOverflowHidden();

    /*window.onscroll = () => {
      const { scrollHeight, scrollTop, clientHeight } = this.$refs['scroll-loader'];
      if (scrollHeight - scrollTop === clientHeight && this.loadingProperties == false ) {
        // Fetch the next page of data
         if( this.hasMoreProperties == true ) {
          this.getNextNewarcHomes();
         }
         
      }
    }; */
  },
  unmounted() {
    this.removeBodyOverflowHidden();
  },
  methods: {

    toggleSorting() {
      // console.log('Sorting');
      this.toggleSort = !this.toggleSort;
      console.log(this.toggleSort);
    },

    sortProperty(sortby) {

      this.selectedSort = sortby;
      this.loadingProperties = true;

      switch (sortby) {
        case 'priceHighToLow':
          this.properties = this.properties.sort((a, b) => b['orgPrice'] - a['orgPrice']);
          break;
        case 'priceLowToHigh':
          this.properties = this.properties.sort((a, b) => a['orgPrice'] - b['orgPrice']);
          break;
        case 'timeRecent':
          this.properties = this.properties.sort((a, b) => a['endDate'] - b['endDate']);
          break;
        case 'timeLater':
          this.properties = this.properties.sort((a, b) => b['endDate'] - a['endDate']);
          break;
        default:
          this.properties = this.properties.sort((a, b) => a['endDate'] - b['endDate']);
          this.selectedSort = "timeRecent";
          break;

      }

      this.loadingProperties = false;


    },

    longPressStartTimer(docId) {
      // return;
      let timer = 0;
      if (this.isMobile()) {
        timer = 1500;
      }
      this.longPressTimer = setTimeout(() => {
        // handle long press action
        this.$router.push(`/compra/${docId}`);
      }, timer);
    },
    longPressClearTimer() {
      clearTimeout(this.longPressTimer);
    },

    clickOutsideElement(e) {
      if (e.target.classList.contains('nap-top-container') || e.target.classList.contains('na-listing-filter-wrapper') || e.target.classList.contains('na-listing-container') || e.target.classList.length == 0) {
        this.showCityZoneFilter = false; // or whatever logic would close your dropdown 
      }
    },
    clearZones(city) {

      if (city == this.selectedCity) {
        this.selectedCity = "";
      }

      if (this.selectedCity.length == 0) {
        this.selectedZone = "";
      }
    },
    setZoneToEmpty(zone) {
      if (zone == this.selectedZone) this.selectedZone = "";
    },

    addBodyOverflowHidden() {
      document.body.classList.add('overflow-hidden');
    },
    removeBodyOverflowHidden() {
      document.body.classList.remove('overflow-hidden');
    },

    adjustTypo(item) {
      this.checkboxTypology = item;

      if (item == 'Tutte') {
        this.isTypoAllSelected = true;
      } else {
        this.isTypoAllSelected = false;
      }
      this.filterProperties();
    },

    filterProperties() {
      // console.log('filter');

      this.propertyCount = 0;
      this.loadingProperties = true;
      this.properties = this.properties.map((property) => {

        // console.log(this.baths, this.locals, this.price );

        if (this.selectedZone != "" && this.selectedZone != "Tutte le zone" && this.selectedCity != "") {

          if (property['city'] == this.selectedCity && property['zone'] == this.selectedZone) {
            property.display = 1;
          } else {
            property.display = 0;
          }

          if (this.validateFilter(property)) {

            property['display'] = 1;
          } else {
            property['display'] = 0;
          }
          return property;

        } else if (this.selectedZone != "" && this.selectedZone != "Tutte le zone") {

          if (this.property['zone'] == this.selectedZone) {
            property.display = 1;
          } else {
            property.display = 0;
          }

          if (this.validateFilter(property)) {

            property['display'] = 1;
          } else {
            property['display'] = 0;
          }
          return property;

        } else if (this.selectedCity != "") {

          if (property['city'] == this.selectedCity) {
            property.display = 1;
          } else {
            property.display = 0;
          }

          if (this.validateFilter(property)) {

            property['display'] = 1;
          } else {
            property['display'] = 0;
          }

          return property;
        }

        return property.display = 1;

      });

      this.propertyCount = 0;
      this.properties.map((property) => {

        if (property.display == 1) this.propertyCount++;

      });

      this.loadingProperties = false;

      // console.log(this.properties);
      this.mapPosition.property = [];
      this.properties.forEach((property) => {
        if (property.display == 1) {
          let positions = {
            lat: parseFloat(property['location']['lat']),
            lng: parseFloat(property['location']['lng']),
            // image: 'https://firebasestorage.googleapis.com/v0/b/newarc-staging.appspot.com/o/newarcHomes%2F7iURmZLZdUhPd8QqTJ5y%2F1678002223744-1%20(4).jpg?alt=media&token=9883cc88-3abc-4a87-9031-0dc4368a7eda',
            image: property['picturePaths'][0],
            title: property['title'],
            price: property['orgPrice'],
            area: parseInt(property['size'])
          };
          this.mapPosition.property.push(positions);
        }

      });

      // console.log(this.properties);
    },

    validateFilter(property) {

      if (
        (parseInt(property['baths']) >= this.baths.value[0] && parseInt(property['baths']) <= this.baths.value[1])
        && (parseInt(property['locals']) >= this.locals.value[0] && parseInt(property['locals']) <= this.locals.value[1])
        && (parseInt(property['orgPrice']) >= this.price.value[0] && parseInt(property['orgPrice']) <= this.price.value[1])
        && ((this.checkboxTypology != 'Tutte' && property['type'] == this.checkboxTypology) || this.checkboxTypology == 'Tutte')
        && property['display'] == 1
      ) {
        return true;
      } else {
        return false;
      }
    },

    toggleCityZoneFilter() {
      this.showCityZoneFilter = !this.showCityZoneFilter;
      this.filterProperties();


    },

    makeCompleteActive(status) {
      this.isCompleteActive = status;
    },

    setProperties(result) {
      this.propertyCount = 0;
      this.loadingProperties = true;
      if (result.data.length > 0) {
        // console.log(result.length);
        for (let index = 0; index < result.data.length; index++) {

          let rooms = '';
          // switch (result.data[index]['baths']) {
          switch (parseInt(result.data[index]['locals'])) {
            case 1:
              rooms = 'Monolocale'
              break;
            case 2:
              rooms = 'Bilocale'
              break;
            case 3:
              rooms = 'Trilocale'
              break;
            case 4:
              rooms = 'Quadrilocale'
              break;
            default:
              rooms = 'Plurilocale'
              break;
          }

          let features = {
            'planimetria': rooms,
            'bagni': result.data[index]['baths'] + ' bagni',
            'piano': result.data[index]['floors'] + ' piano',
            'superficie': result.data[index]['mq'],
          };

          let price = '€000';
          let originalPrice = 0;
          if (result.data[index]['styles'].length > 0) {
            // Default price
            price = '€' + parseInt(result.data[index]['styles'][0]['price']).toLocaleString();
            originalPrice = parseInt(result.data[index]['styles'][0]['price']);

            for (let styleIndex = 0; styleIndex < result.data[index]['styles'].length; styleIndex++) {
              if (result.data[index]['styles']['isDefault'] == 1) {
                price = '€' + parseInt(result.data[index]['styles'][styleIndex]['price']).toLocaleString();
                originalPrice = parseInt(result.data[index]['styles'][styleIndex]['price']);
              }

            }

          }

          let propertyTitle = '';
          if (result.data[index]['addressComponents'] != null) {
            propertyTitle = rooms + ' in ' + result.data[index]['addressComponents']['street'] + ' ' + result.data[index]['addressComponents']['civico'];
          }

          let publicStatusTags = result.data[index]['publicStatus'];
          if (publicStatusTags != null) {
            Object.keys(publicStatusTags).forEach((key) => {
              // Check if the current value is equal to the value to remove
              if (publicStatusTags[key] === false) {
                // Remove the entry
                delete publicStatusTags[key];
              }
            });
          }


          this.propertyCount++;
          let property = {
            'currency': '€',
            'display': 1,
            'docId': result.data[index]['firebaseId'],
            'insertTimestamp': result.data[index]['insertTimestamp'],
            'title': propertyTitle,
            'description': result.data[index]['description'],
            'location': result.data[index]['location'],
            'size': result.data[index]['mq'],
            'price': price,
            'orgPrice': originalPrice,
            'eurpermq': 0,
            'features': features,
            'images': result.data[index]['picturePaths'] ?? [],
            'picturePaths': result.data[index]['picturePaths'],
            'phone': '',
            'share': '',
            'characteristics': result.data[index]['propertyFeatures'],
            'startDate': result.data[index]['startDate'],
            'endDate': result.data[index]['endDate'],
            'limitDate': result.data[index]['limitDate'],
            'styles': result.data[index]['styles'] ?? [],
            'city': result.data[index]['city'],
            'zone': result.data[index]['zone'],
            'locals': result.data[index]['locals'],
            'baths': result.data[index]['baths'],
            'type': result.data[index]['type'],
            'addressComponents': result.data[index]['addressComponents'],
            'publicStatusTags': publicStatusTags
          };


          
          this.properties.push(property);
          // console.log('location',parseFloat(result.data[index]['location']['lat']), parseFloat(result.data[index]['location']['lng']));

        }

        // console.log(this.mapPosition);



        // console.log(this.properties);

      }
      this.loadingProperties = false;
      this.loadStyleImageFromFb();


    },

    async loadStyleImageFromFb() {
      let locations = [];
      if (this.properties.length > 0) {
        // If property is not empty then check the properties
        for (let pIndex = 0; pIndex < this.properties.length; pIndex++) {
          // let p
          let tmpProperty = this.properties[pIndex];
          // console.log(tmpProperty);

          // If property has styles
          if (tmpProperty['styles'].length > 0) {

            let tmpStyle = tmpProperty['styles'];
            for (let styIndex = 0; styIndex < 1; styIndex++) {

              // If property styles has images
              if (tmpStyle[styIndex]['picturePaths'].length > 0) {

                let tmpStylePicture = tmpStyle[styIndex]['picturePaths'];

                let img_counter = 0;
                for (let picIndex = 0; picIndex < tmpStylePicture.length; picIndex++) {
                  await this.getImageUrlFromFS(pIndex, picIndex, this.properties[pIndex]['docId'], tmpStylePicture[picIndex]);
                  img_counter++;
                }
              }
            }
          }


          let positions = {
            lat: parseFloat(tmpProperty['location']['lat']),
            lng: parseFloat(tmpProperty['location']['lng']),
            // image: 'https://firebasestorage.googleapis.com/v0/b/newarc-staging.appspot.com/o/newarcHomes%2F7iURmZLZdUhPd8QqTJ5y%2F1678002223744-1%20(4).jpg?alt=media&token=9883cc88-3abc-4a87-9031-0dc4368a7eda',
            image: tmpProperty['picturePaths'][0],
            title: tmpProperty['title'],
            price: tmpProperty['orgPrice'],
            area: parseInt(tmpProperty['size'])
          };

          // this.mapPosition.property.push(positions);
          locations.push(positions);

        }
      }
      this.mapPosition.property = locations;
      // console.log('Image loading done...');
    },

    navigateToDetails(property) {

    },

    /*async getNewarcHomes() {

      const initialFetchNewarcHomes = httpsCallable(functions, 'initialFetchNewarcHomes');
      const result = await initialFetchNewarcHomes({ lastTimestamp: Date.now() });
      this.setProperties(result);


    },*/
    async getNewarcHomes() {
      try {
        const db = firebase.firestore();
        const newarcHomesRef = db.collection("newarcHomes").where('isActive', '==', true)
          .orderBy("endDate", 'desc');
        const querySnapshot = await newarcHomesRef.get();
        const homes = [];
        querySnapshot.forEach((doc) => {
          homes.push(doc.data());
        });
        this.setProperties({ data: homes });
      } catch (error) {
        console.error(error);
        this.setProperties({ data: [] })
      }
    },
    async getNextNewarcHomes() {
      this.loadingProperties = true;
      const lastTimestamp = this.properties[this.properties.length - 1]['insertTimestamp'];


      try {
        const db = firebase.firestore();
        const newarcHomesRef = db.collection("newarcHomes").where('isActive', '==', true)
          .orderBy("endDate", 'desc')
          .startAfter(lastTimestamp)
          .limit(this.propertiesPerCall);
        const snapshot = await newarcHomesRef.get();
        const homes = snapshot.docs.map(doc => doc.data());
        // console.log(homes);
        if (homes.length > 0) {
          this.setProperties({ data: homes });
        } else {
          this.hasMoreProperties = false;
        }


      } catch (error) {
        console.error(error);
        this.setProperties({ data: [] });
      }
      this.loadingProperties = false;
    },
    /*async getNextNewarcHomes() {
      let nextFetchTimestamp = this.properties[this.properties.length - 1]['insertTimestamp'];
      const nextFetchNewarcHomes = httpsCallable(functions, 'nextFetchNewarcHomes');
      const result = await nextFetchNewarcHomes({ lastTimestamp: nextFetchTimestamp });
      this.setProperties(result);
    },*/

    async getImageUrlFromFS(propertyKey, imageIndex, docId, image) {

      const storage = getStorage();
      const url = await getDownloadURL(ref(storage, `newarcHomes/${docId.trim()}/${image.trim()}`))
        .then((url) => {
          this.properties[propertyKey]['picturePaths'][imageIndex] = url;
          // console.log(url);

          if (imageIndex == 0) {
            this.mapPosition.property[propertyKey]['image'] = url;
          }

          return url;

        })
        .catch((error) => {
          // Handle any errors
        });

      return url;

    },

    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },

    openFilterOnMobile() {
      this.showFilterOnMobile = true;
      this.showMapOnMobile = false;
      this.addBodyOverflowHidden();
    },
    closeFilterOnMobile() {
      this.showFilterOnMobile = false;
      this.removeBodyOverflowHidden();
    },
    openMapOnMobile() {
      this.addBodyOverflowHidden();
      this.showMapOnMobile = true;
    },
    closeMapOnMobile() {
      this.removeBodyOverflowHidden();
      this.showMapOnMobile = false;
    },
    resetFilter() {
      this.price.value[0] = 1;
      this.price.value[1] = 10000000;

      this.locals.value[0] = 1;
      this.locals.value[1] = 10;

      this.baths.value[0] = 1;
      this.baths.value[1] = 10;

      this.checkboxTypology = "Tutte";

    }
  },
  data() {
    return {
      selectedSort: "",
      selectedSort: "timeRecent",
      toggleSort: false,
      sortOptions: {
        "priceHighToLow": "By Price high to low",
        "priceLowToHigh": "By Price low to high",
        "timeRecent": "By Time Finishing soon",
        "timeLater": "By Time Finishing later"
      },
      propertiesPerCall: 2,
      propertyCount: 0,
      hasMoreProperties: true,
      loadingProperties: true,
      isCompleteActive: true,
      showCityZoneFilter: false,
      selectedCity: "Torino",
      selectedZone: "Tutte le zone",
      cities: ['Torino'],
      zones: ["Tutte le zone", "Campidoglio", "Colle della Maddalena", "San Secondo", "Pozzo Strada", "Crocetta", "San Salvario - Baretti", "Sassi", "Parella", "Lingotto", "Piazza Solferino", "Quadrilatero Romano", "Cavoretto", "Madonna del Pilone", "Superga", "Via della Rocca", "Santa Rita", "Via Roma", "Cit Turin", "San Donato", "Mirafiori Nord", "Gran Madre - Crimea", "San Salvario - Dante", "San Paolo", "Cenisia", "Nizza Millefonti", "Regio Parco", "Cittadella", "Città Giardino", "Vanchiglia", "Vanchiglietta", "Centro Europa", "Giardini Reali", "Italia 61"],
      typology: ['Tutte', 'Appartamento', 'Villa', 'Casa indipendente', 'Loft', 'Attico', 'Mansarda'],
      checkboxTypology: "Tutte",
      isTypoAllSelected: true,
      price: {
        value: [1, 1000000],
        min: 1,
        max: 1000000,
        step: 1000,
        tooltips: false,
        classes: ['nap-range-slider']

      },
      locals: {
        value: [1, 6],
        min: 1,
        max: 6,
        step: 1,
        tooltips: false,

      },
      baths: {
        value: [1, 4],
        min: 1,
        max: 4,
        step: 1,
        tooltips: false,

      },
      showMapOnMobile: false,
      showFilterOnMobile: false,
      properties: [],
      filteredProperties: [],
      id2: "listing-map-canvas",
      mapMobile: "listing-map-canvas-mobile",
      mapPosition: {
        moveToRight: true,
        showPopup: true,
        center: {
          lat: 45.071379,
          lng: 7.679094
        },
        property: [
          // {
          //   image: 'https://firebasestorage.googleapis.com/v0/b/newarc-staging.appspot.com/o/newarcHomes%2F7iURmZLZdUhPd8QqTJ5y%2F1678002223744-1%20(4).jpg?alt=media&token=9883cc88-3abc-4a87-9031-0dc4368a7eda',
          //   title: 'Trilocale in Via Serrano 25',
          //   price: 249000,
          //   area: 96,
          //   lat: 45.0662249,
          //   lng: 7.6364855
          // },
          // {
          //   image: 'https://firebasestorage.googleapis.com/v0/b/newarc-staging.appspot.com/o/newarcHomes%2F7iURmZLZdUhPd8QqTJ5y%2F1678002223744-1%20(4).jpg?alt=media&token=9883cc88-3abc-4a87-9031-0dc4368a7eda',
          //   title: 'Trilocale in Via Borgosesia 109',
          //   price: 149000,
          //   area: 75,
          //   lat: 45.083168,
          //   lng: 7.6377087
          // },
          // {
          //   image: 'https://firebasestorage.googleapis.com/v0/b/newarc-staging.appspot.com/o/newarcHomes%2FbdkUnf3OHjRuo956fXG8%2FCucina_Corso%20Orbassano_R.jpg?alt=media&token=5f3cc648-1750-41cf-9626-e2c02ef431a0',
          //   title: 'Quadrilocale in Corso Orbassano 214',
          //   price: 329000,
          //   area: 130,
          //   lat: 45.0420944,
          //   lng: 7.6352393
          // },
          // {
          //   image: 'https://firebasestorage.googleapis.com/v0/b/newarc-staging.appspot.com/o/newarcHomes%2FVV3D19XCBQoe2UKm4uBh%2FGuglielminetti_soggiorno2.jpg?alt=media&token=e7c3aeb4-36cd-4994-bd9a-e73886c3be12',
          //   title: 'Bilocale in Via Guglielminetti Amalia 29',
          //   price: 129000,
          //   area: 85,
          //   lat:45.0537947,
          //   lng: 7.6334242
          // },
          // {
          //   image: 'https://firebasestorage.googleapis.com/v0/b/newarc-staging.appspot.com/o/newarcHomes%2F1726INCW3j39nKTAl8yk%2Fcamera%20matrimoniale%20def.png?alt=media&token=89b6f8db-dcb0-4016-b87b-244273112044',
          //   title: 'Trilocale in Corso Siracusa 59',
          //   price: 189000,
          //   area: 85,
          //   lat: 45.0495151,
          //   lng: 7.6327122
          // }
        ]
      }
    };
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  components: {
    Navbar,
    FooterComponent,
    MapListingPage,
    VueperSlides,
    VueperSlide,
    Close,
    ChevronDown,
    Slider,
    MobilePopupSorting

  },
};


</script>
