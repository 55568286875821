<template>
    <div :class="[
        'nap-map-popup nap-mobile-configurator-div nap-popup-desktop na-overlay fixed my-auto mx-auto w-full h-full bg-white z-30 top-0 left-0',
        showConfigPop == true ? 'block' : 'hidden'
    ]">

        <div class="relative nap-popup-wrapper w-full h-full z-40 flex flex-row items-center justify-center">


            <div
                class="lg:w-11/12 w-full nap-popup-container nap-config-container flex flex-row items-center justify-center bg-white br-10">
                <div ref="napmobilescrollcontainer"
                    class="nap-mobile-scroll-container h-full w-full overflow-y-auto overflow-x-hidden">

                    <div class="fixed w-full top-0 left-0 z-50 bg-white flex flex-row items-center justify-center p-3">
                        <h3 class="text-green font-bold f18-ls79">Configura</h3>
                        <div class="nap-contact-form-close-button absolute right-2 cursor-pointer z-50 flex flex-row items-center justify-center"
                            style="margin-top:0px;" @click="hideConfigurePopup()">
                            <img src="@/assets/img/icons/bold-close.png" height="10" />
                        </div>
                    </div>

                    <div>
                        <div style="padding:10px;">
                            <div class="h-50 w-full bg-gray-200 header-sidebar-progressbar">

                                <div
                                    class="na-property-time-slider-container items-center relative w-full lg:w-1/2 bg-white lg:p-1 lg:pt-2 lg:px-4 p-3 br-10 pb-2 lg:shadow-none shadow-lg">
                                    <div class="text-center timeline-title">AVANZAMENTO LAVORI</div>

                                    <div class="flex flex-row justify-between">
                                        <img width="20" src="@/assets/img/icons/house_started.svg">
                                        <img width="20" src="@/assets/img/icons/house_compleated.svg">
                                    </div>
                                    <div class="na-property-time-slider relative flex flex-row items-center ">
                                        <div class="absolute bg-beige w-full z-10 br-5 na-time-slider-all">

                                        </div>
                                        <div class="absolute bg-green z-20 br-5  na-time-slider-current" :style="{
                                            'width': progress.completed + '%'
                                        }">
                                            <nar-tooltip v-if="progress.completed < 100" :id="`todayDate`" width="200"
                                                textAlign="center" title="" :description="property.formattedTodayDate"
                                                position="top">
                                                <div :id="`todayDate`"
                                                    class="absolute identy-icon flex flex-row items-center justify-center bg-green"
                                                    :style="{
                                                        'right': progress.completed == 100 ? '0px' : '-17px'
                                                    }">

                                                    <img src="@/assets/img/icons/hammer.svg" class="object-cover" alt="">
                                                </div>
                                            </nar-tooltip>
                                        </div>
                                    </div>
                                    <div class="relative hidden lg:flex flex-row justify-between items-center pb-2">
                                        <span class="na-time-slider-label">Inizio</span>
                                        <span class="na-time-slider-label">Fine</span>
                                    </div>
                                    <div :class="[
                                        'absolute timeline-slider mx-auto flex justify-center w-full items-center',
                                        ''
                                    ]">

                                        <div :class="[
                                            parseInt(property.timeStatus) >= 100 ? 'timeline-completed' : 'timeline-in-progress',
                                            'timeline-status px-6']">
                                            <span v-if="parseInt(property.timeStatus) >= 100">
                                                Lavori completati!
                                            </span>
                                            <span v-else>
                                                Configurabile fino al {{ property.formattedEndDate }}
                                            </span>


                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="mt-4 pb-10">

                        <div id="nap-scroll-to-section-tabs" @scroll="handleScroll"
                            class="nap-scroll-to-section-tabs w-full overflow-scroll">

                            <ul class="flex flex-row gap-4">
                                <!-- TO BE UNCOMMENTED AT FUTURE DATE
                                    
                                    <li ref="stilli_newarc_li" v-on:click="scrollToConfigSection('stilli_newarc')"
                                    class="active">Stilli Newarc</li>
                                <li ref="tinta_pareti_li" v-on:click="scrollToConfigSection('tinta_pareti')">Tinta pareti
                                </li>
                                <li ref="pavimenti_li" v-on:click="scrollToConfigSection('pavimenti')">Pavimenti</li>
                                <li ref="rivestimenti_li" v-on:click="scrollToConfigSection('rivestimenti')">Rivestimenti
                                </li>-->
                                <li ref="optional_li" v-on:click="scrollToConfigSection('optional')" class="active">Optional</li>

                            </ul>
                        </div>

                        <div v-if="parseInt(property.timeStatus) < 100" class="nap-scroll-section-wrapper">
                            <!--TO BE UNCOMMENTED AT FUTURE DATE
                            <div data-ref="stilli_newarc" ref="stilli_newarc" id="stilli_newarc"
                                class="nap-scroll-section-container">

                                <h3>Stilli Newarc</h3>
                                <div class="nap-mobile-config-top-slider">
                                    <vueper-slides v-if="sliderImages.length > 0" ref="vueperslides-mobile-header"
                                        @slide="setSlideIndex($event.currentSlide.index)" :arrows="false" :bullets="false"
                                        :fixed-height="true" class="relative no-shadow na-vueper-slide">

                                        <vueper-slide class="main-slider" v-for="(image, index) in sliderImages "
                                            :key="index">
                                            <template #content>
                                                <div class="nap-slide-image-container flex w-full relative overflow-hidden"
                                                    :style="{
                                                        'background': `url('${image}')`
                                                    }">
                                                </div>
                                            </template>
                                        </vueper-slide>
                                    </vueper-slides>
                                </div>

                                <div class="flex overflow-x-auto mt-10p" style="padding: 0px 15px;">
                                    <button v-if="configuration.length > 3" class="mr-2"
                                        v-on:click="$refs.vueperslides2.previous()"><img class=""
                                            src="@/assets/img/icons/arrow-left.png" alt=""></button>

                                    <vueper-slides v-if="configuration.length > 0" ref="vueperslides2" :arrows="false"
                                        :bullets="false" :infinite="true" :fixed-height="true"
                                        :visible-slides="configuration.length > 3 ? 3 : configuration.length" :class="[
                                            'relative no-shadow sidebar-slider-slides',
                                            configuration.length > 3 ? 'slider-enabled' : 'slider-disabled'
                                        ]">

                                        <vueper-slide class="sidebar-slider"
                                            v-for="(config, config_index) in configuration " :key="index">
                                            <template #content>
                                                <div
                                                    :class="['flex flex-col w-full relative overflow-hidden sidebar-slide-config-image ', configuration.length > 1 ? 'items-center' : '']">
                                                    <img class="" src="@/assets/img/prop-style-slider.png" alt="">
                                                    <div class="flex flex-col items-center">
                                                        <span class="f13 font-bold mt-1">{{ config.title }}</span>
                                                        <span class="f13 mt-1">{{ property.currency }}{{ config.price
                                                        }}</span>
                                                    </div>

                                                </div>
                                            </template>
                                        </vueper-slide>
                                    </vueper-slides>
                                    <button v-if="configuration.length > 3" class="ml-2"
                                        v-on:click="$refs.vueperslides2.next()"><img class=""
                                            src="@/assets/img/icons/arrow-right.png" alt=""></button>
                                </div>

                                <div class="border-thin-grey"></div>


                            </div>

                            <div data-ref="tinta_pareti" ref="tinta_pareti" id="tinta_pareti"
                                class="nap-scroll-section-container">

                                <h3>Tinta pareti</h3>
                                <div class="nap-mobile-config-top-slider">
                                    <vueper-slides v-if="sliderImages.length > 0" ref="vueperslides-mobile-header"
                                        @slide="setSlideIndex($event.currentSlide.index)" :arrows="false" :bullets="false"
                                        :fixed-height="true" class="relative no-shadow na-vueper-slide">

                                        <vueper-slide class="main-slider" v-for="(image, index) in sliderImages "
                                            :key="index">
                                            <template #content>
                                                <div class="nap-slide-image-container flex w-full relative overflow-hidden"
                                                    :style="{
                                                        'background': `url('${image}')`
                                                    }">
                                                </div>
                                            </template>
                                        </vueper-slide>
                                    </vueper-slides>
                                </div>

                                <div class="flex overflow-x-auto mt-10p">
                                    <button v-if="configuration.length > 3" class="mr-2"
                                        v-on:click="$refs.vueperslides3.previous()"><img class=""
                                            src="@/assets/img/icons/arrow-left.png" alt=""></button>

                                    <vueper-slides v-if="configuration.length > 0" ref="vueperslides3" :arrows="false"
                                        :bullets="false" :infinite="true" :fixed-height="true"
                                        :visible-slides="configuration.length < 3 ? 1 : 3" :class="['relative no-shadow modification-slider-slides',
                                            configuration.length < 4 ? 'slider-disabled-' : '-slider-enabled']">

                                        <vueper-slide :style="{
                                            'width': configuration.length < 3 ? '100px' : 'auto'
                                        }" class="sidebar-modification-slider items-center"
                                            v-for="(config, config_index) in configuration " :key="index">
                                            <template #content>
                                                <div
                                                    :class="['flex flex-col w-full relative overflow-hidden sidebar-modification-image items-center']">
                                                    <img class="" src="@/assets/img/modification-image.png" alt="">
                                                    <div class="flex flex-col items-center">
                                                        <span class="f13 font-bold mt-1">{{ config.title }}</span>
                                                        <span class="f13 mt-1">{{ property.currency }}{{ config.price
                                                        }}</span>
                                                    </div>

                                                </div>
                                            </template>
                                        </vueper-slide>
                                    </vueper-slides>
                                    <button v-if="configuration.length > 3" class="ml-2"
                                        v-on:click="$refs.vueperslides3.next()"><img class=""
                                            src="@/assets/img/icons/arrow-right.png" alt=""></button>
                                </div>

                                <div ref="pavimenti_bottom" class="border-thin-grey"></div>


                            </div>

                            <div data-ref="pavimenti" ref="pavimenti" id="pavimenti" class="nap-scroll-section-container">
                                <h3>Pavimenti</h3>
                                <p>Blank space added to check the scroll</p>
                                <br><br><br><br><br><br><br><br><br><br><br><br><br><br>
                                <br><br><br><br><br><br><br><br><br><br><br><br><br><br>
                                <br><br><br><br><br><br><br><br><br><br><br><br><br><br>
                                <div class="border-thin-grey"></div>
                            </div>

                            <div data-ref="rivestimenti" ref="rivestimenti" id="rivestimenti"
                                class="nap-scroll-section-container">
                                <h3>Rivestimenti</h3>
                                <p>Blank space added to check the scroll</p>
                                <br><br><br><br><br><br><br><br><br><br><br><br><br><br>
                                <br><br><br><br><br><br><br><br><br><br><br><br><br><br>
                                <br><br><br><br><br><br><br><br><br><br><br><br><br><br>
                                <div class="border-thin-grey"></div>
                            </div>-->

                            <div data-ref="optional" ref="optional" id="optional" class="nap-scroll-section-container">
                                <h3>Optional</h3>
                                <ul class="grid grid-cols-2 gap-x-4 gap-y-12 px-4 mb-40">
                                    <li v-for="(configOption, index) in configurationOptions" :key="index"
                                        @click="selectionConfigurationOption(configOption)" :class="[
                                            'nap-config-options-list items-center justify-start relative',
                                            configurationOptionSelected.length > 0 && configurationOptionSelected.indexOf(configOption) != -1 ? 'active' : ''
                                        ]" style="width:150px;">

                                        <div class="absolute left-2 top-2">
                                            <input type="checkbox"
                                                v-bind:class="['na-custom-radio option-input radio', configurationOptionSelected.length > 0 && configurationOptionSelected.indexOf(configOption) > -1 ? '' : 'hidden']"
                                                :checked="configurationOptionSelected.length > 0 && configurationOptionSelected.indexOf(configOption) != -1">
                                        </div>

                                        <div class="" style="width:150px; height:100px;">
                                            <!-- <img v-bind:src="configOption.image" v-bind:class="[
                                            'object-cover',
                                            'p-0',
                                            'm-0',
                                            'option-na-feature-item-image'
                                        ]" alt=""> -->

                                            <div class="nap-sb-con-op-image" :style="{
                                                // 'background': `url('${configOption.image}')`
                                                'background': 'url(' + require(`@/assets/img/${configOption.title.toLowerCase().replace(' ', '-').concat('.jpg')}`) + ')'
                                            }"> &nbsp;

                                            </div>

                                            <h4 class="text-green f12-ls011 lh-14 mt-2">{{ configOption.title }}</h4>
                                            <span class="f10-017 nap-config-option-price flex items-center">€{{
                                                configOption.price
                                            }}</span>
                                        </div>

                                        <!-- < -->
                                        <nar-tooltip :id="`configOptionMobile${index}`" :width="200" title=""
                                            :description="configOption.description" position="top">
                                            <div :id="`configOptionMobile${index}`" class="absolute right-2 top-2">
                                                <svg class="h-6 w-6 text-black" viewBox="0 0 24 24" fill="#ECECEC"
                                                    stroke="#22252B" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round">
                                                    <circle cx="12" cy="12" r="10" />
                                                    <line x1="12" y1="16" x2="12" y2="12" />
                                                    <line x1="12" y1="8" x2="12.01" y2="8" />
                                                    >Dark tooltip
                                                </svg>
                                            </div>
                                        </nar-tooltip>

                                    </li>
                                </ul>
                            </div>

                        </div>


                        <!-- <h3 class="px-4 pb-2 f18-ls79 text-black font-bold">1. Scegli uno stile</h3> -->
                        <!-- <div class="relative px-4">


                            <vueper-slides :visibleSlides="2" :arrows="true" :bullets="false" :gap="2" :fixed-height="true"
                                class="no-shadow" style="height:70px;">
                                

                                <vueper-slide v-for="(config, configname, listindex) in configuration" :key="configname">
                                    <template #content>
                                        <div :key="listindex" class="">
                                            <div @click="updateConfigurationSelection(configname)"
                                                :class="[
                                                        'nap-list-config-slider flex flex-row items-center items-center br-10 border-2 px-2', configname == configurationSelected ? 'active' : '']"
                                                style="height:60px;">
                                                <div class="mr-1">
                                                    <input name="configure-mo"
                                                        @click="updateConfigurationSelection(configname)"
                                                        v-model="chConfigurationSelected" :value="configname"
                                                        class="na-custom-radio option-input radio" type="radio"
                                                        :checked="configname == configurationSelected">

                                                </div>
                                                <div @click="updateConfigurationSelection(configname)"
                                                    class="flex flex-col justify-start items-start">
                                                    <small @click="updateConfigurationSelection(configname)"
                                                        v-if="config.is_predefined"
                                                        class="configure-item-sub-title-2 text-black text-left">PREDEFINITO</small>
                                                    <span @click="updateConfigurationSelection(configname)"
                                                        class="configure-item-title text-left">{{ config.title }}</span>
                                                    <small @click="updateConfigurationSelection(configname)"
                                                        class="configure-item-price text-black text-left text-green">{{
                                                            property.currency }}{{ parseInt(config.price).toLocaleString()
    }}</small>
                                                </div>


                                            </div>
                                        </div>
                                    </template>
                                </vueper-slide>
                            </vueper-slides>
                        </div> -->



                    </div>

                    <div
                        class="nap-popup-footer fixed bottom-0 left-0 w-full items-center justify-center z-1000 bg-white px-8 py-2">
                        <div class="w-full flex flex-col">
                            <div class="flex flex-row justify-between py-2">
                                <div class="nap-config-footer-info flex flex-row items-center text-22252B">
                                    <!-- <chevron-up fillColor="#22252B" :size="30"/> -->
                                    <span class="ml-2">La tua configurazione</span>
                                </div>
                                <div class="na-property-item-price flex flex-col text-center">
                                    <div class="nap-bs-price">
                                        {{ property.currency }} {{ parseInt(grandTotal).toLocaleString() }}
                                    </div>
                                    <!-- <span class="unit-price">
                                        {{ parseInt(property['eurpermq']).toLocaleString() }} €/m²
                                    </span> -->

                                </div>
                                <!--
                                <span class="unit-price">
                                    {{ parseInt(property['eurpermq']).toLocaleString() }} €/m²
                                </span>-->
                            </div>
                            <div class="bg-white flex flex-row justify-center w-full">
                                <button
                                    class="py-3 br-10 bg-green nap-mobile-config-save-button text-white w-full font-bold"
                                    @click="hideConfigurePopup()">
                                    Conferma configurazione
                                </button>
                            </div>
                        </div>


                    </div>



                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import Close from "vue-material-design-icons/Close.vue";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import NarTooltip from "../../components/utils/Tooltip/NarTooltip.vue";



export default {

    props: {
        progress: {
            type: Object,
            required: true,
            default: {}
        },
        property: {
            type: Object,
            required: true,
            default: {}
        },
        showConfigPop: {
            type: Boolean,
            required: true,
            default: false
        },
        configuration: {
            type: Object,
            required: true,
            default: {}
        },
        configurationOptionSelected: {
            type: Object,
            required: true,
            default: []
        },
        sliderImages: {
            type: Object,
            required: true,
            default: {}
        },
        configurationOptions: {
            type: Object,
            required: true,
            default: {}
        },
        configurationSelected: {
            type: Number,
            required: true,
            default: 0

        },
        price: {
            type: Number,
            required: true,
            default: 0

        },
        grandTotal: {
            type: Number,
            required: true,
            default: 0

        }
    },
    emits: ["hideConfigurePopup", "selectionConfigurationOption", "setSlideIndex", "updateConfigurationSelection", "updateSliderImages", "toggleStyleMoreInfo"],
    data() {
        return {
            scrolloff: 0,
            chConfigurationSelected: this.configurationSelected,
            chConfigurationOptionSelected: this.configurationOptionSelected,
            containerPositions: []
        }
    },
    mounted() {

        this.$refs.napmobilescrollcontainer.addEventListener('scroll', this.handleScroll);
        let container = document.getElementsByClassName("nap-scroll-section-container");
        for (let index = 0; index < container.length; index++) {

            this.containerPositions.push({
                'position': container[index].offsetTop - 200,
                'ref': container[index].getAttribute('data-ref')
            });

        }

    },
    unmounted() {
        if (this.$refs.napmobilescrollcontainer) {
            this.$refs.napmobilescrollcontainer.removeEventListener('scroll', this.handleScroll);
        }

        let container = document.getElementsByClassName("nap-scroll-section-container");

    },
    updated() {

        let container = document.getElementsByClassName("nap-scroll-section-container");

    },
    created() {
        let container = document.getElementsByClassName("nap-scroll-section-container");

    },
    methods: {

        handleScroll(event) {

            const element = this.$refs.napmobilescrollcontainer;
            let header = document.querySelector(".nap-scroll-to-section-tabs");
            // let container = document.querySelector(".nap-scroll-section-wrapper");

            this.scrolloff = element.scrollTop;

            if (element.scrollTop > 110) {
                this.scrolloff = 'added';
                header.classList.add('menu-sticky');
                // container.classList.add('menu-sticky'); 
            } else {
                header.classList.remove('menu-sticky');
            }

            let ul_item = document.querySelector(".nap-scroll-to-section-tabs").getElementsByTagName("li");
            for (let index = 0; index < ul_item.length; index++) {
                document.querySelector(".nap-scroll-to-section-tabs").getElementsByTagName("li")[index].classList.remove('active');
            }

            let flag_active = false;
            for (let _index = 0; _index < this.containerPositions.length; _index++) {

                const container = this.containerPositions[_index];

                if ((_index + 1) < this.containerPositions.length) {
                    if (element.scrollTop > container.position && element.scrollTop < this.containerPositions[_index + 1].position) {
                        this.$refs[container.ref + '_li'].classList.add('active');
                        flag_active = true;
                        break;
                    }
                } else {
                    if (element.scrollTop > container.position) {
                        this.$refs[container.ref + '_li'].classList.add('active');
                        flag_active = true;
                        break;
                    }
                }


            }

            if (flag_active == false) {
                let alternate = this.containerPositions[0];
                this.$refs[alternate.ref + '_li'].classList.add('active');

            }

        },
        scrollToConfigSection(refName) {

            let ul_item = document.querySelector(".nap-scroll-to-section-tabs").getElementsByTagName("li");
            for (let index = 0; index < ul_item.length; index++) {
                document.querySelector(".nap-scroll-to-section-tabs").getElementsByTagName("li")[index].classList.remove('active');
            }
            this.$refs[refName + '_li'].classList.add('active');

            if (parseInt(this.property.timeStatus) > 100) return;


            // this.$refs[refName].classList.add('active');
            this.$refs.napmobilescrollcontainer.scrollTo({ behavior: 'smooth', top: this.$refs[refName].offsetTop - 90 });


        },
        updateSliderImages(configOption) {
            console.log(configKey);
            this.$emit("updateSliderImages", configOption);
        },
        hideConfigurePopup() {
            this.$emit("hideConfigurePopup");
        },
        toggleStyleMoreInfo(selectedStyleMoreInfo) {
            this.$emit("toggleStyleMoreInfo", selectedStyleMoreInfo);
        },
        selectionConfigurationOption(options) {

            // console.log(this.chConfigurationOptionSelected);
            // if( this.chConfigurationOptionSelected.indexOf(options) == -1 ) {
            //     this.chConfigurationOptionSelected.push(options); 
            // } else {
            //     this.chConfigurationOptionSelected.splice(this.chConfigurationOptionSelected.indexOf(options), 1);
            // }
            // console.log(this.chConfigurationOptionSelected.indexOf(options));
            // console.log(this.chConfigurationOptionSelected);
            // this.configurationOptionSelected = this.chConfigurationOptionSelected;
            this.$emit("selectionConfigurationOption", options);


        },
        setSlideIndex(index) {
            this.$emit("setSlideIndex", index);
        },
        updateConfigurationSelection(configname) {
            console.log(configname);
            this.$emit("updateConfigurationSelection", configname);
            this.$emit("updateSliderImages", configname);
        },
        // updateConfigurationOptionSelection(configname){
        //     this.$emit("updateConfigurationOptionSelection", configname);

        // }
    },
    components: {
        ChevronUp,
        Close,
        VueperSlides,
        VueperSlide,
        NarTooltip

    }
}

</script>