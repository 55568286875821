<template>
  <div class="relative mb-8 mt-8">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap text-center lg:text-center">
        <div class="w-full lg:w-6/12 p-4 mx-auto">
          <div class="w-full px-4 flex py-4 " style="justify-content:center;">
            <div class="breeding-rhombus-spinner">
              <div class="rhombus child-1"></div>
              <div class="rhombus child-2"></div>
              <div class="rhombus child-3"></div>
              <div class="rhombus child-4"></div>
              <div class="rhombus child-5"></div>
              <div class="rhombus child-6"></div>
              <div class="rhombus child-7"></div>
              <div class="rhombus child-8"></div>
              <div class="rhombus big"></div>
            </div>
          </div>
          <div class="text-black text-lg font-semibold">
            Stiamo preparando la tua valutazione...
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    
  },
};
</script>