<template>
    <div :class="[
            'nap-map-popup nap-popup-desktop na-overlay fixed my-auto mx-auto w-full h-full bg-white z-30 top-0 left-0',
            showLayoutPop == true ? 'block' : 'hidden'
        ]" >
            
        <div class="relative nap-popup-wrapper w-full h-full z-40 flex flex-row items-center justify-center">

            <div class="lg:w-11/12 w-full nap-popup-container nap-layout-popup-container flex flex-row items-center justify-center bg-white px-4 py-4 br-10">
                <div class="h-full w-full overflow-hidden relative">
                    <div class="absolute right-0 top-0 cursor-pointer" @click="hideLayoutPopup()" style="height:20px;width:20px;">
                        <close fillColor="#919191" />
                    </div>
                    
                    <h3 class="f20-ls04 text-green text-center font-bold mb-8">Planimetrie</h3>
                    
                        <div class="w-full overflow-auto" style="height:90vh">
                            <vueper-slides 
                                    autoplay :arrows="true" 
                                    :bullets="false" 
                                    :fixed-height="true" class="no-shadow relative mx-auto" style="height:600px;">
                                <!-- :fixed-height="false" -->
                            
                                <vueper-slide
                                    v-for="(image, index) in buildingPlanImages " :key="index">
                                    <template #content>
                                    <div class="flex items-center justify-center relative h-full">
                                        <img class="" v-bind:src="`${image}`" style="height:600px;" alt="">
                                    </div>
                                    </template>
                                </vueper-slide>
                            </vueper-slides>
                        </div>
                    
                    
                </div>
            </div>
            
            
        </div>


    </div>
</template>

<script>
import Close from "vue-material-design-icons/Close.vue";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default{
    props: {
        showLayoutPop: {
            type: Boolean,
            required: true
        },
        buildingPlanImages: {
            type: Object,
            required: true
        }

    },
    created(){
        // console.log(this.buildingPlanImages);
    },
    emits: ["hideLayoutPopup"],
    methods: {
        hideLayoutPopup() {
            this.$emit("hideLayoutPopup");
        }
    },
    data() {
        return {
            
        }
    },
    components: {
        Close,
        VueperSlides, 
        VueperSlide
    }
}

</script>