<template>
  <!-- <section class="lg:w-10/12 w-full mx-auto bg-green p-6 br-10"  v-if="!additionalInfo.wantsAgencyValuation && requestType=='vendi'"> -->
  <section class="lg:w-10/12 w-full mx-auto bg-green house-not-ok-section">
      <p class=" house-ok-sub-heading">STAI CERCANDO CASA?</p>
      <h4 class="text-2xl text-white font-bold house-ok-heading">Dai un’occhiata alle case Newarc! </h4>

      <div class="flex flex-col items-center justify-center">

        <div class="flex lg:flex-row flex-col justify-around">
          <img class="mx-auto house-ok-icon" src="@/assets/img/house-not-ok-1.png" alt="" />
          <img class="mx-auto house-ok-icon" src="@/assets/img/house-not-ok-2.png" alt="" />
          <img class="mx-auto house-ok-icon" src="@/assets/img/house-not-ok-3.png" alt="" />
        </div>
        
        
        <router-link class="house-not-ok-button flex flex-row justify-around" style="min-width: 300px" to="/compra"><span>Vai alle case in vendita</span><arrow-right fillColor="#489B79" /></router-link>

      </div>
  </section>
</template>

<script>
import ArrowRight from "vue-material-design-icons/ArrowRight.vue";

export default {
props: {
  additionalInfo: Object,
  requestType: String,
  
},
components: {
  ArrowRight
},
methods: {
},
}
</script>